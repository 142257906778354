import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Reddaction from './Reddaction';
import Programme from './Programme';
import Project from './Project';
import Verification from './Verification';
import Validation from './Validation';

const ActionRoutes = (props) => {
  return (
    <Router style={{ flex: 1 }}>
      <Programme path="programmes" />
      <Project path="projects" />
      <Reddaction path="actions" />
      <Verification path="verifications" />
      <Validation path="validations" />
    </Router>
  );
};

export default ActionRoutes;
