import React from 'react';
import { Block, Text, Button, toastr } from '@cloudhub-ux/core';
import { Form } from '@cloudhub-ux/core/form';
import DialogContent from '@cloudhub-ux/core/dialog/DialogContent';
import DialogActions from '@cloudhub-ux/core/dialog/DialogActions';
import AdminContext from 'admin/context/AdminContext';

const SystemSettingsForm = () => {
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const [settings, setSettings] = React.useState({});

  const saveSettings = async (values) => {
    try {
      const {
        data,
      } = await adminaxiosinstance().post(
        '/system/settings/updatesystemsettings',
        { ...values }
      );
      setSettings(data);
      toastr.success('Settings updated');
    } catch (error) {
      setSettings({});
    }
  };

  const loadSettings = async () => {
    try {
      const { data } = await adminaxiosinstance().get(
        '/system/settings/getsystemsettings'
      );
      setSettings(data);
    } catch (error) {
      setSettings({});
    }
  };

  React.useEffect(() => {
    loadSettings();
    // eslint-disable-next-line
  }, []);

  return (
    <Block>
      <Text h4>System Settings</Text>

      <Form
        onSubmit={saveSettings}
        initialValues={{ id: 'systemsettings', ...settings }}
        render={({ handleSubmit, submitting, pristine }) => (
          <React.Fragment>
            <DialogContent />

            <DialogActions>
              <Button
                contained
                color="primary"
                disabled={pristine || submitting}
                onClick={handleSubmit}
              >
                <Text white>Update system settings</Text>
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Block>
  );
};

export default SystemSettingsForm;
