/* eslint-disable capitalized-comments */
import React from 'react';
import { TableCell } from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import { Block, Alert, IconButton } from '@cloudhub-ux/core';

import VerificationForm from './forms/GrievanceForm';
import VerificationContext from 'actionverification/context/VerificationContext';
import { Edit } from '@mui/icons-material';
import GridHeader from 'actionverification/GridHeader';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Verification = (props) => {
  const [Status, setStatus] = React.useState('');
  const { verificationAxiosInstance } = React.useContext(VerificationContext);
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  React.useEffect(() => {
    if (Status) {
      try {
        gridRef.current.reload();
      } catch (error) {
        // error
      }
    }
  }, [Status]);

  const cols = [
    { name: 'Subject' },
    { name: 'GrievanceCode', title: 'Grievance No' },
    { name: 'Status' },
  ];

  const columnWidths = [
    { columnName: 'Subject', width: 200 },
    { columnName: 'GrievanceCode', width: 150 },
    { columnName: 'Status', width: 50 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Grievances"
        url="/grievance/myassignments"
        actionsComponent={(props) => {
          return (
            <IconButton onClick={() => setEditingRow((props || {}).row)}>
              <Edit color="primary" />
            </IconButton>
          );
        }}
        header={(props) => (
          <GridHeader {...props} Status={Status} setStatus={setStatus} />
        )}
        axiosinstance={verificationAxiosInstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        params={{ Status }}
        onAdd={() => {}}
        onEdit={(row) => setEditingRow(row)}
        onDeleteRows={() => {}}
        ref={gridRef}
      />
      <VerificationForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Verification;
