import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import { Block, Tab, Tabs, Text, IconButton } from '@cloudhub-ux/core';
import Download from '@mui/icons-material/Download';
import { sizes } from 'theme';
const RootStyle = styled('div')(({ theme }) => ({
  // width: '100%',
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}));

export default function AboutContent({ page }) {
  const [tabvalue, setTabValue] = React.useState(0);
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 10, md: 25, p: '25px' } }}>
          <Typography
            variant="h5"
            dangerouslySetInnerHTML={{
              __html: (page || {}).Body || '<p>About</p>',
            }}
            sx={{
              fontFamily: 'CircularStd',
              fontWeight: 400,
              fontSize: '3rem',
            }}
          />
          {Array.isArray(page.Sections) && (
            <Block padding={sizes.padding}>
              <Block>
                <Tabs
                  value={tabvalue}
                  onChange={(e, tabvalue) => setTabValue(tabvalue)}
                  fullWidth
                >
                  {page.Sections.map((section) => (
                    <Tab key={section.Title} label={section.Title} />
                  ))}
                </Tabs>
                <Block>
                  <Typography
                    variant="h5"
                    dangerouslySetInnerHTML={{
                      __html:
                        (((page || {}).Sections || [])[tabvalue] || {}).Body ||
                        '',
                    }}
                    sx={{
                      fontFamily: 'CircularStd',
                      fontWeight: 400,
                      fontSize: '3rem',
                    }}
                  />
                </Block>
              </Block>
            </Block>
          )}
          {page && page.File && (
            <Block center padding={sizes.padding}>
              <Text h5 bold>
                {page.File.filename || (page.File[0] || {}).filename || ''}
              </Text>
              <a
                href={page.File.Location || (page.File[0] || {}).Location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton>
                  <Download /> Download
                </IconButton>
              </a>
            </Block>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
}
