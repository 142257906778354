import React from 'react';
import {
  Block,
  ListMenu,
  ListSubMenu,
  FieldBlock,
  TextArea,
  Alert,
  Button,
  toastr,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import Text from '@cloudhub-ux/core/Text';
import { Fab } from '@mui/material';
import { FiberNew } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';

const InformationRequests = ({ GrievanceId, Requests, onUpdate }) => {
  const [addRequest, setaddRequest] = React.useState(false);
  const [uploading, setuploading] = React.useState(false);

  const { postData, error, reset } = useVerificationAxios({});

  const saveRequest = async (values, form) => {
    try {
      const data = await postData({
        url: `/grievance/addrequest`,
        params: { Request: values, GrievanceId },
      });
      if (data && data.Requests) {
        form.reset();
        toastr.success('Request sent Succesfully');
        onUpdate(data);
        setaddRequest(false);
      }
    } catch (error) {}
  };

  const changeFile = (event, form) => {
    if (event && event.target && event.target.value) {
      const v = event.target.value || [];
      const ind = v.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', v);
    }
    if (event && Array.isArray(event)) {
      const ind = event.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', event);
    }
  };

  const onRemoveFiles = async (file) => {
    try {
      const data = await postData({
        url: '/media/upload/deletefiles',
        params: { files: [file] },
      });
      if (data) {
        toastr.success('Files deleted successfully');
        return data;
      }
    } catch (error) {}
  };

  console.log('Requests', Requests);

  return (
    <Block shadow padding={[0, sizes.padding]} margin={sizes.baseMargin}>
      <Text h5 bold style={{ alignSelf: 'center' }}>
        Information Requests
      </Text>
      <div style={{ alignSelf: 'flex-end' }}>
        <Fab
          sx={{ backgroundColor: colors.error, mr: 1 }}
          size="small"
          variant="extended"
          onClick={() => setaddRequest(false)}
        >
          <Close /> Cancel
        </Fab>
        <Fab
          size="small"
          variant="extended"
          onClick={() => setaddRequest(true)}
        >
          <FiberNew /> New Request
        </Fab>
      </div>
      {addRequest && (
        <Form
          onSubmit={saveRequest}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <Block
              padding={sizes.padding}
              shadow
              margin={sizes.doubleBaseMargin}
            >
              <Field
                readOnly
                name="Description"
                label="Description of Request"
                component={TextArea}
                flex
              />
              <FieldBlock row wrap>
                <Field
                  type="text"
                  label="Response Files"
                  name="Files"
                  url={`/api/media/upload/file`}
                  // axiosinstance={axiosinstance}
                  component={FilesUpload}
                  limit={5}
                  onChange={(event) => changeFile(event, form)}
                  onRemove={onRemoveFiles}
                  flex
                />
              </FieldBlock>
              <Button
                contained
                color={colors.primary}
                onClick={handleSubmit}
                disabled={submitting || pristine || uploading}
              >
                {submitting || uploading ? 'Saving...' : 'Save'}
              </Button>
              {Boolean(error) && (
                <Alert error message={error} onClose={reset} />
              )}
            </Block>
          )}
        />
      )}

      <ListMenu>
        {(Requests || []).map((item) => (
          <ListSubMenu
            header={item.Description}
            key={item.Description}
            expanded={!(item || {}).Description}
          >
            {(item || {}).Description && (
              <Block padding={sizes.padding} paper>
                <Text h6 style={{ marginBottom: sizes.baseMargin }}>
                  {item.Description}
                </Text>
                <Text h6 primary style={{ marginBottom: sizes.baseMargin }}>
                  {item.Response}
                </Text>
                {Array.isArray(item.Files) &&
                  item.Files.map((file) => (
                    <a
                      key={file.url}
                      href={file.Location}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {file.Location}
                    </a>
                  ))}
              </Block>
            )}
          </ListSubMenu>
        ))}
      </ListMenu>
    </Block>
  );
};

InformationRequests.defaultProps = {
  Requests: [
    {
      EventCode: 'INTEV0001',
      Timestamp: 0,
      Description: 'Requested actions were completed',
      Files: [],
      Owner: {
        Name: 'Implementor 1',
        id: '1',
      },
    },
    {
      EventCode: 'INTEV0002',
      Timestamp: 0,
      Description: 'Requested actions were completed',
      Files: [],
      Owner: {
        Name: 'Implementor 1',
        id: '2',
      },
    },
    {
      EventCode: 'INTEV0003',
      Timestamp: 0,
      Description: 'Requested actions were completed',
      Files: [],
      Owner: {
        Name: 'Implementor 1',
        id: '3',
      },
    },
  ],
};

export default InformationRequests;
