import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Block } from '@cloudhub-ux/core';
import { sizes, colors } from 'theme';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import ListMenuItem from '@cloudhub-ux/core/widgets/ListMenuItem';
import Avatar from '@cloudhub-ux/core/Avatar';
const RootStyle = styled('div')(({ theme }) => ({
  // width: '100%',
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}));

export default function SafeguardContent({ page }) {
  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 10, md: 25, p: '25px' } }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{ fontWeightt: 400 }}>
              Safeguard Theme:{' '}
            </Typography>
            <Typography variant="h3" color="primary">
              {((page || {}).Theme || []).join(', ')}
            </Typography>
          </Box>
          {Array.isArray(page.Indicators) && (
            <Block padding={sizes.padding}>
              <ListMenu>
                {page.Indicators.map((indicator) => {
                  const { IndicatorName, Description, Definition } =
                    indicator || {};
                  return (
                    <ListMenuItem
                      key={IndicatorName}
                      avatar={<Avatar color="primary">{IndicatorName}</Avatar>}
                      primary={
                        <Block>
                          <Typography
                            variant="h5"
                            dangerouslySetInnerHTML={{
                              __html: Description || '',
                            }}
                            sx={{
                              mr: 5,
                            }}
                          />
                        </Block>
                      }
                      action={
                        Definition ? (
                          <Tooltip
                            title={
                              <Typography
                                variant="h6"
                                dangerouslySetInnerHTML={{
                                  __html: Definition || '',
                                }}
                              />
                            }
                          >
                            <InfoOutlined
                              style={{
                                color: colors.info,
                                fontSize: sizes.icons.medium,
                                marginLeft: sizes.doubleBaseMargin,
                                marginBottom: -2,
                              }}
                            />
                          </Tooltip>
                        ) : (
                          ''
                        )
                      }
                    />
                  );
                })}
              </ListMenu>
              <Block></Block>
            </Block>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
}
