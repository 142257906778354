import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Faq from './Faq';
import Infopage from './Infopage';
import Documents from './Documents';
import Gallery from './Gallery';

const WebsiteRoutes = () => {
  return (
    <Router style={{ flex: 1 }}>
      <Faq path="faq" />
      <Infopage path="infopages" />
      <Documents path="documents" />
      <Gallery path="gallery" />
    </Router>
  );
};

export default WebsiteRoutes;
