import React from 'react';
import { Button, Dialog, Input, Block, Text, Alert } from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import { Form, Field } from '@cloudhub-ux/core/form';
import { RemoteSelector, StaticListSelector } from '@cloudhub-ux/core/baseweb';
import { colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import DialogLayer from 'localcomponents/DialogLayer';

const ImplementorForm = (props) => {
  const { postData, error, reset, adminAxiosInstance } = useAdminAxios({});

  const addImplementor = async (implementor) => {
    try {
      const data = await postData({
        url: `/stakeholders/implementor/saveimplementor`,
        params: implementor,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="50%" maxWidth="md">
      <Form
        onSubmit={addImplementor}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).ImplementorName || 'Add Implementor'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <Field
                  name="User"
                  component={RemoteSelector}
                  url="/system/user/filtersearch"
                  label="User"
                  axiosinstance={adminAxiosInstance}
                  labelExtractor={(row) => (row || {}).Name || ''}
                  valueExtractor={(row) =>
                    row && row.id
                      ? {
                          id: row.id,
                          _id: row._id,
                          Name: row.Name,
                          Email: row.Email,
                          Phone: row.Phone,
                        }
                      : row
                  }
                  keyExtractor={(row) => (row || {}).id || row}
                  required
                />
                <Field
                  name="Stakeholder"
                  component={RemoteSelector}
                  url="/stakeholders/stakeholder/filtersearch"
                  label="Stakeholder"
                  axiosinstance={adminAxiosInstance}
                  labelExtractor={(row) => (row || {}).StakeholderName || ''}
                  valueExtractor={(row) =>
                    row && row.id
                      ? {
                          id: row.id,
                          _id: row._id,
                          StakeholderName: row.StakeholderName,
                          StakeholderCode: row.StakeholderCode,
                        }
                      : row
                  }
                  keyExtractor={(row) => (row || {}).id || row}
                  required
                />
                <Field
                  name="Designation"
                  label="Designation"
                  component={Input}
                  required
                />
                <Field
                  name="Status"
                  label="Status"
                  component={StaticListSelector}
                  options={['ACTIVE', 'DORMANT']}
                  required
                />
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default ImplementorForm;
