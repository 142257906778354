import React from 'react';
import { Dialog, Text, GridContainer, GridItem } from '@cloudhub-ux/core';
import { DialogHeader } from '@cloudhub-ux/core/dialog';
import DialogLayer from 'localcomponents/DialogLayer';
import GrievanceDetail from 'actionverification/grievance/forms/GrievanceDetail';
import GrievanceResponseForm from './GrievanceResponseForm';

const GrievanceForm = (props) => {
  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} fullScreen onClose={props.onCancelEdit}>
      <DialogHeader onClose={props.onCancelEdit}>
        <Text h4 bold>
          {(editingRow || {}).GrievanceCode || (editingRow || {}).Subject || ''}
        </Text>
      </DialogHeader>
      <DialogLayer>
        <GridContainer>
          <GridItem
            sm={12}
            md={8}
            style={{ maxHeight: '100%', overflow: 'auto' }}
          >
            <GrievanceDetail
              GrievanceId={((editingRow || {})._from || '').split('/')[1]}
            />
          </GridItem>
          <GridItem
            sm={12}
            md={4}
            style={{ maxHeight: '100%', overflow: 'auto' }}
          >
            <GrievanceResponseForm
              Grievanceresponse={editingRow}
              onClose={props.onCancelEdit}
            />
          </GridItem>
        </GridContainer>
      </DialogLayer>
    </Dialog>
  );
};

export default GrievanceForm;
