import React from 'react';
import AuthContext from 'auth/context/AuthContext';
import AppContext from './AppContext';
import useAppSettings from './useAppSettings';
import useGrievance from '../fgrm/store/useGrievance';
import useSafeguards from './useSafeguards';

const AppProvider = (props) => {
  const auth = React.useContext(AuthContext);

  const { isAuthenticated, currentUser, token, axiosinstance, CONFIG } = auth;

  const AppSettings = useAppSettings({ axiosinstance });
  const GrievanceState = useGrievance({ axiosinstance });
  const Safeguards = useSafeguards({ axiosinstance });

  return (
    <AppContext.Provider
      value={{
        auth: {
          token,
          currentUser,
          isAuthenticated,
          UserRoles: currentUser.UserRoles || [],
          UserType: currentUser.UserType,
        },
        CONFIG,
        axiosinstance,
        AppSettings,
        GrievanceState,
        Safeguards,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
