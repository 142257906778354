import React from 'react';
import { Form, Field } from 'react-final-form';
import { Input, Block, Button, Text, FieldBlock } from '@cloudhub-ux/core';
import PhoneInput from '@cloudhub-ux/core/PhoneInput';
import { DialogActions, DialogContent } from '@cloudhub-ux/core/dialog';

import AuthContext from './context/AuthContext';

const ProfileForm = (props) => {
  const {
    actions: { updateProfile },
  } = React.useContext(AuthContext);

  const onUpdateProfile = (values) => {
    updateProfile(values);
  };

  return (
    <Form
      onSubmit={onUpdateProfile}
      validate={validate}
      initialValues={props.initialValues || {}}
      render={({ handleSubmit, submitting, pristine }) => (
        <Block>
          <DialogContent>
            <Block>
              <FieldBlock>
                <label>Full Name</label>
                <Field type="text" name="Name" component={Input} />
              </FieldBlock>

              <FieldBlock row wrap>
                <FieldBlock flex>
                  <label>Email:</label>
                  <Field type="email" name="Email" component={Input} />
                </FieldBlock>
                <FieldBlock flex>
                  <label>Phone:</label>
                  <Field type="tel" name="Phone" component={PhoneInput} />
                </FieldBlock>
              </FieldBlock>
              <FieldBlock>
                <label>Address</label>
                <Field type="text" name="Address" component={Input} />
              </FieldBlock>
            </Block>
          </DialogContent>
          <DialogActions>
            <Button
              contained
              onClick={handleSubmit}
              disabled={submitting || pristine}
              color="success"
            >
              <Text button white>
                Update Profile
              </Text>
            </Button>
          </DialogActions>
        </Block>
      )}
    />
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.Email) {
    errors.Email = 'Required';
  }
  if (!values.Name) {
    errors.Name = 'Required';
  }
  if (!values.Phone) {
    errors.Phone = 'Required';
  }
  return errors;
};

export default ProfileForm;
