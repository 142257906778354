import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import useSettings from '@cloudhub-ux/min/hooks/useSettings';
import {
  Box,
  Grid,
  Container,
  Stack,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ListSubMenu } from '@cloudhub-ux/core';
import useAppAxios from 'app/context/useAppAxios';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import SearchNotFound from 'app/static/SearchNotFound';
import { useDebounce } from '@cloudhub-ux/core/customhooks';
import moment from 'moment';
export default function PlrContent() {
  const [plrs, setplrs] = React.useState([]);
  const [totalCount, settotalCount] = React.useState(0);
  const [page, setpage] = React.useState(0);
  const [rowsPerpage, setRowsPerPage] = React.useState(30);
  const [searchTerm, setsearchTerm] = React.useState('');

  const searchPhrase = useDebounce(searchTerm, 1500);

  const { themeStretch } = useSettings();
  const { getData } = useAppAxios({});

  const getPlrs = React.useCallback(async () => {
    try {
      const data = await getData({
        url: '/app/safeguards/plr/getall',
        params: { skip: page * 30, limit: 30, filter: searchPhrase },
      });
      if (data && Array.isArray(data.items)) {
        settotalCount(data.totalCount);
        setplrs(data.items);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [page]);

  React.useEffect(() => {
    getPlrs();
  }, [getPlrs]);

  return (
    <>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{ minHeight: '100vh', pt: 4 }}
      >
        <Stack
          mb={5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TextField
            placeholder="Search post..."
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{
                        ml: 1,
                        width: 20,
                        height: 20,
                        color: 'text.disabled',
                      }}
                    />
                  </InputAdornment>
                </>
              ),
            }}
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm}
          />
        </Stack>
        <Grid container spacing={3}>
          {plrs.length === 0 && searchTerm && <SearchNotFound />}
          {plrs.map((plr, index) => (
            <ListSubMenu header={plr.PlrName}>
              <Typography variant="h6" color="primary">
                Type: {plr.PlrType}
              </Typography>
              <Typography variant="h6" color="primary">
                Subject: {plr.Subject}
              </Typography>
              <Typography variant="h6" color="primary">
                Effected: {moment(plr.EffectedDate).format('DD MMM, YYYY')}
              </Typography>
              <Typography sx={{ fontWeight: 400, m: 3, fontSize: 15 }}>
                {plr.LongTitle}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: plr.Description }}
              />
            </ListSubMenu>
          ))}
          <Grid item xs={12} justify="center" sx={{ justifyContent: 'center' }}>
            <TablePagination
              count={totalCount}
              page={page}
              onChange={(event, value) => setpage(value)}
              rowsPerPage={rowsPerpage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setpage(0);
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
