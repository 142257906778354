import React from 'react';
import { DialogContent } from '@cloudhub-ux/core/dialog';
import { LayersManager } from '@cloudhub-ux/core/baseweb';

const DialogLayer = (props) => (
  <DialogContent>
    <LayersManager
      zIndex={1301}
      overrides={{
        AppContainer: { style: { height: '100%', display: 'flex' } },
      }}
    >
      {props.children}
    </LayersManager>
  </DialogContent>
);

export default DialogLayer;
