import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { Link as RouterLink } from '@cloudhub-ux/core/reach';
import { Block } from '@cloudhub-ux/core';
import { styled } from '@mui/material/styles';
import { Button, Box, Container, Typography, Stack, Card } from '@mui/material';
import {
  // varFadeIn,
  varFadeInUp,
  varWrapEnter,
  varFadeInRight,
} from '@cloudhub-ux/min/theme/animate';
import { useTheme } from '@mui/material/styles';
import { AutoStories } from '@mui/icons-material';
import AppContext from 'app/context/AppContext';
import { Images } from 'theme';
import CarouselControlsArrowsIndex from './CarouselControlsArrowsIndex';

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[400],
  backgroundImage: `url(${Images.landingbg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '85vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 520,
    margin: '25vh auto 0',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left',
    },
  })
);

// const HeroOverlayStyle = styled(motion.img)({
//   // zIndex: 9,
//   width: '100%',
//   height: '100%',
//   objectFit: 'cover',
//   position: 'absolute',
// });

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  // zIndex: -1,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    right: '8%',
    width: 'auto',
    height: '48vh',
  },
  // [theme.breakpoints.down('md')]: {
  //   backgroundColor: 'black',
  // },
}));

export default function LandingHero() {
  const [homepageMessages, sethomepageMessages] = React.useState([
    {
      Title: 'Welcome to Kenya REDD+ SIS',
      LongTitle: "Kenya's REDD+ safeguards Information System",
      Body:
        "Welcome to Kenya REDD+ SIS to learn more about Kenya's approach to addressing and respecting the Cancun safeguards",
    },
  ]);
  const carouselRef = React.useRef();
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = React.useState(
    theme.direction === 'rtl' ? homepageMessages.length - 1 : 0
  );

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };
  const {
    AppSettings: { InfoPages },
  } = React.useContext(AppContext);

  React.useEffect(() => {
    const homepages = InfoPages.filter((page) => page.Section === 'Homepage');
    sethomepageMessages(homepages);
    // eslint-disable-next-line
  }, [JSON.stringify(InfoPages)]);

  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        {/* <HeroOverlayStyle
          alt="overlay"
          src={Images.overlay}
          variants={varFadeIn}
        /> */}
        <HeroImgStyle
          alt="hero"
          src={
            (((homepageMessages || [])[currentIndex] || {}).Picture || {})
              .Location || Images.landing
          }
          variants={varFadeInUp}
        />
        <Card
          sx={{
            backgroundColor: 'transparent',
            width: '100vw',
            height: {
              md: 'auto',
              xs: '100vh',
            },
          }}
        >
          <Slider ref={carouselRef} {...settings}>
            {homepageMessages.map((page) => (
              <Container
                maxWidth="lg"
                key={page.Title}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <ContentStyle>
                  <motion.div variants={varFadeInRight}>
                    <Block>
                      <Typography variant="h1" sx={{ color: 'common.white' }}>
                        {page.Title}
                      </Typography>
                      <Typography variant="h3" sx={{ color: 'common.white' }}>
                        {page.LongTitle}
                      </Typography>
                    </Block>
                  </motion.div>

                  <motion.div variants={varFadeInRight}>
                    <Typography
                      sx={{ color: 'common.white' }}
                      dangerouslySetInnerHTML={{ __html: page.Body }}
                    />
                  </motion.div>

                  <motion.div variants={varFadeInRight}>
                    <Button
                      size="large"
                      variant="contained"
                      component={RouterLink}
                      to="/about"
                      startIcon={<AutoStories width={20} height={20} />}
                    >
                      Learn More
                    </Button>
                  </motion.div>

                  {/* <Stack
              direction="row"
              spacing={1.5}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
            >
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_sketch.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_figma.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_material.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_react.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_js.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_ts.svg"
              />
            </Stack> */}
                </ContentStyle>
              </Container>
            ))}
          </Slider>
        </Card>
        <CarouselControlsArrowsIndex
          index={currentIndex}
          total={homepageMessages.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      </RootStyle>

      <Box sx={{ height: { md: '75vh' } }} />
    </>
  );
}
