/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';

import { Block, Text, Button } from '@cloudhub-ux/core';
import { sizes, colors } from 'theme';
import { useLocation, useMetrics } from '@cloudhub-ux/core/customhooks';

const SessionExpired = () => {
  const { height } = useMetrics();
  const { navigate } = useLocation();

  const onSignIn = () => {
    navigate('/signin');
  };

  return (
    <Block center middle style={{ height }}>
      <Block flex={false} padding={sizes.padding}>
        <Text center style={{ fontSize: 52 }}>
          😢
        </Text>
        <Text h5>Your session has expired</Text>

        <Button contained rounded color={colors.primary} onClick={onSignIn}>
          <Text milkyWhite>Sign in</Text>
        </Button>
      </Block>
    </Block>
  );
};

export default SessionExpired;
