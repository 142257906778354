import React from 'react';
import toastr from '@cloudhub-ux/core/toastr';
export const SET_VALIDATOR = 'SET_VALIDATOR';
export const SET_VERIFIER = 'SET_VERIFIER';
export const SET_GRIEVANCE_HANDLER = 'SET_GRIEVANCE_HANDLER';
export const SET_DISSEMINATOR = 'SET_DISSEMINATOR';
export const SET_AGGREGATOR = 'SET_AGGREGATOR';
export const SET_TASKS = 'SET_TASKS';
export const SET_SAFEGUARDS = 'SET_SAFEGUARDS';
export const SET_CURRENT_ACTION = 'SET_CURRENT_ACTION';

const INITIAL_STATE = {
  CurrentFeedback: {},
  CurrentVerification: {},
  CurrentValidation: {},
  CurrentGrievance: {},
  Tasks: {
    Validations: {
      AllValidations: 0,
      PendingValidations: 0,
      SubmittedValidations: 0,
    },
    Verifications: {
      AllVerifications: 0,
      PendingVerifications: 0,
      SubmittedVerifications: 0,
    },
    Grievances: {
      AllGrievances: 0,
      PendingGrievances: 0,
      ResolvedGrievances: 0,
      EscalatedGrievances: 0,
      DismissedGrievances: 0,
    },
    Feedback: {
      AllFeedback: 0,
    },
  },
  Safeguards: [],
  CurrentAction: {},
};

export const tasksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VALIDATOR: {
      return {
        ...state,
        Validator: { ...action.payload },
      };
    }
    case SET_VERIFIER: {
      return {
        ...state,
        Verifier: { ...action.payload },
      };
    }
    case SET_GRIEVANCE_HANDLER: {
      return {
        ...state,
        Grievancehandler: { ...action.payload },
      };
    }
    case SET_DISSEMINATOR: {
      return {
        ...state,
        Disseminator: { ...action.payload },
      };
    }
    case SET_AGGREGATOR: {
      return {
        ...state,
        Aggregator: { ...action.payload },
      };
    }
    case SET_TASKS:
      return {
        ...state,
        Tasks: {
          ...state.Tasks,
          ...action.payload,
        },
      };
    case SET_SAFEGUARDS:
      return {
        ...state,
        Safeguards: action.payload,
      };
    case SET_CURRENT_ACTION:
      return {
        ...state,
        CurrentAction: action.payload,
      };
    default:
      return state;
  }
};

const useTasks = ({ axiosinstance }) => {
  const [state, dispatch] = React.useReducer(tasksReducer, INITIAL_STATE);

  const getTasks = async () => {
    try {
      const { data } = await axiosinstance().get(
        '/actions/reddaction/actiontasks'
      );
      if (data && data.Verifications) {
        dispatch({
          type: SET_TASKS,
          payload: data,
        });
      }
    } catch (error) {
      toastr.error();
    }
  };

  const getSafeguards = async () => {
    try {
      const { data } = await axiosinstance().get(
        `/safeguards/safeguard/getall`
      );
      if (data && data.items) {
        dispatch({
          type: SET_SAFEGUARDS,
          payload: data.items,
        });
      }
    } catch (error) {}
  };

  const getAction = async (ActionId) => {
    try {
      const { data } = await axiosinstance().get(`/reddaction/getaction`, {
        params: { ActionId },
      });
      if (data && data.id) {
        dispatch({
          type: SET_CURRENT_ACTION,
          payload: data,
        });
      }
    } catch (error) {
      const response = error.response || {};
      const data = response.data || {};
      const message = data.message || error.message || error || '';
      toastr.error(message);
    }
  };

  React.useEffect(() => {
    getTasks();
    getSafeguards();
    // eslint-disable-next-line
  }, []);

  return {
    ...state,
    verificationActions: {
      getAction,
      getSafeguards,
      getTasks,
    },
  };
};

export default useTasks;
