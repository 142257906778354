import React from 'react';
import { Link } from '@cloudhub-ux/core/reach';
import { Block, ListMenuItem } from '@cloudhub-ux/core';
import { MHidden } from '@cloudhub-ux/min/theme/@material-extend';
import useOffSetTop from '@cloudhub-ux/min/hooks/useOffSetTop';
import MenuDesktop from './MenuDesktop';
import navConfig from './MenuConfig';
import AppContext from 'app/context/AppContext';
import { Images } from 'theme';

const TopMenu = () => {
  const isOffset = useOffSetTop(100);
  const {
    AppSettings: { InfoPages },
    Safeguards: { Safeguards },
  } = React.useContext(AppContext);
  return (
    <Block flex={false} row middle>
      <Link to="/" style={{ flex: 1 }}>
        <ListMenuItem>
          <Block flex={false} style={{ minWidth: 50 }}>
            <img
              src={Images.logoicon}
              alt="logo"
              style={{ height: 60, width: 70 }}
            />
          </Block>
        </ListMenuItem>
      </Link>
      <MHidden width="mdDown">
        <MenuDesktop
          isOffset={isOffset}
          isHome={false}
          navConfig={navConfig(InfoPages, Safeguards)}
        />
      </MHidden>
    </Block>
  );
};

export default TopMenu;
