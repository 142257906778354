import { toastr } from '@cloudhub-ux/core';
import React from 'react';
import VerificationContext from './VerificationContext';

const useVerificationAxios = (props) => {
  const { url, params, initialState } = props || {};
  const {
    verificationAxiosInstance,
    auth: { axiosinstance },
  } = React.useContext(VerificationContext);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(initialState);

  const postData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await verificationAxiosInstance().post(`${url}`, {
          ...params,
        });

        setLoading(false);
        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};
        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    [verificationAxiosInstance]
  );

  const getData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await verificationAxiosInstance().get(`${url}`, {
          params,
        });
        setLoading(false);
        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};
        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    [verificationAxiosInstance]
  );

  const hydrateState = async () => {
    const data = await getData({ url, params });
    setData(data);
  };

  const reset = () => {
    setError('');
    setLoading(false);
    setData(initialState);
  };

  React.useEffect(() => {
    if (error) {
      toastr.error(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (url) {
      hydrateState();
    }
    // eslint-disable-next-line
  }, [url]);

  return {
    data,
    error,
    loading,
    getData,
    postData,
    reset,
    verificationAxiosInstance,
    axiosinstance,
  };
};

export default useVerificationAxios;
