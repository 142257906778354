import React from 'react';
import {
  GridContainer,
  GridItem,
  Text,
  TextArea,
  Alert,
  toastr,
  Block,
  ListSubMenu,
} from '@cloudhub-ux/core';
import { Field } from '@cloudhub-ux/core/form';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import useAppAxios from 'app/context/useAppAxios';
import { Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
  StaticListSelector,
  RemoteSelector,
  LayersManager,
} from '@cloudhub-ux/core/baseweb';
import { colors, sizes } from 'theme';

const ActionSafeguards = ({ values, setuploading, form }) => {
  const { postData, error, reset, appAxiosInstance } = useAppAxios({});

  const changeFile = (event, form) => {
    if (event && event.target && event.target.value) {
      const v = event.target.value || [];
      const ind = v.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Safeguards.Files', v);
    }
    if (event && Array.isArray(event)) {
      const ind = event.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Safeguards.Files', event);
    }
  };

  const onRemoveFiles = async (file) => {
    try {
      const data = await postData({
        url: '/media/upload/deletefiles',
        params: { files: [file] },
      });
      if (data) {
        toastr.success('Files deleted successfully');
        return data;
      }
    } catch (error) {}
  };

  return (
    <LayersManager zIndex={1}>
      <GridContainer spacing={2}>
        <GridItem xs={12}>
          <Field
            name="SafeguardTheme"
            label="Safeguard Themes"
            component={StaticListSelector}
            options={[
              'Governance',
              'Do no harm',
              'Securing Multiple Benefits',
              'Assuring mitigation Effectiveness',
            ]}
            isMulti
            required
          />
        </GridItem>
        <GridItem xs={12}>
          <Field
            name="Safeguard"
            label="Safeguard"
            component={RemoteSelector}
            url="/app/safeguards/safeguard/filtersearch"
            axiosinstance={appAxiosInstance}
            valueExtractor={(row) => row}
            labelExtractor={(row) => (row || {}).SafeguardName || `${row}`}
            key={(row) => (row || {}).id}
            params={{
              ...(((values || {}).SafeguardTheme || []).length > 0
                ? { SafeguardTheme: values.SafeguardTheme }
                : null),
            }}
            isMulti
            required
            readOnly={((values || {}).SafeguardTheme || []).length <= 0}
          />
        </GridItem>
        {((values || {}).Safeguard || []).map((safeguard) => {
          const {
            SafeguardName,
            SafeguardCode,
            Description,
            Indicators,
            id,
          } = safeguard;
          if (
            !id ||
            !SafeguardName ||
            !SafeguardCode ||
            (Indicators || []).length === 0
          ) {
            return null;
          }
          return (
            <ListSubMenu header={safeguard.SafeguardName} key={safeguard.id}>
              <Block shadow margin={sizes.baseMargin} padding={sizes.padding}>
                {Boolean(error) && (
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Alert error message={error} onClose={reset} />
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Text h4 bold primary>
                    {SafeguardName}
                  </Text>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: Description,
                    }}
                  />
                </GridItem>
                {Indicators.map((indicator) => {
                  const {
                    IndicatorName,
                    Description,
                    AssessmentQuestion,
                    Definition,
                    AssessedInformation,
                  } = indicator;
                  if (!IndicatorName || !AssessmentQuestion) {
                    return null;
                  }
                  return (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      key={IndicatorName}
                    >
                      <Field
                        name={`Safeguards.${SafeguardCode}.${IndicatorName.replace(
                          /[\s.,]/g,
                          '_'
                        )}`}
                        label={
                          <Text>
                            <Text bold>{IndicatorName}:</Text>{' '}
                            {AssessmentQuestion}{' '}
                            {Description ? (
                              <Tooltip
                                title={
                                  <Block>
                                    <Text>{Definition || ''}</Text>
                                    <Text>{AssessedInformation || ''}</Text>
                                  </Block>
                                }
                              >
                                <InfoOutlined
                                  style={{
                                    color: colors.info,
                                    fontSize: 14,
                                    marginLeft: sizes.doubleBaseMargin,
                                    marginBottom: -2,
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </Text>
                        }
                        component={TextArea}
                        required
                      />
                    </GridItem>
                  );
                })}
              </Block>
            </ListSubMenu>
          );
        })}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Field
            type="text"
            label="Safeguards Files"
            name="Files"
            url={`/api/media/upload/file`}
            // axiosinstance={appAxiosInstance}
            component={FilesUpload}
            limit={5}
            onChange={(event) => changeFile(event, form)}
            onRemove={onRemoveFiles}
          />
        </GridItem>
      </GridContainer>
    </LayersManager>
  );
};

export default ActionSafeguards;
