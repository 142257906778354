import React from 'react';
import CONFIG from 'auth/context/Config';
// import { createHistory, createMemorySource } from '@reach/router';
import LocationProvider from '@cloudhub-ux/core/customhooks/LocationProvider';
import { ThemeProvider } from '@cloudhub-ux/min';
import { hot } from 'react-hot-loader/root';
import AuthProvider from 'auth/context/AuthProvider';
import SocketProvider from 'socket/SocketProvider';
import HomeRoute from './HomeRoute';
import { colors, sizes, fonts } from './theme';
import UpdatesListener from 'serviceworkers/UpdatesListener';
import IdleTimeOutHandler from 'auth/IdleTimeoutHandler';
// const source = createMemorySource('/');
// const history = createHistory(source);

const App = () => {
  return (
    <ThemeProvider
      colors={colors}
      fonts={fonts}
      sizes={sizes}
      CONFIG={CONFIG}
      addBaseWeb
    >
      <LocationProvider>
        <AuthProvider>
          <SocketProvider>
            <HomeRoute />
            <IdleTimeOutHandler />
            <UpdatesListener />
          </SocketProvider>
        </AuthProvider>
      </LocationProvider>
    </ThemeProvider>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
