import React from 'react';
import axios from 'axios';

import { useLocation } from '@cloudhub-ux/core/customhooks';
import { authReducer, INITIAL_STATE } from './authReducer';
import authActions from './actions/authActions';
import CONFIG from './Config';

let localtoken = '';

try {
  localtoken = localStorage.getItem('token');
} catch (error) {}

const useAuthentication = () => {
  const { navigate, location } = useLocation();
  const [state, dispatch] = React.useReducer(authReducer, INITIAL_STATE);

  const { isAuthenticated, currentUser, token, ...rest } = state;

  const axiosinstance = React.useMemo(
    () => () =>
      axios.create({
        baseURL: '/api',
        headers: {
          prxy: 'no-proxy',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token || localtoken}`,
          userid: currentUser.id || '',
          username: currentUser.Name || '',
          email: currentUser.Email || '',
          phone: currentUser.Phone || '',
        },
      }),
    [
      currentUser.Email,
      currentUser.Name,
      currentUser.Phone,
      currentUser.id,
      token,
    ]
  );

  const dispatchActions = React.useMemo(
    () => authActions({ dispatch, axiosinstance }),
    [axiosinstance]
  );
  const { getImplementor } = dispatchActions;

  React.useEffect(() => {
    if (currentUser.id && currentUser.UserType.includes('ActionImplementor')) {
      getImplementor();
    }
    // eslint-disable-next-line
  }, [currentUser.id]);

  return {
    isAuthenticated,
    currentUser,
    dispatch,
    actions: dispatchActions,
    token,
    navigate,
    location,
    CONFIG,
    axiosinstance,
    ...rest,
  };
};

export default useAuthentication;
