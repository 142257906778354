import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
import { fShortenNumber } from '@cloudhub-ux/min/utils/formatNumber';
import Gavel from '@mui/icons-material/Gavel';
import { colors } from 'theme';
import VerificationContext from 'actionverification/context/VerificationContext';

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3),
}));

export default function AllGrievances() {
  const {
    VerificationState: { Tasks },
  } = React.useContext(VerificationContext);
  const { Grievances } = Tasks || {};
  const { AllGrievances: Total } = Grievances;
  return (
    <RootStyle>
      <div>
        <Typography variant="h3">{fShortenNumber(Total || 0)}</Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          All Grievances
        </Typography>
      </div>
      <Box
        sx={{
          width: 120,
          height: 120,
          lineHeight: 0,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        <Gavel
          style={{
            color: colors.secondary,
            fontSize: 120,
          }}
        />
      </Box>
    </RootStyle>
  );
}
