import React, { useState } from 'react';
import {
  FieldButton,
  Text,
  Input,
  IconButton,
  Button,
  Block,
  ListMenu,
  ListMenuItem,
  TextArea,
  toastr,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { Close as CloseIcon, Edit, DragHandle } from '@mui/icons-material';
import SortableList from 'react-sortablejs-list';
import { colors, sizes } from 'theme';
import { RemoteSelector } from '@cloudhub-ux/core/baseweb';
import useAdminAxios from 'admin/context/useAdminAxios';

const SafeguardIndicatorForm = ({ input: { onChange, value } }) => {
  const [selectedIndex, selectIndex] = useState(-1);
  const [editing, setediting] = React.useState({});

  const { adminAxiosInstance } = useAdminAxios({});

  const onAddIndicator = (values, form) => {
    const ind = [...(value || [])].findIndex(
      (indicator) =>
        indicator && indicator.IndicatorName === values.IndicatorName
    );
    if (ind > -1) {
      toastr.error('Indicator already added');
      return;
    }
    onChange([...(value || []), values]);
    setTimeout(() => {
      form.reset();
    });
  };

  const onUpdateIndicator = (values) => {
    const ind = [...(value || [])].findIndex(
      (indicator, idx) =>
        indicator &&
        indicator.IndicatorName === values.IndicatorName &&
        idx !== selectedIndex
    );
    if (ind > -1) {
      toastr.error('Duplicate Indicator');
      return;
    }
    const index = selectedIndex;
    const categories = [...(value || [])];
    categories[index] = values || {};
    setediting({});
    selectIndex(0);
    setTimeout(() => {
      onChange([...categories]);
    });
  };
  const handleFormSubmit = (group, form) => {
    if (selectedIndex > -1) {
      onUpdateIndicator(group, form);
    } else {
      onAddIndicator(group, form);
    }
  };
  const onSortIndicators = (indicators) => {
    onChange([...(indicators || [])]);
  };

  const onEditIndicator = (indicator, index) => {
    setediting(indicator);
    selectIndex(index);
  };

  const removeIndicator = (index) => {
    const categories = [...(value || [])];
    categories.splice(index, 1);
    onChange([...categories]);
  };

  const renderIndicators = () => {
    const groups = [...(value || [])];
    const renderItems = groups.map((indicator, index) => (
      <ListMenuItem
        dense
        key={indicator.IndicatorName}
        button={false}
        icon={<DragHandle style={{ cursor: 'move' }} />}
        primary={
          <Text h5 style={{ marginRight: 75 }}>
            {index + 1}. {indicator.IndicatorName || ''}
          </Text>
        }
        action={
          <Block flex={false} row>
            <IconButton
              aria-label={indicator || ''}
              onClick={() => onEditIndicator(indicator, index)}
            >
              <Edit color="primary" />
            </IconButton>
            <IconButton
              aria-label={indicator || ''}
              onClick={() => removeIndicator(index)}
            >
              <CloseIcon style={{ color: colors.error }} />
            </IconButton>
          </Block>
        }
      />
    ));

    return (
      <ListMenu>
        <SortableList options={groups} onChange={onSortIndicators}>
          {renderItems}
        </SortableList>
      </ListMenu>
    );
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={editing || {}}
      render={({ handleSubmit, submitting, pristine }) => (
        <Block row padding={sizes.padding} margin={sizes.smallMargin} paper>
          <Block flex={false}>
            <Text h5 semibold>
              Safeguard Indicators
            </Text>
            {renderIndicators()}
          </Block>
          <Block>
            <Button
              onClick={() => {
                setediting({});
                selectIndex(-1);
              }}
              style={{ alignSelf: 'flex-end' }}
            >
              <Text small error>
                Clear
              </Text>
            </Button>
            <Field
              type="text"
              label="Indicator Name"
              required
              name="IndicatorName"
              component={Input}
            />
            <Field
              type="text"
              label="Description"
              name="Description"
              component={TextArea}
            />
            <Field
              type="text"
              label="Definition"
              name="Definition"
              component={TextArea}
            />
            <Field
              type="text"
              label="Required Assessment Information"
              required
              name="AssessedInformation"
              component={TextArea}
            />
            <Field
              type="text"
              label="Methodology/how information is submitted to SIS"
              name="InformationSubmissionMethodology"
              component={TextArea}
            />
            <Field
              type="text"
              label="Assessment Question"
              required
              name="AssessmentQuestion"
              component={TextArea}
            />
            <Field
              type="text"
              label="Entities Responsible for Reporting"
              name="ReportingEntity"
              component={TextArea}
            />
            <Field
              type="text"
              label="Institution Responsible for Verifying Quality of Information "
              name="VerifyingEntity"
              component={RemoteSelector}
              url="/stakeholders/stakeholder/filtersearch"
              axiosinstance={adminAxiosInstance}
              labelExtractor={(item) => (item || {}).StakeholderName}
              keyExtractor={(item) => (item || {}).id}
              valueExtractor={(row) =>
                row && row.id
                  ? {
                      id: row.id,
                      StakeholderName: row.StakeholderName,
                      _id: row._id,
                      StakeholderCode: row.StakeholderCode,
                    }
                  : row
              }
              isMulti
            />
            <Field
              type="text"
              label="Verification Note (Guide for verifying institutions)"
              name="VerificationNote"
              component={TextArea}
            />
            <Field
              type="text"
              label="Manner of Verification"
              name="VerificationManner"
              component={TextArea}
            />
            <Field
              type="text"
              label="Verification Question (Answered by Verifying institutions)"
              name="VerificationQuestion"
              component={TextArea}
            />
            <Field
              type="text"
              label="Institution Responsible for Validating Quality of Information "
              name="ValidattingEntity"
              component={RemoteSelector}
              url="/stakeholders/stakeholder/filtersearch"
              axiosinstance={adminAxiosInstance}
              labelExtractor={(item) => (item || {}).StakeholderName}
              keyExtractor={(item) => (item || {}).id}
              valueExtractor={(row) =>
                row && row.id
                  ? {
                      id: row.id,
                      StakeholderName: row.StakeholderName,
                      _id: row._id,
                      StakeholderCode: row.StakeholderCode,
                    }
                  : row
              }
              isMulti
            />
            <Field
              type="text"
              label="Validation Note (Guide for verifying stakeholders)"
              name="ValidationNote"
              component={TextArea}
            />
            <Field
              type="text"
              label="Validation Question (Answered by Verifying Stakeholder)"
              required
              name="ValidationQuestion"
              component={TextArea}
            />
            <Field
              type="text"
              label="Institution Reponsible for Publishing Report on SIS "
              name="PublishingEntity"
              component={RemoteSelector}
              url="/stakeholders/stakeholder/filtersearch"
              axiosinstance={adminAxiosInstance}
              labelExtractor={(item) => (item || {}).StakeholderName}
              keyExtractor={(item) => (item || {}).id}
              valueExtractor={(row) =>
                row && row.id
                  ? {
                      id: row.id,
                      StakeholderName: row.StakeholderName,
                      _id: row._id,
                      StakeholderCode: row.StakeholderCode,
                    }
                  : row
              }
              isMulti
            />
            {selectedIndex > -1 ? (
              <FieldButton
                disabled={submitting || pristine}
                contained
                color={colors.primary}
                onClick={handleSubmit}
              >
                Update Indicator
              </FieldButton>
            ) : (
              <FieldButton
                disabled={submitting || pristine}
                contained
                color={colors.primary}
                onClick={handleSubmit}
              >
                Add Indicator
              </FieldButton>
            )}
          </Block>
        </Block>
      )}
    />
  );
};
SafeguardIndicatorForm.defaultProps = {
  input: {
    onChange: () => {},
    value: [],
  },
};

export default SafeguardIndicatorForm;
