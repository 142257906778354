import React from 'react';
import { styled } from '@mui/material/styles';
import AboutHero from '../about/AboutHero';
import PlrContent from './PlrContent';

const RootStyle = styled('div')({
  height: '100%',
});

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100vw',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export default function PLR({ Title }) {
  return (
    <RootStyle title="REDD+ Kenya SIS" id="PLRs">
      <AboutHero
        page={{
          Title: 'Policies Laws and Regulations',
          LongTitle: '',
          Body: 'Kenyan Policies Laws and Regulations Relevant to REDD+ ',
        }}
      />
      <ContentStyle>
        <PlrContent />
      </ContentStyle>
    </RootStyle>
  );
}
