import React from 'react';
import moment from 'moment';
import Dialog from '@cloudhub-ux/core/dialog/Dialog';
import { Button } from '@cloudhub-ux/core';
import {
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@cloudhub-ux/core/dialog';
import { colors } from 'theme';
import AuthContext from 'auth/context/AuthContext';

const IdleTimeOutHandler = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [isLogout, setLogout] = React.useState(false);
  const [countdown, setCountdown] = React.useState(false);
  const logoutTimeout = React.useRef(null);
  const {
    actions,
    LastInteraction,
    sessioncounter,
    isAuthenticated,
  } = React.useContext(AuthContext);

  const { setLastInteraction, signoutUser } = actions;
  let timer = undefined;
  const events = ['click', 'load', 'scroll', 'keydown'];

  React.useEffect(() => {
    if (showModal && !countdown) {
      logoutTimeout.current = setTimeout(() => {
        signoutUser();
        setShowModal(false);
        setCountdown(false);
      }, 10000);
    }
    if (!showModal) {
      try {
        clearTimeout(logoutTimeout.current);
        setCountdown(false);
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [showModal, countdown]);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      const diff = moment().diff(moment(LastInteraction), 'millisecond');
      let timeOutInterval = sessioncounter || 60000;
      if (isLogout) {
        clearTimeout(timer);
      } else {
        if (diff._milliseconds < timeOutInterval) {
          startTimer();
        } else {
          setShowModal(true);
        }
      }
    }, sessioncounter || 6000);
  };
  const eventHandler = () => {
    if (!isLogout) {
      setLastInteraction(moment().valueOf());
      if (timer) {
        startTimer();
      }
    }
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const handleContinueSession = () => {
    setShowModal(false);
    setLogout(false);
  };
  const handleLogout = () => {
    removeEvents();
    clearTimeout(timer);
    setLogout(true);
    signoutUser();
    setShowModal(false);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      addEvents();

      return () => {
        removeEvents();
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleContinueSession}
      open={showModal}
      minHeight={200}
    >
      <DialogHeader>Session Timeout</DialogHeader>
      <DialogContent>
        Your Session will expire soon. Continue your session?
      </DialogContent>
      <DialogActions>
        <Button color={colors.error} onClick={handleLogout}>
          Logout
        </Button>
        <Button color={colors.primary} onClick={handleContinueSession}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdleTimeOutHandler;
