import React from 'react';
import CKEditor from '@cloudhub-ux/core/CKEditor';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import SafeguardIndicatorForm from './SafeguardIndicatorForm';
import DialogLayer from 'localcomponents/DialogLayer';
import { sizes } from 'theme';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';

const SafeguardForm = (props) => {
  const { postData, error, reset } = useAdminAxios({});

  const addSafeguard = async (values) => {
    try {
      const data = await postData({
        url: `/safeguards/safeguard/savesafeguard`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="85%">
      <Form
        onSubmit={addSafeguard}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).SafeguardName || 'Add Safeguard'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block padding={sizes.padding}>
                <FieldBlock row wrap margin={[sizes.doubleBaseMargin, 0]}>
                  <Field
                    type="text"
                    label="Safeguard Name"
                    required
                    name="SafeguardName"
                    component={Input}
                    flex
                  />
                  <Field
                    name="Theme"
                    component={StaticListSelector}
                    label="Safeguard Theme"
                    options={[
                      'Governance',
                      'Do no harm',
                      'Securing Multiple Benefits',
                      'Assuring mitigation Effectiveness',
                    ]}
                    isMulti
                    flex
                  />
                </FieldBlock>

                <Field
                  type="text"
                  label="Description"
                  name="Description"
                  component={CKEditor}
                  height={300}
                />
                <Field name="Indicators" component={SafeguardIndicatorForm} />
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default SafeguardForm;
