import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
// import Loading from '@cloudhub-ux/core/Loading';
import AuthContext from 'auth/context/AuthContext';

import WebsiteApp from './app/App';
import Block from '@cloudhub-ux/core/Block';
import ScreenLoading from 'localcomponents/ScreenLoading';
import AdminApp from 'admin/AdminApp';
import VerificationApp from 'actionverification/VerificationApp';
// const AdminApp = React.lazy(() => import('admin/AdminApp'));
// const VerificationApp = React.lazy(() =>
//   import('activityverification/VerificationApp')
// );

const HomeRoute = () => {
  const { isAuthenticated, currentUser, loading } = React.useContext(
    AuthContext
  );

  const isAdmin = isAuthenticated && currentUser.UserType.includes('Admin');
  const isVerifier =
    isAuthenticated &&
    (currentUser.UserType.includes('Verifier') ||
      currentUser.UserType.includes('Validator'));

  if (loading) {
    return (
      <Block>
        <ScreenLoading />
      </Block>
    );
  }

  /* {!isAdmin && <Redirect noThrow from="/admin" to="/signin" />} */
  return (
    // <React.Suspense fallback={<Loading />}>
    <Router style={{ flex: 1, display: 'flex' }}>
      <WebsiteApp path="/" default />
      {isAdmin && <AdminApp path="/admin/*" />}
      {isVerifier && <VerificationApp path="/actionverification/*" />}
    </Router>
    // </React.Suspense>
  );
};
export default HomeRoute;
