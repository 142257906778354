import React from 'react';
import Block from '@cloudhub-ux/core/Block';
import IconButton from '@cloudhub-ux/core/IconButton';
import Text from '@cloudhub-ux/core/Text';
import Input from '@cloudhub-ux/core/Input';
import Refresh from '@mui/icons-material/Refresh';
import { sizes } from 'theme';
import Field from '@cloudhub-ux/core/form/Field';
import Form from '@cloudhub-ux/core/form/Form';
import { FormSpy } from '@cloudhub-ux/core/form';
import { useDebounce } from '@cloudhub-ux/core/customhooks';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Stack,
} from '@mui/material';

const GridHeader = ({
  header,
  permissions,
  onSearch,
  queryString,
  Status,
  setStatus,
  ...props
}) => {
  const [searchTerm, setsearchTerm] = React.useState('');
  const debouncedSearch = useDebounce(searchTerm, 1500);

  React.useEffect(() => {
    if (typeof debouncedSearch === 'string') {
      onSearch(debouncedSearch);
    }
  }, [onSearch, debouncedSearch]);

  if (header) {
    return (
      <Block flex={false}>
        {header({
          ...props,
          ...permissions,
          queryString,
          onSearch,
        })}
      </Block>
    );
  }

  return (
    <Block paper flex={false} row space="between" padding={sizes.padding}>
      <Text h4 bold>
        {props.title}
      </Text>

      <Stack spacing={2} direction="row">
        <FormControl component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={Status}
            onChange={(event) => setStatus(event.target.value)}
            row
          >
            <FormControlLabel
              value="PENDING_STAKEHOLDER_RESPONSE"
              control={<Radio />}
              label="Pending Response"
            />
            <FormControlLabel
              value="NOT_SUBMITTED"
              control={<Radio />}
              label="Not Submitted"
            />
            <FormControlLabel
              value="SUBMITTED"
              control={<Radio />}
              label="Submitted"
            />
          </RadioGroup>
        </FormControl>
        <Form
          onSubmit={() => {}}
          render={() => (
            <React.Fragment>
              <Field name="search" value={searchTerm} component={Input} />
              <FormSpy
                subscriptions={{ values: true }}
                onChange={({ values }) => {
                  if (typeof (values || {}).search === 'string') {
                    setTimeout(() => {
                      setsearchTerm(values.search);
                    });
                  }
                }}
              />
            </React.Fragment>
          )}
        />

        <IconButton onClick={props.onRefresh}>
          <Refresh />
        </IconButton>
      </Stack>
    </Block>
  );
};

export default GridHeader;
