import React from 'react';
import { styled } from '@mui/material/styles';
import AboutHero from './AboutHero';
import AppContext from 'app/context/AppContext';
import AboutContent from './AboutContent';
import { useLocation } from '@cloudhub-ux/core/customhooks';

const RootStyle = styled('div')({
  height: '100%',
});

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100vw',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export default function AboutPage({ Title }) {
  const [aboutPage, setaboutPage] = React.useState({
    Title: '',
    LongTitle: '',
    Body: '',
  });

  const { location } = useLocation();

  const {
    AppSettings: { InfoPages },
  } = React.useContext(AppContext);

  React.useEffect(
    () => {
      const section = location.pathname.split('/').filter(Boolean)[0];
      let sectionName = 'About';
      switch (section) {
        case 'about':
          sectionName = 'About';
          break;
        case 'safeguards':
          sectionName = 'Safeguards';
          break;
        case 'stakeholders':
          sectionName = 'Stakeholders';

          break;
        case 'resources':
          sectionName = 'Resources';
          break;
        case 'fgrm':
          sectionName = 'FGRM';
          break;
        default:
          sectionName = 'About';
          break;
      }

      if (Title) {
        const ind = InfoPages.findIndex((page) => {
          return (
            page.Section === sectionName &&
            (page.Title || '')
              .replace(/[^A-Za-z0-9]/g, '')
              .replace(/\s/g, '') === Title
          );
        });
        if (ind > -1) {
          setaboutPage(InfoPages[ind]);
        }
      } else {
        const ind = InfoPages.findIndex(
          (page) => page.Section === sectionName && page.Rank === 1
        );
        if (ind > -1) {
          setaboutPage(InfoPages[ind]);
        }
      }
    },
    // eslint-disable-next-line
    [JSON.stringify(InfoPages), Title, location.pathname]
  );

  return (
    <RootStyle title="REDD+ Kenya SIS" id="about_sis">
      <AboutHero page={aboutPage} />
      <ContentStyle>
        <AboutContent page={aboutPage} />
      </ContentStyle>
    </RootStyle>
  );
}
