import React from 'react';
import { motion } from 'framer-motion';
import { styled, alpha } from '@mui/material/styles';
import { Container, Typography, Grid, Button } from '@mui/material';
import {
  varFadeIn,
  varWrapEnter,
  varFadeInRight,
} from '@cloudhub-ux/min/theme/animate';
import { Images, colors } from 'theme';
import AppContext from 'app/context/AppContext';

const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `linear-gradient(to right, ${alpha(colors.dark, 1)}, ${alpha(
    colors.dark,
    0.7
  )}), url(${Images.landing})`,
  padding: theme.spacing(10, 0),
  width: '100vw',
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    // position: 'absolute',
    // bottom: theme.spacing(10),
  },
}));

export default function GrievanceHero() {
  const {
    GrievanceState: { Partners },
  } = React.useContext(AppContext);

  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      <Container
        maxWidth="lg"
        sx={{ position: 'relative', height: '100%', pt: 5 }}
      >
        <ContentStyle>
          <motion.div variants={varFadeInRight}>
            <Typography color="primary" variant="h2">
              File Grievance with a REDD+ Institution
            </Typography>
          </motion.div>
          <Grid container spacing={5} sx={{ mt: 5, color: 'common.white' }}>
            {Partners.map((partner) => (
              <Grid
                key={partner.StakeholderName}
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                sx={{ pr: { md: 5 } }}
              >
                <motion.div variants={varFadeIn}>
                  <a
                    href={(partner.GrievanceRedress || {}).URL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Typography
                      variant="h6"
                      paragraph
                      sx={{ color: 'common.white' }}
                    >
                      {partner.StakeholderName}
                    </Typography>
                  </a>
                </motion.div>
                <motion.div variants={varFadeInRight}>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      maxHeight: '110px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      mb: 2,
                    }}
                  >
                    {(partner.GrievanceRedress || {}).Description}
                  </Typography>
                </motion.div>
                <motion.div
                  variants={varFadeInRight}
                  style={{ marginTop: 'auto' }}
                >
                  <a
                    href={(partner.GrievanceRedress || {}).URL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button variant="contained" color="primary" fullWidth>
                      GO
                    </Button>
                  </a>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
