import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useTheme, styled, alpha } from '@mui/material/styles';
import {
  Box,
  Card,
  Button,
  Paper,
  Typography,
  CardContent,
} from '@mui/material';
import { Link } from '@cloudhub-ux/core/reach';
import {
  varFadeInRight,
  MotionContainer,
} from '@cloudhub-ux/min/theme/animate';
import useAppAxios from 'app/context/useAppAxios';
import CarouselControlsArrowsIndex from '../views/landing/CarouselControlsArrowsIndex';
import { Images } from 'theme';
import './slick.css';

const RootStyle = styled('div')(({ theme }) => ({
  backgroundImage: `linear-gradient(120deg, black, black, black, white, red,red, white, green, green,green)`,
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(8),
  },
}));

const CarouselImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

CarouselItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
};

function CarouselItem({ item, isActive }) {
  const theme = useTheme();
  const { image, SafeguardName } = item;

  return (
    <Link to={`/safeguards/safeguard/${SafeguardName}`}>
      <Paper
        sx={{
          position: 'relative',
          paddingTop: { xs: '100%', md: '50%' },
          pr: 2,
          borderRadius: 15,
        }}
      >
        <CarouselImgStyle alt={SafeguardName} src={image || Images.landing3} />
        <Box
          sx={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundImage: `linear-gradient(to right, ${
              theme.palette.grey[900]
            } 20%,${alpha(theme.palette.grey[900], 0.9)} 5%)`,
          }}
        />
        <CardContent
          sx={{
            bottom: 0,
            width: '100%',
            maxWidth: 480,
            textAlign: 'left',
            position: 'absolute',
            color: 'common.white',
          }}
        >
          <MotionContainer open={isActive}>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h3" gutterBottom>
                {item.SafeguardName}
              </Typography>
            </motion.div>
            <motion.div variants={varFadeInRight}>
              <Typography
                variant="h6"
                noWrap
                gutterBottom
                dangerouslySetInnerHTML={{ __html: item.Description }}
              />
            </motion.div>
            <motion.div variants={varFadeInRight}>
              <Button variant="contained" sx={{ mt: 3 }}>
                Learn More
              </Button>
            </motion.div>
          </MotionContainer>
        </CardContent>
      </Paper>
    </Link>
  );
}
export default function LandingSafeguards() {
  const [safeguards, setsafeguards] = React.useState([]);
  const theme = useTheme();
  const carouselRef = React.useRef();
  const [currentIndex, setCurrentIndex] = React.useState(
    theme.direction === 'rtl' ? safeguards.length - 1 : 0
  );

  const settings = {
    speed: 500,
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    adaprtiveHeight: true,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };
  const { getData } = useAppAxios({});

  const getSafeguards = async () => {
    try {
      const data = await getData({ url: '/app/safeguards/safeguard/getall' });
      if (data && data.items) {
        setsafeguards(data.items);
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getSafeguards(); // eslint-disable-next-line
  }, []);

  return (
    <RootStyle>
      <Card
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <Slider ref={carouselRef} {...settings}>
          {safeguards.map((item, index) => (
            <CarouselItem
              key={item.SafeguardCode}
              item={item}
              isActive={index === currentIndex}
            />
          ))}
        </Slider>

        <CarouselControlsArrowsIndex
          index={currentIndex}
          total={safeguards.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
      </Card>
    </RootStyle>
  );
}
