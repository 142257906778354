import React from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer as MuiDrawer } from '@mui/material';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { IconButton, Block, Text } from '@cloudhub-ux/core';
import Typography from '@mui/material/Typography';
import { colors, sizes } from 'theme';

const getStyles = ({ drawerWidth }) => {
  const useStyles = makeStyles({
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
      borderWidth: 0,
    },
    drawerContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  });

  return {
    useStyles,
  };
};

const Drawer = ({ open, drawerWidth, ...props }) => {
  const classes = getStyles({ drawerWidth }).useStyles();

  return (
    <MuiDrawer
      variant="persistent"
      classes={{
        root: classes.root,
        paper: classes.drawerPaper,
      }}
      open={open}
    >
      <Block row middle flex={false} shadow color={colors.mistyWhite}>
        <Block
          row
          middle
          center
          style={{ height: sizes.verificationNavBarHeight }}
        >
          <Block flex={false} padding={[0, sizes.padding]}>
            {/* <img
              src={Images.logo}
              alt="logo"
              style={{ width: '100%', objectFit: 'contain' }}
            /> */}
            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 }}>
              KENYA REDD+ SIS
            </Typography>
          </Block>
        </Block>
        <Block flex={false}>
          <IconButton style={{ alignItems: 'center' }} onClick={props.onClose}>
            <ChevronLeft />
          </IconButton>
        </Block>
      </Block>

      <Block
        column
        style={{
          borderRight: '1px solid #CCC',
          // marginRight: -20,
        }}
      >
        <Block style={{ position: 'relative' }}>
          <Block className={classes.drawerContainer}>{props.children}</Block>
        </Block>
        <Block flex={false} shadow middle center>
          <Text small facebook>
            Developed By Cloud Hub
          </Text>
        </Block>
      </Block>
    </MuiDrawer>
  );
};

Drawer.defaultProps = {
  open: true,
  drawerWidth: 270,
  onClose: () => {},
};

export default Drawer;
