import React from 'react';

const INITIAL_STATE = {
  Organization: {
    OrganizationName: 'Cloud Hub Limited',
    Address: {
      Line1: 'Po Box 2255',
      Line2: '',
      City: 'Nairobi',
    },
    Email: 'info@cloudhub.co.ke',
    Phone: '+254794588883',
  },
  InfoPages: [],
};

export const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_ORG': {
      return {
        ...state,
        Organization: { ...action.payload },
      };
    }
    case 'SET_INFO_PAGES': {
      return {
        ...state,
        InfoPages: [...action.payload],
      };
    }

    default:
      return state;
  }
};

const useAppSettings = ({ axiosinstance }) => {
  const [state, dispatch] = React.useReducer(appReducer, INITIAL_STATE);

  const getOrganization = async () => {
    try {
      const { data } = await axiosinstance().get(
        `/app/settings/appsetting/getorganization`
      );

      if (data) {
        dispatch({
          type: 'SET_ORG',
          payload: data,
        });
      }
    } catch (error) {}
  };

  const getInfoPages = async () => {
    try {
      const { data } = await axiosinstance().get(
        `/app/settings/appsetting/getinfopages`
      );

      if (data && Array.isArray(data.items)) {
        dispatch({
          type: 'SET_INFO_PAGES',
          payload: data.items,
        });
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getOrganization();
    getInfoPages();
    // eslint-disable-next-line
  }, []);
  return {
    getOrganization,
    getInfoPages,
    ...state,
  };
};

export default useAppSettings;
