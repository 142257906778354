import React from 'react';
import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import Gavel from '@iconify/icons-ic/baseline-gavel';
import Help from '@iconify/icons-ic/baseline-help-center';
import activityOutline from '@iconify/icons-eva/activity-outline';
import archiveOutline from '@iconify/icons-eva/archive-outline';
import infoFill from '@iconify/icons-eva/info-fill';
import peopleOutline from '@iconify/icons-eva/people-outline';
import shieldFill from '@iconify/icons-eva/shield-fill';

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = (pages, Safeguards) => {
  const aboutpages = (pages || [])
    .filter((page) => page.Section === 'About')
    .sort((a, b) => a.Rank - b.Rank);
  const safeguardspages = (pages || [])
    .filter((page) => page.Section === 'Safeguards')
    .sort((a, b) => a.Rank - b.Rank);
  const stakeholderspages = (pages || [])
    .filter((page) => page.Section === 'Stakeholders')
    .sort((a, b) => a.Rank - b.Rank);
  const resourcespages = (pages || [])
    .filter((page) => page.Section === 'Resources')
    .sort((a, b) => a.Rank - b.Rank);
  const fgrmpages = (pages || [])
    .filter((page) => page.Section === 'FGRM')
    .sort((a, b) => a.Rank - b.Rank);
  return [
    {
      title: 'Home',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/',
    },
    {
      title: 'About',
      icon: <Icon icon={infoFill} {...ICON_SIZE} />,
      path: '/about',
      children: [
        {
          subheader: 'About',
          items: aboutpages.map((page) => ({
            title: page.Title,
            path: `/about/${(page.Title || '')
              .replace(/[^A-Za-z0-9]/g, '')
              .replace(/\s/g, '')}`,
          })),
        },
      ],
    },
    {
      title: 'Safeguards',
      icon: <Icon icon={shieldFill} {...ICON_SIZE} />,
      path: '/safegurads',
      children: [
        {
          subheader: 'Safeguards',
          items: [
            ...safeguardspages.map((page) => ({
              title: page.Title,
              path: `/safeguards/${(page.Title || '')
                .replace(/[^A-Za-z0-9]/g, '')
                .replace(/\s/g, '')}`,
            })),
            {
              title: 'The National REDD+ Safeguards System ',
              path: '',
              childroutes: [
                ...(Safeguards || []).map((safeguard) => ({
                  title: safeguard.SafeguardName,
                  path: `/safeguards/safeguard/${safeguard.SafeguardName}`,
                })),
              ],
            },
            {
              title: 'Policies Laws and Regulations',
              path: '/safeguards/plrs',
            },
          ],
        },
      ],
    },
    {
      title: 'Stakeholders',
      icon: <Icon icon={peopleOutline} {...ICON_SIZE} />,
      path: '/stakeholders',
      children: [
        {
          subheader: 'Partners, Programs & Consultations',
          items: [
            {
              title: 'Institutions',
              path: '/stakeholders/institutions',
            },
            {
              title: 'Secretariat',
              path: '/stakeholders/secretariat',
            },
            ...stakeholderspages.map((page) => ({
              title: page.Title,
              path: `/stakeholders/${(page.Title || '')
                .replace(/[^A-Za-z0-9]/g, '')
                .replace(/\s/g, '')}`,
            })),
          ],
        },
      ],
    },
    {
      title: 'Resources',
      icon: <Icon icon={archiveOutline} {...ICON_SIZE} />,
      path: '/resources',
      children: [
        {
          subheader: 'REDD+ Resources',
          items: [
            ...resourcespages.map((page) => ({
              title: page.Title,
              path: `/resources/${(page.Title || '')
                .replace(/[^A-Za-z0-9]/g, '')
                .replace(/\s/g, '')}`,
            })),
            {
              title: 'Reports',
              path: `/resources/reports`,
            },
            {
              title: 'Media',
              path: `/resources/media`,
            },
          ],
        },
      ],
    },
    {
      title: 'Actions',
      icon: <Icon icon={activityOutline} {...ICON_SIZE} />,
      path: '/actions',
      children: [
        {
          subheader: 'Actions',
          items: [
            { title: 'New Action', path: '/actions/new' },
            { title: 'My Actions', path: '/actions/myactions' },
          ],
        },
      ],
    },
    {
      title: 'FGRM',
      icon: <Icon icon={Gavel} {...ICON_SIZE} />,
      path: '/FGRM',
      children: [
        {
          subheader: 'REDD+ FGRM',
          items: [
            ...fgrmpages.map((page) => ({
              title: page.Title,
              path: `/fgrm/${(page.Title || '')
                .replace(/[^A-Za-z0-9]/g, '')
                .replace(/\s/g, '')}`,
            })),
            {
              title: 'Submit Grievance',
              path: '/fgrm/grievance',
            },
            {
              title: 'Send Feedback',
              path: '/fgrm/feedback',
            },
          ],
        },
      ],
    },
    {
      title: 'Help',
      icon: <Icon icon={Help} {...ICON_SIZE} />,
      path: '/help',
      children: [
        {
          subheader: 'Contact and Support Info',
          items: [
            {
              title: 'Contact Us',
              path: '/help/contactus',
            },
            {
              title: 'FAQ',
              path: '/help/faq',
            },
            {
              title: 'User Guide',
              path: '/help/userguide',
            },
          ],
        },
      ],
    },
  ];
};

export default menuConfig;
