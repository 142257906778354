import { toastr } from '@cloudhub-ux/core';

export const SET_PARTNERS = 'SET_PARTNERS';
export const SET_TRACKING_GRIEVANCE = 'SET_TRACKING_GRIEVANCE';

const grievanceActions = ({ dispatch, axiosinstance }) => {
  const actions = {
    getPartners: async () => {
      const { data } = await axiosinstance().get(
        '/app/stakeholders/stakeholder/grievanceredresspartners'
      );
      if (data && Array.isArray(data.items)) {
        dispatch({
          type: SET_PARTNERS,
          payload: data.items,
        });
      }
    },
    getGrievance: async (values) => {
      try {
        const { data } = await axiosinstance().get(
          '/app/fgrm/grievance/retrievegrievance',
          {
            params: values,
          }
        );
        if (data && data.id) {
          dispatch({
            type: SET_TRACKING_GRIEVANCE,
            payload: data,
          });
        }
      } catch (error) {
        const response = error.response || {};
        const data = response.data || {};
        const message = data.message || error.message || error;
        toastr.error(message);
      }
    },
    closeGrievance: () => {
      dispatch({
        type: SET_TRACKING_GRIEVANCE,
        payload: {},
      });
    },
    updateTrackingGrievance: () => {
      dispatch({
        type: SET_TRACKING_GRIEVANCE,
        payload: {},
      });
    },
  };
  return actions;
};
export default grievanceActions;
