import React from 'react';
import { motion } from 'framer-motion';
import { Block } from '@cloudhub-ux/core';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Stack } from '@mui/material';
import {
  varFadeIn,
  varWrapEnter,
  varFadeInRight,
} from '@cloudhub-ux/min/theme/animate';
import { Images } from 'theme';

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${Images.landing})`,
  backgroundColor: theme.palette.grey[400],
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: 700,
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));
const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    // maxWidth: 520,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left',
    },
  })
);

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

export default function SafeguardHero({ page }) {
  return (
    <>
      <RootStyle
        initial="initial"
        animate="animate"
        variants={varWrapEnter}
        pageImage={((page || {}).Picture || {}).Location}
      >
        <HeroOverlayStyle
          alt="overlay"
          src={Images.overlay}
          variants={varFadeIn}
        />

        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInRight}>
              <Block>
                <Typography variant="h1" sx={{ color: 'common.white' }}>
                  {(page || {}).SafeguardName}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: 'common.white' }}
                  dangerouslySetInnerHTML={{
                    __html: (page || {}).Description || '<p>About</p>',
                  }}
                />
              </Block>
            </motion.div>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: 500 } }} />
    </>
  );
}
