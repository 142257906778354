import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Faqs from './faq/Faqs';
import ContactUs from './contact/ContactUs';
import UserGuide from './guide/Userguide';

const HelpRoutes = () => {
  return (
    <Router style={{ flex: 1 }}>
      <Faqs path="faq" />
      <ContactUs path="contactus" />
      <UserGuide path="userguide" />
    </Router>
  );
};

export default HelpRoutes;
