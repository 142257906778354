import React from 'react';
import CKEditor from '@cloudhub-ux/core/CKEditor';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
  DatePicker,
  TextArea,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';
import DialogLayer from 'localcomponents/DialogLayer';

const PlrForm = (props) => {
  const { postData, error, reset } = useAdminAxios({});

  const addPlr = async (values) => {
    try {
      const data = await postData({
        url: `/safeguards/plr/saveplr`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="75%">
      <Form
        onSubmit={addPlr}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).PlrName || 'Add PLR'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <FieldBlock row wrap>
                  <Field
                    type="text"
                    label="PLR Name"
                    required
                    name="PlrName"
                    component={Input}
                    flex
                  />
                  {(editingRow || {}).id && (
                    <Field
                      type="text"
                      label="PLR Code"
                      required
                      name="PlrCode"
                      component={Input}
                      flex
                      readOnly
                    />
                  )}
                  <Field
                    name="PlrType"
                    label="PLR Type"
                    component={StaticListSelector}
                    flex
                    options={['POLICY', 'LAW', 'REGULATION']}
                  />
                </FieldBlock>
                <FieldBlock row wrap>
                  <Field
                    type="text"
                    label="PLR Subject"
                    required
                    name="Subject"
                    component={Input}
                    flex
                  />
                  <Field
                    name="EffectedDate"
                    label="Date Effected"
                    component={DatePicker}
                    required
                    flex
                  />
                </FieldBlock>
                <Field
                  name="LongTitle"
                  label="Long Title"
                  component={TextArea}
                />
                <Field
                  type="text"
                  label="Description"
                  name="Description"
                  component={CKEditor}
                  height={400}
                />
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default PlrForm;
