import React from 'react';
import { styled } from '@mui/material/styles';
import LandingHero from './LandingHero';
import LandingGrievance from './LandingGrievance';
import LandingSafeguards from '../../safeguards/LandingSafeguards';
// import LandingPricingPlans from './LandingPricingPlans';
// import LandingHugePackElements from './LandingHugePackElements';
// import LandingThemeColor from './LandingThemeColor';
// import LandingCleanInterfaces from './LandingCleanInterfaces';
// import LandingAdvertisement from './LandingAdvertisement';

const RootStyle = styled('div')({
  height: '100%',
});

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  width: '100vw',
}));

export default function LandingPage() {
  return (
    <RootStyle title="REDD+ Kenya SIS" id="move_top">
      <LandingHero />
      <ContentStyle>
        <LandingSafeguards />
        {/* <LandingPricingPlans />
        <LandingHugePackElements />
        <LandingThemeColor />
        <LandingCleanInterfaces />
        <LandingAdvertisement /> */}
        <LandingGrievance />
      </ContentStyle>
    </RootStyle>
  );
}
