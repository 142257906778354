import React from 'react';
import {
  GridContainer,
  GridItem,
  Block,
  TextArea,
  Alert,
  Text,
  toastr,
  Button,
  ListSubMenu,
  CheckBox,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { makeStyles } from '@mui/styles';
import { LayersManager } from '@cloudhub-ux/core/baseweb';
import { colors, sizes } from 'theme';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import { Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import VerificationContext from 'actionverification/context/VerificationContext';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingBottom: sizes.padding * 2,
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const ActionvalidationForm = ({ Validation, onClose }) => {
  const [validation, setvalidation] = React.useState({});
  const [uploading, setuploading] = React.useState(false);
  const [saving, setsaving] = React.useState(false);
  const classes = useStyles();
  const { error, reset, postData } = useVerificationAxios({});

  const {
    VerificationState: { Validator, CurrentAction, Safeguards },
  } = React.useContext(VerificationContext);

  React.useEffect(() => {
    setvalidation(Validation);
    // eslint-disable-next-line
  }, [JSON.stringify(Validation)]);

  const changeFile = (event, form) => {
    if (event && event.target && event.target.value) {
      const v = event.target.value || [];
      const ind = v.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', v);
    }
    if (event && Array.isArray(event)) {
      const ind = event.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', event);
    }
  };

  const onRemoveFiles = async (file) => {
    try {
      const data = await postData({
        url: '/media/upload/deletefiles',
        params: { files: [file] },
      });
      if (data) {
        reset();
        toastr.success('Files deleted successfully');
        return data;
      }
    } catch (error) {}
  };

  const submitValidattion = async (values) => {
    try {
      const data = await postData({
        url: '/actionvalidation/submitvalidation',
        params: values,
      });
      if (data && data.id) {
        toastr.success('Validattion submitted successfully');
        reset();
        onClose();
      }
    } catch (error) {}
  };

  const saveValidattion = async (values) => {
    try {
      console.log(values);
      setsaving(true);
      const data = await postData({
        url: '/actionvalidation/savevalidation',
        params: values,
      });
      if (data && data.id) {
        toastr.success('Validation saved successfully');
        reset();
      }
      setsaving(false);
    } catch (error) {
      console.log(error);
      setsaving(false);
    }
  };
  // console.log((validation || {}).Status);

  return (
    <div className={classes.root}>
      <Form
        onSubmit={submitValidattion}
        initialValues={{ ...validation }}
        validateOnChange={false}
        render={({ handleSubmit, pristine, submitting, form, values }) => (
          <Block
            style={{
              maxHeight: '100%',
              overflow: 'auto',
            }}
          >
            <LayersManager zIndex={1}>
              <GridContainer spacing={2}>
                <GridItem>
                  {Boolean(error) && (
                    <Alert error message={error} onClose={() => reset('')} />
                  )}
                </GridItem>
                {((CurrentAction || {}).Safeguard || []).map(
                  (safeguard, index) => {
                    const ind = Safeguards.findIndex(
                      (sg) =>
                        sg &&
                        sg.SafeguardCode === (safeguard || {}).SafeguardCode
                    );
                    if (ind === -1) {
                      return null;
                    }

                    const {
                      SafeguardName,
                      SafeguardCode,
                      Description,
                      Indicators,
                      id,
                    } = Safeguards[ind];
                    if (
                      !id ||
                      !SafeguardName ||
                      !SafeguardCode ||
                      (Indicators || []).length === 0
                    ) {
                      return null;
                    }
                    return (
                      <ListSubMenu
                        key={id}
                        header={SafeguardName}
                        expanded={index === 0}
                      >
                        <Block
                          shadow
                          margin={sizes.baseMargin}
                          padding={sizes.padding}
                        >
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Text h4 bold primary>
                              {SafeguardName}
                            </Text>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: Description,
                              }}
                            />
                          </GridItem>
                          {Indicators.map((indicator) => {
                            const {
                              IndicatorName,
                              ValidationQuestion,
                              ValidationNote,
                              Description,
                              Definition,
                              AssessedInformation,
                            } = indicator;
                            const verifierindicator = `${IndicatorName}. ${Description}`;
                            if (
                              !IndicatorName ||
                              !ValidationQuestion ||
                              ((Validator || {}).Indicators || []).indexOf(
                                verifierindicator
                              ) === -1
                            ) {
                              return null;
                            }
                            return (
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Field
                                  name={`Safeguards.${SafeguardCode}.${IndicatorName.replace(
                                    /[\s.,]/g,
                                    '_'
                                  )}`}
                                  label={
                                    <Text>
                                      <Text bold>{IndicatorName}:</Text>{' '}
                                      {ValidationQuestion}{' '}
                                      {ValidationNote ? (
                                        <Tooltip
                                          title={
                                            <Block>
                                              <Text>
                                                {ValidationNote || ''}
                                              </Text>
                                              <Text>{Definition || ''}</Text>
                                              <Text>
                                                {AssessedInformation || ''}
                                              </Text>
                                            </Block>
                                          }
                                        >
                                          <InfoOutlined
                                            style={{
                                              color: colors.info,
                                              fontSize: 14,
                                              marginLeft:
                                                sizes.doubleBaseMargin,
                                              marginBottom: -2,
                                            }}
                                          />
                                        </Tooltip>
                                      ) : (
                                        ''
                                      )}
                                    </Text>
                                  }
                                  component={TextArea}
                                  required
                                />
                              </GridItem>
                            );
                          })}
                        </Block>
                      </ListSubMenu>
                    );
                  }
                )}

                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    tag="Does action address and respect safeguards?"
                    name="Compliant"
                    component={CheckBox}
                    height={sizes.icons.medium}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    label="Conclusion"
                    name="Conclusion"
                    component={TextArea}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    label="Recommendation"
                    name="Recommendation"
                    component={TextArea}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    label="Validattion Files"
                    name="Files"
                    url={`/api/media/upload/file`}
                    // axiosinstance={axiosinstance}
                    component={FilesUpload}
                    limit={10}
                    onChange={(event) => changeFile(event, form)}
                    onRemove={onRemoveFiles}
                  />
                </GridItem>
                {(validation || {}).Status !== 'SUBMITTED' && (
                  <React.Fragment>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        fullWidth
                        onClick={() => saveValidattion(values)}
                        color="secondary"
                        disabled={pristine || submitting || saving || uploading}
                      >
                        {`${submitting ? 'Submitting...' : ''}${
                          saving ? 'Saving...' : ''
                        }${uploading ? 'Uploading...' : ''}${
                          !submitting && !saving && !uploading ? 'Save' : ''
                        }`}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        fullWidth
                        onClick={handleSubmit}
                        color="primary"
                        disabled={submitting || saving || uploading}
                      >
                        {`${submitting ? 'Submitting...' : ''}${
                          saving ? 'Saving...' : ''
                        }${uploading ? 'Uploading...' : ''}${
                          !submitting && !saving && !uploading ? 'Submit' : ''
                        }`}
                      </Button>
                    </GridItem>
                  </React.Fragment>
                )}
              </GridContainer>
            </LayersManager>
          </Block>
        )}
      />
    </div>
  );
};

export default ActionvalidationForm;
