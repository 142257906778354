import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  LinearProgress,
  Box,
} from '@mui/material';
import { fShortenNumber } from '@cloudhub-ux/min/utils/formatNumber';
import VerificationContext from 'actionverification/context/VerificationContext';

export default function GrievancesByStatus() {
  const {
    VerificationState: { Tasks },
  } = React.useContext(VerificationContext);
  const { Grievances } = Tasks;
  const {
    PendingGrievances,
    ResolvedGrievances,
    AllGrievances,
    EscalatedGrievances,
    DismissedGrievances,
  } = Grievances || {};

  return (
    <Card>
      <CardHeader title="Grievances" />
      <Stack spacing={3} sx={{ px: 3, my: 5 }}>
        <LinearProgress
          variant="determinate"
          key="pending_grievances"
          value={((PendingGrievances || 0) / (AllGrievances || 1)) * 100}
          color="warning"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          key="resolved_grievances"
          value={((ResolvedGrievances || 0) / (AllGrievances || 1)) * 100}
          color="success"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          key="escalated_grievances"
          value={((EscalatedGrievances || 0) / (AllGrievances || 1)) * 100}
          color="error"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          key="dismissed_grievances"
          value={((DismissedGrievances || 0) / (AllGrievances || 1)) * 100}
          color="secondary"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, pb: 3 }}
      >
        <Stack key="pending" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'warning.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Pending
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(PendingGrievances)}
          </Typography>
        </Stack>
        <Stack key="submitted" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'success.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Resolved
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(ResolvedGrievances)}
          </Typography>
        </Stack>
        <Stack key="escalated" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'orange',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Escalated
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(EscalatedGrievances)}
          </Typography>
        </Stack>
        <Stack key="dismissed" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'secondary.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Dismissed
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(DismissedGrievances)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
