import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
// import { Container } from '@cloudhub-ux/core';
import AuthRoutes from '../auth/AuthRoutes';
import HomePage from './views/landing/LandingPage';
import AppSignIn from './views/AppSignIn';
import ActionsRoutes from './actions/ActionsRoutes';
import AboutPage from './about/AboutPage';
import NewGrievance from './fgrm/GrievancePage';
import FeedbackForm from './fgrm/FeedbackPage';
import HelpRoutes from './help/HelpRoutes';
import Safeguard from './safeguards/Safeguard';
import StakeholderRoutes from './stakeholders/StakeholderRoutes';
import ResourceRoutes from './resources/ResourceRoutes';
import PLR from 'app/safeguards/PLR';
const AppRoutes = () => {
  return (
    <Router
      style={{
        display: 'flex',
        flex: 1,
      }}
    >
      <HomePage default path="/" />
      <AppSignIn path="signin" />
      <AuthRoutes path="auth/*" />
      <AboutPage path="about" />
      <AboutPage path="about/:Title" />
      <AboutPage path="safeguards/:Title" />
      <Safeguard path="safeguards/safeguard/:SafeguardName" />
      <PLR path="safeguards/plrs" />
      <ResourceRoutes path="resources/*" />
      <AboutPage path="consultations/:Title" />
      <AboutPage path="fgrm/:Title" />
      <ActionsRoutes path="actions/*" />
      <NewGrievance path="fgrm/grievance" />
      <FeedbackForm path="fgrm/feedback" />
      <HelpRoutes path="help/*" />
      <StakeholderRoutes path="stakeholders/*" />
    </Router>
  );
};

export default AppRoutes;
