import React from 'react';
import VerificationErrorBoundary from './VerificationErrorBoundary';
import VerificationHome from './VerificationHome';
import VerificationRoutes from './VerificationRoutes';
import VerificationProvider from './context/VerificationProvider';

const VerificationApp = () => {
  return (
    <VerificationErrorBoundary>
      <VerificationProvider>
        <VerificationHome>
          <VerificationRoutes />
        </VerificationHome>
      </VerificationProvider>
    </VerificationErrorBoundary>
  );
};

export default VerificationApp;
