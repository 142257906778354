import React from 'react';
import { styled } from '@mui/material/styles';
import { Fab, Typography } from '@mui/material';
import { MotionContainer } from '@cloudhub-ux/min/theme/animate';
import { Block, GridItem, Container } from '@cloudhub-ux/core';
import AppContext from 'app/context/AppContext';
import InfoResponse from './InfoResponse';
import RetrieveGrievance from './RetrieveGrievance';
import { ExitToApp, Home } from '@mui/icons-material';
import { Link } from '@cloudhub-ux/core/reach';
import { sizes } from 'theme';
import Followup from './Followup';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function GrievanceStatus() {
  const [infoRequest, setInfoRequest] = React.useState(null);
  const {
    GrievanceState: { grievanceActions, TrackingGrievance },
  } = React.useContext(AppContext);
  const { closeGrievance } = grievanceActions;

  const { InformationRequests } = TrackingGrievance || {};
  React.useEffect(() => {
    const openRequests = (InformationRequests || [])
      .filter((rq) => rq && rq.Status === 'PENDING')
      .sort((a, b) => a.Timestamp - b.Timestamp);
    if (openRequests.length > 0) {
      setInfoRequest(openRequests[0]);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(TrackingGrievance)]);

  return (TrackingGrievance || {}).id ? (
    <GridItem xs={12} md={8}>
      <RootStyle title="Retrieve Grievance">
        <Container column>
          <div
            style={{
              float: 'right',
              marginLeft: 'auto',
            }}
          >
            <Link to="/">
              <Fab variant="extended" sx={{ mr: 2 }}>
                <Home sx={{ mr: 1 }} /> Home
              </Fab>
            </Link>
            <Fab variant="extended" onClick={closeGrievance}>
              <ExitToApp sx={{ mr: 1 }} />
              New Grievance
            </Fab>
          </div>
          <MotionContainer initial="initial" open>
            <Block center middle flex={false}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 2 }}>
                {TrackingGrievance.Subject || ''}
              </Typography>

              <Typography variant="h6" sx={{ m: 3 }}>
                {TrackingGrievance.Body || ''}
              </Typography>
            </Block>
            {infoRequest && (
              <Block>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: 'center',
                    my: `${sizes.doubleBaseMargin}px`,
                  }}
                >
                  You have been requested to submit further information on your
                  grievance. Please fill it in the form below and submit
                </Typography>
                <InfoResponse infoRequest={infoRequest} />
              </Block>
            )}
            {Array.isArray(InformationRequests) &&
              InformationRequests.map((rq) => (
                <Block
                  key={(rq || {}).Description}
                  paper
                  margin={sizes.baseMargin}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      my: `${sizes.doubleBaseMargin}px`,
                    }}
                  >
                    {(rq || {}).Description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      my: `${sizes.doubleBaseMargin}px`,
                      ml: 3,
                    }}
                  >
                    {(rq || {}).Response}
                  </Typography>
                  {Array.isArray((rq || {}).Files) &&
                    rq.Files.map((f) => (
                      <a
                        key={f.Location}
                        href={f.Location}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {f.filename}
                      </a>
                    ))}
                </Block>
              ))}
            <Followup />
          </MotionContainer>
        </Container>
      </RootStyle>
    </GridItem>
  ) : (
    <RetrieveGrievance />
  );
}
