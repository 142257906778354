import React from 'react';
import { ListMenu, ListSubMenu, ListMenuItem } from '@cloudhub-ux/core';
import { Link } from '@cloudhub-ux/core/reach';

import {
  Home as HomeIcon,
  Business as AppIcon,
  Settings as SettingsIcon,
  SettingsApplications,
  LocalActivity,
  Work,
  WorkOutline,
  HealthAndSafety,
  GroupWork,
  Mediation,
  Policy,
  ThumbUp,
  PostAdd,
  Language,
  PhotoAlbum,
} from '@mui/icons-material';
import { colors } from 'theme';

const DrawerMenu = () => (
  <ListMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      header="Main Menu"
      expanded
    >
      <Link to="/admin">
        <ListMenuItem
          icon={<HomeIcon style={{ height: 14 }} />}
          primary="Dashboard"
        />
      </Link>
    </ListSubMenu>

    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      headerIcon={<LocalActivity />}
      header="Actions"
    >
      <Link to="/admin/actions/actions">
        <ListMenuItem
          icon={<LocalActivity style={{ height: 14 }} />}
          primary="REDD+ Actions"
        />
      </Link>
      <Link to="/admin/actions/programmes">
        <ListMenuItem
          icon={<Work style={{ height: 14 }} />}
          primary="Programmes"
        />
      </Link>
      <Link to="/admin/actions/projects">
        <ListMenuItem
          icon={<WorkOutline style={{ height: 14 }} />}
          primary="Projects"
        />
      </Link>
      <Link to="/admin/actions/verifications">
        <ListMenuItem
          icon={<WorkOutline style={{ height: 14 }} />}
          primary="Verifications"
        />
      </Link>
      <Link to="/admin/actions/validations">
        <ListMenuItem
          icon={<WorkOutline style={{ height: 14 }} />}
          primary="Validations"
        />
      </Link>
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      header="Safeguards"
      headerIcon={<HealthAndSafety />}
    >
      <Link to="/admin/safeguards">
        <ListMenuItem
          icon={
            <HealthAndSafety style={{ height: 14, color: colors.success }} />
          }
          primary="Safeguards"
        />
      </Link>
      <Link to="/admin/safeguards/plr">
        <ListMenuItem icon={<Policy style={{ height: 14 }} />} primary="PLR" />
      </Link>
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      header="Website"
      headerIcon={<Language />}
    >
      <Link to="/admin/website/infopages">
        <ListMenuItem
          icon={<PostAdd style={{ height: 14 }} />}
          primary="Information Pages"
        />
      </Link>
      <Link to="/admin/website/documents">
        <ListMenuItem
          icon={<PostAdd style={{ height: 14 }} />}
          primary="Documents"
        />
      </Link>
      <Link to="/admin/website/gallery">
        <ListMenuItem
          icon={<PhotoAlbum style={{ height: 14 }} />}
          primary="Gallery"
        />
      </Link>
      <Link to="/admin/website/faq">
        <ListMenuItem icon={<PostAdd style={{ height: 14 }} />} primary="FAQ" />
      </Link>
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      header="Stakeholders"
      headerIcon={<GroupWork />}
    >
      <Link to="/admin/stakeholders">
        <ListMenuItem
          icon={<GroupWork style={{ height: 14, color: colors.success }} />}
          primary="Stakeholders"
        />
      </Link>
      <Link to="/admin/stakeholders/implementors">
        <ListMenuItem
          icon={<GroupWork style={{ height: 14, color: colors.success }} />}
          primary="Implementors"
        />
      </Link>
      <Link to="/admin/stakeholders/verifiers">
        <ListMenuItem
          icon={<GroupWork style={{ height: 14, color: colors.success }} />}
          primary="Verifiers"
        />
      </Link>
      <Link to="/admin/stakeholders/validators">
        <ListMenuItem
          icon={<GroupWork style={{ height: 14, color: colors.success }} />}
          primary="Validators"
        />
      </Link>
      <Link to="/admin/stakeholders/disseminators">
        <ListMenuItem
          icon={<GroupWork style={{ height: 14, color: colors.success }} />}
          primary="Disseminators"
        />
      </Link>
      <Link to="/admin/stakeholders/secretariat">
        <ListMenuItem
          icon={<GroupWork style={{ height: 14, color: colors.success }} />}
          primary="Secretariat"
        />
      </Link>
    </ListSubMenu>

    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      header="FGRM"
      headerIcon={<Mediation />}
    >
      <Link to="/admin/fgrm/grievances">
        <ListMenuItem
          icon={<Mediation style={{ height: 14, color: colors.success }} />}
          primary="Grievance Assignments"
        />
      </Link>
      <Link to="/admin/fgrm/grievancereports">
        <ListMenuItem
          icon={<Mediation style={{ height: 14, color: colors.facebook }} />}
          primary="Grievance Report"
        />
      </Link>
      <Link to="/admin/fgrm/feedback">
        <ListMenuItem
          icon={<ThumbUp style={{ height: 14, color: colors.facebook }} />}
          primary="Feedback"
        />
      </Link>
    </ListSubMenu>
    <ListSubMenu
      expandedStyles={{ backgroundColor: colors.gray2 }}
      headerIcon={<SettingsIcon />}
      header="Settings"
    >
      <Link to="/admin/settings/systemsettings">
        <ListMenuItem
          icon={<SettingsApplications style={{ height: 14 }} />}
          primary="System Settings"
        />
      </Link>
      <Link to="/admin/settings/users">
        <ListMenuItem
          icon={<AppIcon style={{ height: 14 }} />}
          primary="Users"
        />
      </Link>
    </ListSubMenu>
  </ListMenu>
);

export default DrawerMenu;
