import React from 'react';

import { Form, Field } from 'react-final-form';
import {
  Input,
  Text,
  Block,
  FieldBlock,
  Button,
  toastr,
} from '@cloudhub-ux/core';
import { Save as SaveIcon } from '@mui/icons-material';
import DraftEditor from '@cloudhub-ux/core/editors/DraftEditor';
import AdminContext from 'admin/context/AdminContext';

import { CircularProgress } from '@mui/material';

const validate = (values) => {
  const errors = {};
  if (!values.SenderName) {
    errors.SenderName = 'Required';
  }
  if (!values.ReplyEmail) {
    errors.ReplyEmail = 'Required';
  }
  return errors;
};

const EmailsConfigForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [emails, setEmails] = React.useState({});
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const saveEmails = async (values) => {
    try {
      const { data } = await adminaxiosinstance().post(
        '/system/settings/updateemails',
        {
          ...values,
        }
      );
      setEmails(data);
      toastr.success('Emails updated');
    } catch (error) {
      setEmails({});
    }
  };

  const loadEmails = async () => {
    setLoading(true);
    try {
      const { data } = await adminaxiosinstance().get(
        '/system/settings/getemails'
      );
      if (data && data.id) {
        setEmails(data);
      }
    } catch (error) {
      console.log('====================================');
      console.log(error);
      console.log('====================================');
      setEmails({});
    }
    setLoading(false);
  };

  React.useEffect(() => {
    loadEmails();
    // eslint-disable-next-line
  }, []);

  return (
    <Block>
      {loading && (
        <Block flex={false} center>
          <CircularProgress />
          <Text>Loading...</Text>
        </Block>
      )}
      <Form
        onSubmit={saveEmails}
        validate={validate}
        initialValues={{ ...emails }}
        render={({ handleSubmit, submitting, pristine }) => (
          <Block>
            <Block row right>
              <Button onClick={handleSubmit} disabled={submitting || pristine}>
                <SaveIcon /> Save
              </Button>
            </Block>
            <FieldBlock row>
              <FieldBlock flex>
                <label>Sender Name</label>
                <Field type="text" name="SenderName" component={Input} />
              </FieldBlock>
              <FieldBlock flex>
                <label>Reply Email</label>
                <Field type="text" name="ReplyEmail" component={Input} />
              </FieldBlock>
            </FieldBlock>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Registration Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="RegistrationEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="RegistrationEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Account Confirmation Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="AccountConfirmationEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="AccountConfirmationEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Support Auto Reply Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="SupportAutoReply.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="SupportAutoReply.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">Reset Password Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ResetPasswordEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ResetPasswordEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Change Password Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ChangePasswordEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ChangePasswordEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                New Action Verification Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ActionVerificationEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ActionVerificationEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>

            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                New Action Validation Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ActionValidationEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ActionValidationEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>

            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Validation Completed Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="ValidationCompletedEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="ValidationCompletedEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>

            <fieldset className="scheduler-border">
              <legend className="scheduler-border">New Grievance Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="NewGrievanceEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="NewGrievanceEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>

            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Grievance Received Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="GrievanceReceivedEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="GrievanceReceivedEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>

            <fieldset className="scheduler-border">
              <legend className="scheduler-border">New Feedback Email</legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="NewFeedbackEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="NewFeedbackEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                New Dissemination Email
              </legend>
              <Block flex={false}>
                <FieldBlock>
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="NewDisseminationEmail.Subject"
                    marginRight={0}
                    component={Input}
                  />
                </FieldBlock>

                <Field
                  name="NewDisseminationEmail.Body"
                  component={DraftEditor}
                  wrap={false}
                  height={400}
                />
              </Block>
            </fieldset>
          </Block>
        )}
      />
    </Block>
  );
};

export default EmailsConfigForm;
