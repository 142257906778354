import React from 'react';
import { Text, Block, IconButton } from '@cloudhub-ux/core';
import Dialog from '@cloudhub-ux/core/dialog';
import { colors, sizes } from 'theme';
import { Close } from '@mui/icons-material';
import videojs from 'video.js';
import VideoPlayer from './VideoPlayer';
import Hidden from '@mui/material/Hidden';

const VideoPlayerContainer = ({ watching, title, onClose }) => {
  const playerRef = React.useRef();
  const current = playerRef ? playerRef.current : null;

  React.useEffect(() => {
    const videoSrc = watching && watching.Location ? watching.Location : '';
    if (current) {
      const player = videojs(
        current,
        { autoplay: true, muted: false, controls: true },
        () => {
          player.src(videoSrc);
        }
      );
      return () => {
        player.dispose();
      };
    }
  }, [watching, current]);

  return (
    <React.Fragment>
      <Hidden mdDown>
        <Dialog
          open={watching !== null}
          onClose={onClose}
          // minHeight={height * 0.735}
        >
          <Block
            flex={false}
            row
            color={colors.primary}
            style={{ height: sizes.inputHeight }}
            space="between"
            middle
            padding={[0, sizes.padding]}
          >
            <Text header bold milkyWhite>
              {title || ''}
            </Text>
            <IconButton onClick={onClose}>
              <Close style={{ color: colors.milkyWhite }} />
            </IconButton>
          </Block>

          <Block flex={false} style={{ width: '100%' }}>
            <VideoPlayer video={watching} />
          </Block>
        </Dialog>
      </Hidden>
      {watching !== null && (
        <Hidden mdUp>
          <div
            style={{
              position: 'fixed',
              top: sizes.navBarHeight,
              // bottom: 0,
              left: 0,
              right: 0,
              zIndex: 99,
            }}
          >
            <Block
              style={{ height: sizes.navBarHeight }}
              flex={false}
              padding={sizes.padding}
              row
              middle
              space="between"
              color={colors.primary}
            >
              <Text header bold milkyWhite>
                {title || ''}
              </Text>
              <IconButton onClick={onClose}>
                <Close
                  style={{
                    fontWeight: 700,
                  }}
                />
              </IconButton>
            </Block>
            <Block flex={false}>
              <VideoPlayer video={watching} />
            </Block>
          </div>
        </Hidden>
      )}
    </React.Fragment>
  );
};

export default VideoPlayerContainer;
