import React from 'react';
import { Container, Block, Text } from '@cloudhub-ux/core';
import { sizes, colors, Images } from 'theme';
import GridContainer from '@cloudhub-ux/core/GridContainer';
import GridItem from '@cloudhub-ux/core/GridItem';
import AppSignIn from 'app/AppSignIn';

const HomePage = (props) => {
  return (
    <Container style={{ flex: 1, flexDirection: 'column' }}>
      <Block paper color={colors.gray4} flex={false} padding={sizes.padding}>
        <GridContainer style={{ display: 'flex', alignItems: 'center' }}>
          <GridItem sm={12} md={6} style={{}}>
            <Block center middle>
              <img
                alt="REDDSIS Kenya"
                style={{
                  width: 400,
                }}
                src={Images.logoicon}
              />
              <Text h3 center>
                Kenya REDD+ SIS
              </Text>
            </Block>
          </GridItem>
          <GridItem sm={12} md={6}>
            <Block>
              <AppSignIn />
            </Block>
          </GridItem>
        </GridContainer>
      </Block>
    </Container>
  );
};

export default HomePage;
