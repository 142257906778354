import React from 'react';
import CKEditor from '@cloudhub-ux/core/CKEditor';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import ImagesUpload from '@cloudhub-ux/core/uploaders/ImagesUpload';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';
import DialogLayer from 'localcomponents/DialogLayer';
import PageSectionForm from './PageSectionForm';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';

const InfopageForm = (props) => {
  const { postData, error, reset } = useAdminAxios({});

  const addInfopage = async (values) => {
    try {
      const data = await postData({
        url: `/website/infopage/saveinfopage`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="75%">
      <Form
        onSubmit={addInfopage}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).InfopageName || 'Add Information Page'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <Block row wrap margin={sizes.doubleBaseMargin}>
                  <Field
                    type="text"
                    label="Title"
                    required
                    name="Title"
                    component={Input}
                    flex
                  />
                  <Field
                    name="Section"
                    label="Section"
                    component={StaticListSelector}
                    flex
                    options={[
                      'Homepage',
                      'About',
                      'Safeguards',
                      'Stakeholders',
                      'Resources',
                      'FGRM',
                      'Consultation',
                    ]}
                  />
                  <Field
                    type="number"
                    name="Rank"
                    required
                    label="Rank"
                    component={Input}
                    parse={(val) =>
                      isNaN(parseInt(val, 10)) ? 1 : parseInt(val, 10)
                    }
                    min={1}
                  />
                </Block>
                <FieldBlock margin={sizes.doubleBaseMargin}>
                  <Field
                    type="text"
                    name="LongTitle"
                    label="Alternate Title"
                    component={Input}
                    flex
                  />
                </FieldBlock>
                <Field
                  type="text"
                  label="Page Content"
                  name="Body"
                  component={CKEditor}
                  height={400}
                />
                <Field
                  type="text"
                  label="Page Picture"
                  name="Picture"
                  url={`/api/media/upload/image`}
                  component={ImagesUpload}
                />
                <Field name="Sections" component={PageSectionForm} />
                {['Resources', 'Safeguards'].includes(values.Section) && (
                  <Field
                    type="text"
                    label="Upload Document"
                    name="File"
                    url={`/api/media/upload/file`}
                    // axiosinstance={axiosinstance}
                    component={FilesUpload}
                  />
                )}
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default InfopageForm;
