import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import useSettings from '@cloudhub-ux/min/hooks/useSettings';
import {
  Box,
  Grid,
  Container,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import MemberCard from './MemberCard';
import useAppAxios from 'app/context/useAppAxios';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import SearchNotFound from 'app/static/SearchNotFound';
import { useDebounce } from '@cloudhub-ux/core/customhooks';

export default function Secretariat() {
  const [members, setmembers] = React.useState([]);
  const [totalCount, settotalCount] = React.useState(0);
  const [page, setpage] = React.useState(0);
  const [rowsPerpage, setRowsPerPage] = React.useState(30);
  const [searchTerm, setsearchTerm] = React.useState('');

  const searchPhrase = useDebounce(searchTerm, 1500);

  const { themeStretch } = useSettings();
  const { getData } = useAppAxios({});

  const getMembers = React.useCallback(async () => {
    try {
      const data = await getData({
        url: '/app/stakeholders/secretariatmember/getall',
        params: { skip: page * 30, limit: 30, filter: searchPhrase },
      });
      if (data && Array.isArray(data.items)) {
        settotalCount(data.totalCount);
        setmembers(data.items);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [page]);

  React.useEffect(() => {
    getMembers();
  }, [getMembers]);

  return (
    <Container
      maxWidth={themeStretch ? false : 'lg'}
      sx={{ minHeight: '100vh', pt: 4 }}
    >
      <Stack
        mb={5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          placeholder="Search post..."
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{
                      ml: 1,
                      width: 20,
                      height: 20,
                      color: 'text.disabled',
                    }}
                  />
                </InputAdornment>
              </>
            ),
          }}
          onChange={(event) => {
            setsearchTerm(event.target.value);
          }}
          value={searchTerm}
        />
      </Stack>

      <Grid container spacing={3}>
        {members.length === 0 && searchTerm && <SearchNotFound />}
        {members.map((member, index) => (
          <MemberCard key={member.id} member={member} />
        ))}
        <Grid item xs={12} justify="center" sx={{ justifyContent: 'center' }}>
          <TablePagination
            count={totalCount}
            page={page}
            onChange={(event, value) => setpage(value)}
            rowsPerPage={rowsPerpage}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setpage(0);
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
