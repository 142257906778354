import React, { useState } from 'react';

import {
  Tab,
  Tabs,
  Block,
  PhoneInput,
  Input,
  FieldBlock,
  Button,
  toastr,
  Alert,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import Dialog, { DialogActions, DialogHeader } from '@cloudhub-ux/core/dialog';
import { sizes } from 'theme';
import DialogTabs from '@cloudhub-ux/core/dialog/DialogTabs';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';
import UserRoles from './UserRoles';
import DialogLayer from 'localcomponents/DialogLayer';
import useAdminAxios from 'admin/context/useAdminAxios';

const validate = (values) => {
  const errors = {};
  if (!values.Name) {
    errors.name = 'Required';
  }

  if (!values.Email) {
    errors.email = 'Required';
  }

  if (!values.Phone) {
    errors.phone = 'Required';
  }
  return errors;
};

function TabContainer(props) {
  return <Block style={{ padding: 20 }}>{props.children}</Block>;
}

const UserForm = (props) => {
  const [tabvalue, setTabValue] = useState(0);
  const { postData, error, reset } = useAdminAxios({});
  const { editingRow } = props;

  const title = (editingRow || {}).id
    ? editingRow.Name || 'Edit User'
    : 'Add User';

  const onSubmit = async (values, form) => {
    try {
      console.log(values);
      const data = await postData({
        url: '/system/user/saveuser',
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        setTimeout(() => {
          form.reset();
          props.onCancelEdit();
          toastr.success('Record saved successfully');
          reset();
        });
      }
    } catch (error) {
      console.log(error);
      // error
    }
  };

  return (
    <Dialog
      open={editingRow !== null}
      onClose={props.onCancelEdit}
      maxWidth="md"
      minHeight={500}
    >
      <DialogHeader onClose={props.onCancelEdit}>{title}</DialogHeader>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={editingRow}
        render={({ handleSubmit, form, pristine, submitting }) => (
          <Block>
            <DialogTabs>
              <Tabs
                value={tabvalue}
                onChange={(e, tabvalue) => setTabValue(tabvalue)}
              >
                <Tab label="User Details" />
                <Tab label="Permisions" />
              </Tabs>
            </DialogTabs>
            <DialogLayer>
              {tabvalue === 0 && (
                <TabContainer>
                  <Field
                    label="Full Name"
                    name="Name"
                    component={Input}
                    readOnly={editingRow && editingRow.id}
                  />

                  <FieldBlock row>
                    <Field
                      label="Email"
                      email
                      name="Email"
                      component={Input}
                      flex
                    />

                    <Field
                      label="Phone"
                      name="Phone"
                      component={PhoneInput}
                      readOnly={editingRow && editingRow.id}
                      flex
                    />
                  </FieldBlock>

                  <FieldBlock row>
                    <Field
                      label="User Type"
                      name="UserType"
                      options={[
                        'User',
                        'Admin',
                        'ActionImplementor',
                        'Validator',
                        'Verifier',
                        'GrievanceHandler',
                      ]}
                      component={StaticListSelector}
                      flex
                      isMulti
                    />
                  </FieldBlock>
                </TabContainer>
              )}
              {tabvalue === 1 && (
                <TabContainer>
                  <div>
                    <Field name="Permissions" component={UserRoles} />
                  </div>
                </TabContainer>
              )}
            </DialogLayer>

            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                variant="contained"
                onClick={props.onCancelEdit}
                color="danger"
                style={{ marginRight: sizes.margin }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="success"
                disabled={pristine || submitting}
              >
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </Block>
        )}
      />
    </Dialog>
  );
};

UserForm.defaultProps = {
  onSave: () => {},
};

export default UserForm;
