import React from 'react';
import {
  GridContainer,
  GridItem,
  Block,
  TextArea,
  Alert,
  Text,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { LayersManager } from '@cloudhub-ux/core/baseweb';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import ListMenu from '@cloudhub-ux/core/ListMenu';
import { ListSubMenu } from '@cloudhub-ux/core/widgets';
import AdminContext from 'admin/context/AdminContext';

const Verifications = ({ Action = {} }) => {
  const [verifications, setverifications] = React.useState([]);
  const { error, reset, getData } = useAdminAxios({});

  const {
    Tasks: { Safeguards },
  } = React.useContext(AdminContext);

  const getVerifications = React.useCallback(async () => {
    try {
      if ((Action || {}).id) {
        const data = await getData({
          url: `/actions/actionverification/getverifications`,
          params: { ActionId: Action.id },
        });
        if (data && Array.isArray(data.items)) {
          setverifications(data.items);
        }
      }
    } catch (error) {
      // error
    }
    // eslint-disable-next-line
  }, [JSON.stringify(Action)]);

  React.useEffect(() => {
    getVerifications();
  }, [getVerifications]);

  return (
    <ListMenu>
      {(verifications || {}).map((verification) => {
        const safeguards = Object.keys((verification || {}).Safeguards || {});
        return (
          <ListSubMenu key={verification.id} header={verification.VerifierName}>
            <Form
              onSubmit={() => {}}
              initialValues={{ ...verification }}
              validateOnChange={false}
              render={({ values }) => (
                <Block padding={sizes.padding} paper>
                  <LayersManager zIndex={1}>
                    <GridContainer spacing={2}>
                      <GridItem>
                        {Boolean(error) && (
                          <Alert
                            error
                            message={error}
                            onClose={() => reset('')}
                          />
                        )}
                      </GridItem>
                      {Safeguards.map((safeguard) => {
                        const {
                          SafeguardName,
                          SafeguardCode,
                          Description,
                          Indicators,
                          id,
                        } = safeguard;
                        const indicators = Object.keys(
                          ((verification || {}).Safeguards || {})[
                            SafeguardCode
                          ] || {}
                        );
                        if (
                          !id ||
                          !SafeguardName ||
                          !SafeguardCode ||
                          (Indicators || []).length === 0 ||
                          !(safeguards || []).includes(SafeguardCode)
                        ) {
                          return null;
                        }
                        return (
                          <React.Fragment key={safeguard.id}>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Text h4 bold primary>
                                {SafeguardName}
                              </Text>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: Description,
                                }}
                              />
                            </GridItem>
                            {Indicators.map((indicator) => {
                              const {
                                IndicatorName,
                                VerificationQuestion,
                                VerificationNote,
                              } = indicator;
                              if (
                                !IndicatorName ||
                                !VerificationQuestion ||
                                !(indicators || []).includes(IndicatorName)
                              ) {
                                return null;
                              }
                              return (
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                  <Field
                                    readOnly
                                    name={`Safeguards.${SafeguardCode}.${IndicatorName.replace(
                                      /[\s.,]/g,
                                      '_'
                                    )}`}
                                    label={
                                      <Text>
                                        <Text bold>{IndicatorName}:</Text>{' '}
                                        {VerificationQuestion}{' '}
                                        {VerificationNote ? (
                                          <Tooltip
                                            title={VerificationNote || ''}
                                          >
                                            <InfoOutlined
                                              style={{
                                                color: colors.info,
                                                fontSize: 14,
                                                marginLeft:
                                                  sizes.doubleBaseMargin,
                                                marginBottom: -2,
                                              }}
                                            />
                                          </Tooltip>
                                        ) : (
                                          ''
                                        )}
                                      </Text>
                                    }
                                    component={TextArea}
                                    required
                                  />
                                </GridItem>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Field
                          type="text"
                          label="Conclusion"
                          name="Conclusion"
                          component={TextArea}
                          readOnly
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Field
                          type="text"
                          label="Recommendation"
                          name="Recommendation"
                          component={TextArea}
                          readOnly
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {Array.isArray((values || {}).Files) &&
                          values.Files.map((file) => (
                            <a
                              href={file.Location}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {file.filename}
                            </a>
                          ))}
                      </GridItem>
                    </GridContainer>
                  </LayersManager>
                </Block>
              )}
            />
          </ListSubMenu>
        );
      })}
    </ListMenu>
  );
};

export default Verifications;
