import React from 'react';

import { Save as SaveIcon } from '@mui/icons-material';
import {
  Input,
  Block,
  PhoneInput,
  Button,
  FieldBlock,
  toastr,
} from '@cloudhub-ux/core';

import { Form, Field } from '@cloudhub-ux/core/form';

import ImagesUpload from '@cloudhub-ux/core/uploaders/ImagesUpload';
import AdminContext from 'admin/context/AdminContext';

const CompanyInfoForm = () => {
  const { adminaxiosinstance } = React.useContext(AdminContext);
  const [company, setCompanyInfo] = React.useState({});

  const saveCompanyInfo = async (values) => {
    try {
      const { data } = await adminaxiosinstance().post(
        '/system/settings/savecompanyinfo',
        {
          ...values,
        }
      );
      setCompanyInfo(data);
      toastr.success('CompanyInfo updated');
    } catch (error) {
      setCompanyInfo({});
    }
  };

  const loadCompanyInfo = async () => {
    try {
      const { data } = await adminaxiosinstance().get(
        '/system/settings/getcompanyinfo'
      );
      setCompanyInfo(data);
    } catch (error) {
      setCompanyInfo({});
    }
  };

  React.useEffect(() => {
    loadCompanyInfo();

    // eslint-disable-next-line
  }, []);

  return (
    <Form
      onSubmit={saveCompanyInfo}
      initialValues={{ ...company }}
      render={({ handleSubmit, pristine, submitting }) => (
        <Block>
          <Block right flex={false}>
            <Button onClick={handleSubmit} disabled={pristine || submitting}>
              <SaveIcon /> Save
            </Button>
          </Block>
          <FieldBlock>
            <label>Organization Name</label>
            <Field name="OrganizationName" component={Input} required />
          </FieldBlock>
          <Block row top>
            <Block flex={false} style={{ width: 300 }}>
              <label>Logo</label>
              <Field
                name="Logo"
                component={ImagesUpload}
                url="/api/media/upload/image"
                cardStyles={{
                  height: 250,
                  width: 250,
                  objectFit: 'contain',
                }}
              />
            </Block>

            <Block>
              <Field
                label="Address Line 1"
                name="Address.Line1"
                component={Input}
              />

              <Field
                label="Address Line 2"
                name="Address.Line2"
                component={Input}
                flex
              />

              <Field
                type="text"
                label="Location (City)"
                name="Address.Location"
                component={Input}
                flex
              />

              <FieldBlock row>
                <Field label="Email" name="Email" component={Input} flex />
                <Field label="Website" name="Website" component={Input} flex />
              </FieldBlock>

              <Field label="Phone" name="Phone" component={PhoneInput} />
            </Block>
          </Block>
        </Block>
      )}
    />
  );
};

export default CompanyInfoForm;
