import React, { Component } from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import { Block } from '@cloudhub-ux/core';

import NewAction from './NewAction';
import MyActions from './MyAction';

class ActionsRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Block row padding={[20, 0]}>
        <Router
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          <NewAction path="new" />
          <MyActions path="myactions" />
        </Router>
      </Block>
    );
  }
}

export default ActionsRoutes;
