import React from 'react';
import { TableCell } from '@mui/material';

import RemoteDataGrid from '@cloudhub-ux/core/datagrid/RemoteDataGrid';
import { Block, Alert, toastr } from '@cloudhub-ux/core';
import AdminContext from 'admin/context/AdminContext';
import UserForm from './forms/UserForm';

const User = (props) => {
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);
  const { adminaxiosinstance } = React.useContext(AdminContext);

  const deleteRows = (deletingRows) => {
    const ids = deletingRows.map((item) => item.id);
    adminaxiosinstance()
      .get('/system/user/delete', {
        params: { users: ids },
      })
      .then(() => {
        gridRef.current.onDeleteSuccess(deletingRows);
        toastr.success('Success', 'record(s) deleted');
        setError('');
      })
      .catch((error) => {
        const response = error.response || {};
        const data = response.data || {};
        setError('Error', data.message || error);
      });
  };

  const cellComponent = ({ row, column }) => (
    // Row, column, style
    <TableCell>
      {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
    </TableCell>
  );
  const cols = [
    { name: 'Name', title: 'Full Name' },
    { name: 'Email', title: 'Email' },
    { name: 'Phone', title: 'Phone' },
    { name: 'UserType', title: 'User Type' },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>

      <RemoteDataGrid
        title="Users"
        url="/system/user/getall"
        axiosinstance={adminaxiosinstance}
        columns={cols}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({ Permissions: [] })}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
      />

      <UserForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          setEditingRow(null);
        }}
        keepDirtyOnReinitialize
      />
    </Block>
  );
};

export default User;
