import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from '@cloudhub-ux/core/reach';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import arrowIosUpwardFill from '@iconify/icons-eva/arrow-ios-upward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Link,
  Grid,
  List,
  Stack,
  Popper,
  ListItem,
  ListSubheader,
  ClickAwayListener,
} from '@mui/material';
import AuthContext from 'auth/context/AuthContext';
import { colors } from 'theme';

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginRight: theme.spacing(5),
  [theme.breakpoints.down('lg')]: {
    marginRight: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
  },
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  },
}));

const useStyles = makeStyles({
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    // marginLeft: -sizes.baseMargin,
    marginTop: 15,
    zIndex: 101,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      border: '15px solid',
      borderColor: `transparent transparent ${colors.primary} transparent`,
      // borderColor: colors.primary,
      zIndex: 10,
    },
  },
});

IconBullet.propTypes = {
  type: PropTypes.oneOf(['subheader', 'item']),
};

function IconBullet({ type = 'item' }) {
  return (
    <Box
      sx={{
        width: 24,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        mr: 2,
      }}
    >
      <Box
        component="span"
        sx={{
          ml: '2px',
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'currentColor',
          ...(type !== 'item' && {
            ml: 0,
            width: 8,
            height: 2,
            borderRadius: 2,
          }),
        }}
      />
    </Box>
  );
}

MenuDesktopItem.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

function MenuDesktopItem({
  item,
  pathname,
  isHome,
  isOpen,
  isOffset,
  onOpen,
  onClose,
}) {
  const { title, path, children } = item;
  const [openChildMenu, onOpenChildMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [childAnchorEl, setChildAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);

  const isActive = pathname === path;
  const classes = useStyles();

  if (children) {
    return (
      <div key={title}>
        <LinkStyle
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            onOpen(title);
            setChildAnchorEl(null);
            onOpenChildMenu(null);
          }}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            fontSize: 17,
            flexDirection: {
              lg: 'row',
              md: 'column',
            },
            mr: {
              md: 2,
            },
            ...(isHome && { color: 'common.white' }),
            ...(isOffset && { color: 'text.primary' }),
            ...(isOpen && { opacity: 0.48 }),
          }}
          onMouseEnter={(event) => {
            setAnchorEl(event.currentTarget);
            onOpen(title);
            setChildAnchorEl(null);
            onOpenChildMenu(null);
          }}
        >
          {title}
          <Box
            component={Icon}
            icon={isOpen ? arrowIosUpwardFill : arrowIosDownwardFill}
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popper
          open={isOpen && Boolean(anchorEl)}
          onMouseLeave={() => {
            setAnchorEl(null);
            onClose();
          }}
          anchorEl={anchorEl}
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}
          onClose={onClose}
        >
          <span className={classes.arrow} ref={setArrowRef} />
          <ClickAwayListener
            onClickAway={() => {
              setAnchorEl(null);
              onClose();
            }}
          >
            <Grid
              container
              spacing={3}
              onMouseLeave={onClose}
              sx={{
                px: 3,
                pb: 3,
                right: 16,
                mt: 5,
                borderRadius: 2,
                boxShadow: (theme) => theme.customShadows.z24,
                bgcolor: 'background.paper',
                borderTop: `5px solid ${colors.primary}`,
              }}
            >
              {children.map((list) => {
                const { subheader, items } = list;

                return (
                  <Grid key={subheader} item xs={12} md={2}>
                    <List disablePadding>
                      <ListSubheader
                        disableSticky
                        disableGutters
                        sx={{
                          display: 'flex',
                          lineHeight: 'unset',
                          alignItems: 'center',
                          color: 'text.primary',
                          typography: 'overline',
                          whiteSpace: 'nowrap',
                          fontSize: 18,
                        }}
                      >
                        <IconBullet type="subheader" /> {subheader}
                      </ListSubheader>

                      {items.map((item) => {
                        const { childroutes } = item;
                        return (
                          <ListItem
                            key={item.title}
                            to={item.path}
                            component={RouterLink}
                            underline="none"
                            sx={{
                              p: 0,
                              mt: 3,
                              typography: 'h6',
                              color: 'text.secondary',
                              transition: (theme) =>
                                theme.transitions.create('color'),
                              '&:hover': { color: 'text.primary' },
                              ...(item.path === pathname && {
                                typography: 'h5',
                                color: 'text.primary',
                              }),
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {Array.isArray(childroutes) &&
                            childroutes.length > 0 ? (
                              <div key={item.title}>
                                <LinkStyle
                                  onClick={(event) => {
                                    setChildAnchorEl(event.currentTarget);
                                    onOpenChildMenu(item.title);
                                  }}
                                  sx={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    fontSize: 17,
                                    flexDirection: {
                                      lg: 'row',
                                      md: 'column',
                                    },
                                    mr: {
                                      md: 2,
                                    },
                                    ...(isHome && { color: 'common.white' }),
                                    ...(isOffset && { color: 'text.primary' }),
                                    ...(isOpen && { opacity: 0.48 }),
                                  }}
                                  onMouseEnter={(event) => {
                                    setChildAnchorEl(event.currentTarget);
                                    onOpenChildMenu(item.title);
                                  }}
                                >
                                  {item.title}
                                  <Box
                                    component={Icon}
                                    icon={
                                      isOpen
                                        ? arrowIosUpwardFill
                                        : arrowIosDownwardFill
                                    }
                                    sx={{ ml: 0.5, width: 16, height: 16 }}
                                  />
                                </LinkStyle>

                                <Popper
                                  open={
                                    openChildMenu === item.title &&
                                    Boolean(childAnchorEl)
                                  }
                                  onMouseLeave={() => {
                                    setChildAnchorEl(null);
                                    onOpenChildMenu(false);
                                  }}
                                  anchorEl={childAnchorEl}
                                  onClose={() => {
                                    setChildAnchorEl(null);
                                    onOpenChildMenu(false);
                                  }}
                                  placement="right-end"
                                >
                                  <ClickAwayListener
                                    onClickAway={() => {
                                      setChildAnchorEl(false);
                                      onOpenChildMenu(false);
                                    }}
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      onMouseLeave={() => {
                                        setChildAnchorEl(false);
                                        onOpenChildMenu(false);
                                      }}
                                      sx={{
                                        px: 3,
                                        pb: 3,
                                        right: 16,
                                        mt: 5,
                                        borderRadius: 2,
                                        boxShadow: (theme) =>
                                          theme.customShadows.z24,
                                        bgcolor: 'background.paper',
                                        borderTop: `5px solid ${colors.primary}`,
                                      }}
                                    >
                                      {childroutes.map((list) => {
                                        return (
                                          <Grid item xs={12} key={list.title}>
                                            <List disablePadding>
                                              <ListItem
                                                key={list.title}
                                                to={list.path}
                                                component={RouterLink}
                                                onClick={() => {
                                                  setChildAnchorEl(false);
                                                  onOpenChildMenu(false);
                                                  setAnchorEl(null);
                                                  onOpen(false);
                                                }}
                                                underline="none"
                                                sx={{
                                                  p: 0,
                                                  mt: 3,
                                                  typography: 'h6',
                                                  color: 'text.secondary',
                                                  transition: (theme) =>
                                                    theme.transitions.create(
                                                      'color'
                                                    ),
                                                  '&:hover': {
                                                    color: 'text.primary',
                                                  },
                                                  ...(item.path ===
                                                    pathname && {
                                                    typography: 'h5',
                                                    color: 'text.primary',
                                                  }),
                                                  whiteSpace: 'nowrap',
                                                }}
                                              >
                                                <>
                                                  <IconBullet />
                                                  {list.title}
                                                </>
                                              </ListItem>
                                            </List>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </ClickAwayListener>
                                </Popper>
                              </div>
                            ) : (
                              <>
                                <IconBullet />
                                {item.title}
                              </>
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                );
              })}
            </Grid>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  }

  if (title === 'Documentation') {
    return (
      <LinkStyle
        href={path}
        target="_blank"
        sx={{
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
          ...(isActive && { color: 'primary.main' }),
          fontSize: 17,
        }}
      >
        {title}
      </LinkStyle>
    );
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      sx={(theme) => ({
        ...(isHome && { color: 'common.white' }),
        ...(isOffset && { color: 'text.primary' }),
        ...(isActive && { color: 'primary.main' }),
        fontSize: 17,
        '@media (max-width: 1050px)': {
          display: title === 'Home' ? 'none' : 'flex',
        },
      })}
    >
      {title}
    </LinkStyle>
  );
}

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  isHome: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const { Implementor } = React.useContext(AuthContext);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = (title) => {
    setOpen(title);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction="row">
      {navConfig.map((link) => {
        if (link.title === 'Actions' && !(Implementor || {}).id) {
          return null;
        }
        return (
          <MenuDesktopItem
            key={link.title}
            item={link}
            pathname={pathname}
            isOpen={open === (link || {}).title}
            onOpen={handleOpen}
            onClose={handleClose}
            isOffset={isOffset}
            isHome={isHome}
          />
        );
      })}
    </Stack>
  );
}
