import React from 'react';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
  PhoneInput,
  DatePicker,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';
import DialogLayer from 'localcomponents/DialogLayer';
import FeedbackLocationForm from 'localcomponents/FeedbackLocationForm';
import GrievanceReassignForm from './GrievanceReassignForm';

const GrievanceresponseForm = (props) => {
  const [deassign, setdeassign] = React.useState(false);
  const { postData, error, reset } = useAdminAxios({});

  const deassignTask = async (values) => {
    try {
      const data = await postData({
        url: `/fgrm/infopage/saveinfopage`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="75%">
      <Form
        onSubmit={() => {}}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).GrievanceCode ||
                  'Edit Grievance Assignment'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <FieldBlock row wrap>
                  <Field
                    name="HandlerName"
                    label="Handler"
                    component={Input}
                    required
                    readOnly
                  />
                  <Field
                    name="HandlerPhone"
                    label="Phone"
                    component={PhoneInput}
                    required
                    readOnly
                  />
                </FieldBlock>
                <FeedbackLocationForm values={values} required={{}} />
                <Field
                  type="text"
                  label="Subject"
                  required
                  name="Subject"
                  component={Input}
                  readOnly
                />
                <FieldBlock row wrap>
                  <Field
                    type="text"
                    name="GrievanceCode"
                    required
                    label="Grievance Code"
                    component={Input}
                    min={1}
                  />
                </FieldBlock>
                <FieldBlock row wrap>
                  <Field
                    name="Timestamp"
                    label="Date Received"
                    component={DatePicker}
                    required
                    readOnly
                    flex
                  />
                  <Field
                    name="StatusDates.AcknowledgedDate"
                    label="Date Acknowledged"
                    component={DatePicker}
                    required
                    readOnly
                    flex
                  />
                  {(values || {}).Status === 'RESOLVED' && (
                    <Field
                      name="StatusDates.ResolvedDate"
                      label="Date Resolved"
                      component={DatePicker}
                      required
                      readOnly
                      flex
                    />
                  )}
                  {(values || {}).Status === 'REFERRED' && (
                    <Field
                      name="StatusDates.ReferredDate"
                      label="Date Referred"
                      component={DatePicker}
                      required
                      readOnly
                      flex
                    />
                  )}
                  {(values || {}).Status === 'ESCALATED' && (
                    <Field
                      name="StatusDates.EscalatedDate"
                      label="Date Escalated"
                      component={DatePicker}
                      required
                      readOnly
                      flex
                    />
                  )}
                  {(values || {}).Status === 'DISMISSED' && (
                    <Field
                      name="StatusDates.DismissedDate"
                      label="Date Dismissed"
                      component={DatePicker}
                      required
                      readOnly
                      flex
                    />
                  )}
                </FieldBlock>
                {(values || {}).Status === 'ESCALATED' && (
                  <Field
                    name="EscalatedTo"
                    label="Escalated To"
                    component={StaticListSelector}
                    required
                    readOnly
                    labelExtractor={(row) =>
                      row && row.Officer
                        ? `${row.Officer} - ${row.Institution}`
                        : ''
                    }
                    valueExtractor={(row) => row}
                    keyExtractor={(row) => (row || {}).Officer}
                  />
                )}
                {(values || {}).Status === 'REFERRED' && (
                  <Field
                    name="ReferredTo"
                    label="Referred To"
                    component={StaticListSelector}
                    required
                    readOnly
                    labelExtractor={(row) =>
                      row && row.Officer
                        ? `${row.Officer} - ${row.Institution}`
                        : ''
                    }
                    valueExtractor={(row) => row}
                    keyExtractor={(row) => (row || {}).Officer}
                  />
                )}
                {['OPEN', 'ACKNOWLEDGED'].includes((values || {}).Status) && (
                  <GrievanceReassignForm
                    AssignmentId={(editingRow || {}).id}
                    onReassign={props.onSave}
                  />
                )}
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.error}
                style={{ marginRight: sizes.baseMargin }}
              >
                Cancel
              </Button>
              <React.Fragment>
                {['OPEN', 'ACKNOWLEDGED'].includes((editingRow || {}).Status) &&
                  !deassign && (
                    <React.Fragment>
                      <Button
                        contained
                        onClick={() => setdeassign(true)}
                        color={colors.primary}
                      >
                        Deassgin
                      </Button>
                    </React.Fragment>
                  )}
                {['OPEN', 'ACKNOWLEDGED'].includes((editingRow || {}).Status) &&
                  deassign && (
                    <React.Fragment>
                      <Text h6 bold>
                        Sure you want to deassign task?
                      </Text>
                      <Button
                        contained
                        onClick={() => setdeassign(false)}
                        color={colors.error}
                      >
                        Cancel
                      </Button>
                      <Button
                        contained
                        onClick={deassignTask}
                        color={colors.primary}
                      >
                        Deassgin
                      </Button>
                    </React.Fragment>
                  )}
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default GrievanceresponseForm;
