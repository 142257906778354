import React from 'react';
import grievanceReducer, { INITIAL_STATE } from './grievanceReducer';
import grievanceActions from './grievanceActions';

const useGrievance = ({ axiosinstance }) => {
  const [state, dispatch] = React.useReducer(grievanceReducer, INITIAL_STATE);

  const actions = grievanceActions({ axiosinstance, dispatch });
  const { getPartners } = actions;
  React.useEffect(() => {
    getPartners();
    // eslint-disable-next-line
  }, []);

  return {
    ...state,
    grievanceActions: actions,
  };
};

export default useGrievance;
