import React from 'react';
import { Block } from '@cloudhub-ux/core';
import { colors } from 'theme';
import videojs from 'video.js';

const VideoPlayer = ({ video }) => {
  const playerRef = React.useRef();

  React.useEffect(() => {
    const videoSrc = video && video.Location ? video.Location : '';
    if (playerRef.current) {
      const player = videojs(
        playerRef.current,
        { autoplay: true, muted: false, controls: true },
        () => {
          player.src(videoSrc);
        }
      );
      return () => {
        player.dispose();
      };
    }
  }, [video, playerRef]);

  return (
    <Block middle color={colors.milkyWhite} data-vjs-player>
      <video ref={playerRef} className="video-js vjs-16-9" playsInline />
    </Block>
  );
};

export default VideoPlayer;
