import { toastr } from '@cloudhub-ux/core';
import React from 'react';
import AdminContext from './AdminContext';
import AuthContext from 'auth/context/AuthContext';
const useAdminAxios = (props) => {
  const { url, params, initialState } = props || {};
  const { adminAxiosInstance } = React.useContext(AdminContext);
  const { axiosinstance } = React.useContext(AuthContext);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(initialState);
  const postData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await adminAxiosInstance().post(`${url}`, {
          ...params,
        });

        setLoading(false);
        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};
        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    [adminAxiosInstance]
  );

  const getData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await adminAxiosInstance().get(`${url}`, {
          params,
        });
        setLoading(false);
        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};
        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    [adminAxiosInstance]
  );

  const hydrateState = async () => {
    const data = await getData({ url, params });
    setData(data);
  };

  const reset = () => {
    setError('');
    setLoading(false);
    setData(initialState);
  };

  React.useEffect(() => {
    if (error) {
      toastr.error(error);
    }
  }, [error]);

  React.useEffect(() => {
    if (url) {
      hydrateState();
    }
    // eslint-disable-next-line
  }, [url]);

  return {
    data,
    error,
    loading,
    getData,
    postData,
    reset,
    adminAxiosInstance,
    axiosinstance,
  };
};

export default useAdminAxios;
