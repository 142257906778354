import React from 'react';
import {
  GridContainer,
  GridItem,
  Button,
  Block,
  TextArea,
  Input,
  Alert,
  PhoneInput,
  Tab,
  Tabs,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';
import { makeStyles } from '@mui/styles';
import { LayersManager } from '@cloudhub-ux/core/baseweb';
import FeedbackLocationForm from 'localcomponents/FeedbackLocationForm';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import InformationRequests from 'actionverification/grievance/forms/InformationRequests';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const GrievanceDetail = ({ GrievanceId }) => {
  const [tabvalue, settabvalue] = React.useState(0);
  const [grievance, setgrievance] = React.useState({});
  const classes = useStyles();
  const { getData, error, reset } = useVerificationAxios({});

  const getGrievance = React.useCallback(async () => {
    try {
      if (GrievanceId) {
        const data = await getData({
          url: `/grievance/getgrievance`,
          params: { GrievanceId },
        });
        if (data && data.id) {
          setgrievance(data);
        }
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [GrievanceId]);

  React.useEffect(() => {
    getGrievance();
  }, [getGrievance]);

  return (
    <div className={classes.root}>
      <Block>
        <Tabs
          value={tabvalue}
          onChange={(event, val) => settabvalue(val)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Action Details" />
          <Tab label="Followup" />
        </Tabs>
      </Block>
      {tabvalue === 0 && (
        <GridContainer spacing={4}>
          <GridItem xs={12} md={12}>
            <Form
              onSubmit={() => {}}
              initialValues={grievance}
              validateOnChange={false}
              render={({
                handleSubmit,
                pristine,
                submitting,
                form,
                values,
              }) => (
                <Block>
                  <LayersManager zIndex={1}>
                    <GridContainer spacing={2}>
                      <GridContainer spacing={2}>
                        <GridItem sm={12}>
                          <Field
                            readOnly
                            name="Name"
                            type="text"
                            label="Name"
                            component={Input}
                            required
                            maxLength={200}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <Field
                            readOnly
                            name="Email"
                            type="text"
                            label="Email"
                            component={Input}
                            required
                            maxLength={200}
                          />
                        </GridItem>
                        <GridItem sm={12} md={6}>
                          <Field
                            readOnly
                            name="Phone"
                            type="text"
                            label="Phone"
                            component={PhoneInput}
                            required
                            maxLength={200}
                          />
                        </GridItem>
                        <GridItem sm={12}>
                          <FeedbackLocationForm
                            values={values}
                            required={{
                              County: true,
                              Constituency: true,
                              Ward: true,
                            }}
                          />
                        </GridItem>

                        <GridItem sm={12}>
                          <Field
                            readOnly
                            name="PhysicalAddress"
                            type="text"
                            label="Physical Address"
                            component={Input}
                            required
                            maxLength={200}
                          />
                        </GridItem>
                      </GridContainer>
                      <GridItem sm={12}>
                        <Field
                          readOnly
                          name="Subject"
                          type="text"
                          label="Subject of Grievance"
                          component={Input}
                          required
                          maxLength={200}
                        />
                      </GridItem>
                      <GridItem>
                        <Field
                          readOnly
                          name="Body"
                          type="text"
                          label="Description of Grievance"
                          component={TextArea}
                          rows={10}
                          required
                          maxLength={5000}
                        />
                      </GridItem>
                      <GridItem>
                        {Boolean(error) && (
                          <Alert
                            error
                            message={error}
                            onClose={() => reset('')}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </LayersManager>

                  <Block
                    row
                    style={{
                      justifyContent: 'stretch',
                    }}
                  >
                    <Button
                      contained
                      color={colors.primary}
                      disabled={submitting || pristine}
                      onClick={handleSubmit}
                      fullWidth
                    >
                      {`${submitting ? 'Submitting...' : 'Submit Grievance'}`}
                    </Button>
                  </Block>
                </Block>
              )}
            />
          </GridItem>
        </GridContainer>
      )}
      {tabvalue === 1 && (
        <InformationRequests
          GrievanceId={GrievanceId}
          Requests={grievance.InformationRequests}
          onUpdate={(val) => setgrievance(val)}
        />
      )}
    </div>
  );
};

export default GrievanceDetail;
