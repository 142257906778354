/* eslint-disable capitalized-comments */
import React from 'react';
import { TableCell } from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import {
  Block,
  Alert,
  toastr,
  Text,
  Input,
  Button,
  IconButton,
} from '@cloudhub-ux/core';
import { CSVLink } from 'react-csv';
import ReddactionForm from './forms/ReddactionForm';
import AdminContext from 'admin/context/AdminContext';
import useAdminAxios from 'admin/context/useAdminAxios';
import moment from 'moment';
import {
  Refresh as RefreshIcon,
  ListAlt as ListAltIcon,
  Edit,
} from '@mui/icons-material';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from '@mui/material';
const Reddaction = () => {
  const { adminAxiosInstance } = React.useContext(AdminContext);
  const { postData } = useAdminAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);
  const [exporting, setexporting] = React.useState([]);
  const [Status, setStatus] = React.useState('');

  const deleteRows = async (deletingRows) => {
    try {
      const ids = deletingRows.map((item) => item.id);
      const data = await postData({
        url: `/actions/reddaction/delete`,
        params: { ids },
      });
      if (data) {
        toastr.success('Actions successfulyy Deleted');
        gridRef.current.reload();
      }
    } catch (error) {}
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const onChangeSelection = (selection) => {
    const actionswithdates = [...(selection || [])].map((action) => ({
      ...action,
      Created: moment(action.Timestamp).format('DD MMM, YYYY hh:mm a'),
      Submitted: (action.StatusDates || {}).SubmittedDate
        ? moment((action.StatusDates || {}).SubmittedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      Verified: (action.StatusDates || {}).VerifiedDate
        ? moment((action.StatusDates || {}).VerifiedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      Validated: (action.StatusDates || {}).ValidatedDate
        ? moment((action.StatusDates || {}).ValidatedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      ReportPublished: (action.StatusDates || {}).ReportedDate
        ? moment((action.StatusDates || {}).ReportedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      ActionName: action.ActionName || '',
      Programme: (action.Programme || {}).ProgrammeName || '',
      Project: (action.Project || {}).ProjectName || '',
      County: (action.County || {}).Name || '',
      Constituency: (action.Constituency || {}).Name || '',
      Ward: (action.Ward || {}).Name || '',
      Village: action.Village || '',
      StartDate: moment(action.StartDate).format('DD MMM, YYYY'),
      EndDate: moment(action.StartDate).format('DD MMM, YYYY'),
      SubmittedBy: (action.User || {}).Name || '',
      Phone: (action.User || {}).Phone || '',
      Institution: (action.User || {}).Institution || '',
    }));
    setexporting(actionswithdates);
    // gridRef.current.reload();
  };

  React.useEffect(() => {
    try {
      gridRef.current.reload();
    } catch (error) {}
  }, [Status]);

  const renderHeader = ({ onSearch, onRefresh, title }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={Status}
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              row
            >
              <FormControlLabel
                value="NOT_SUBMITTED"
                control={<Radio />}
                label="Not Submitted"
              />
              <FormControlLabel
                value="SUBMITTED"
                control={<Radio />}
                label="Submitted"
              />
              <FormControlLabel
                value="VERIFIED"
                control={<Radio />}
                label="Verified"
              />
              <FormControlLabel
                value="VALIDATED"
                control={<Radio />}
                label="Validated"
              />
              <FormControlLabel
                value="REPORTED"
                control={<Radio />}
                label="Report Published"
              />
            </RadioGroup>
          </FormControl>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <CSVLink
            data={exporting}
            headers={[
              { label: 'Created', key: 'Created' },
              { label: 'Submitted', key: 'Submitted' },
              { label: 'Verified', key: 'Verified' },
              { label: 'Validated', key: 'Validated' },
              { label: 'Report Published', key: 'ReportPublished' },
              { label: 'Action Name', key: 'ActionName' },
              { label: 'Action Code', key: 'ActionCode' },
              { label: 'Programme', key: 'Programme' },
              { label: 'Project', key: 'Project' },
              { label: 'Description', key: 'Description' },
              { label: 'Scope', key: 'Scope' },
              { label: 'County', key: 'County' },
              { label: 'Constituency', key: 'Constituency' },
              { label: 'Ward', key: 'Ward' },
              { label: 'Village', key: 'Village' },
              { label: 'StartDate', key: 'StartDate' },
              { label: 'EndDate', key: 'EndDate' },
              { label: 'ActionStatus', key: 'ActionStatus' },
              { label: 'Status', key: 'Status' },
              { label: 'SubmittedBy', key: 'SubmittedBy' },
              { label: 'Phone', key: 'Phone' },
              { label: 'Institution', key: 'Institution' },
            ]}
            filename="Actions"
          >
            <Button>
              <ListAltIcon /> Export Selected to Excel
            </Button>
          </CSVLink>
        </Block>
      </Block>
      <Block />
    </Block>
  );

  const cols = [
    { name: 'ActionName', title: 'Action Code' },
    { name: 'ActionCode', title: 'Action Name' },
    { name: 'Status' },
  ];

  const columnWidths = [
    { columnName: 'ActionCode', width: 120 },
    { columnName: 'ActionName', width: 200 },
    { columnName: 'Status', width: 120 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>

      <RemoteDataGrid
        title="REDD+ Actions"
        url="/actions/reddaction/getall"
        axiosinstance={adminAxiosInstance}
        columns={cols}
        columnWidths={columnWidths}
        actionsComponent={(props) => {
          return (
            <IconButton onClick={() => setEditingRow((props || {}).row)}>
              <Edit color="primary" />
            </IconButton>
          );
        }}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        header={renderHeader}
        onChangeSelection={onChangeSelection}
        ref={gridRef}
        params={{ Status }}
      />
      <ReddactionForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Reddaction;
