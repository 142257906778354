import React from 'react';
import { Container, Block, Text, Input, Button } from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { sizes, colors } from 'theme';
import AuthContext from 'auth/context/AuthContext';
import { Link } from '@cloudhub-ux/core/reach';

const AppSignIn = (props) => {
  const { actions } = React.useContext(AuthContext);

  const onSubmit = async (params) => {
    await actions.signinUser(params);
  };

  return (
    <Block center padding={[sizes.padding, 0]}>
      <Container maxWidth="sm">
        <Block paper shadow padding={sizes.padding * 2}>
          <Text h2>{`Sign in`}</Text>

          {
            //   <Block padding={[sizes.padding, 0]}>
            //   <Text h5>{`${merchant.MerchantCode}.chshop.com`}</Text>
            // </Block>
          }

          <Block padding={[sizes.padding, 0]}>
            <Text dark>
              Enter your <Text bold>email address</Text> and{' '}
              <Text bold>password</Text>.
            </Text>
          </Block>
          <Block flex={false} row middle>
            <Form
              onSubmit={onSubmit}
              render={({ submitting, handleSubmit }) => (
                <Block>
                  <Field
                    placeholder="you@example.com"
                    name="Email"
                    component={Input}
                    email
                    required
                  />
                  <Field
                    type="password"
                    placeholder="password"
                    name="Password"
                    component={Input}
                    required
                    style={{ marginTop: 10 }}
                  />

                  <Block padding={[sizes.padding, 0]}>
                    <Button
                      contained
                      color={colors.primary}
                      disabled={submitting}
                      onClick={handleSubmit}
                    >
                      <Text button milkyWhite center>
                        Sign in
                      </Text>
                    </Button>
                  </Block>
                </Block>
              )}
            />
          </Block>

          <Block flex={false} row space="between">
            <Link to="/auth/resetpassword">
              <Text accent>Forgot password?</Text>
            </Link>
            <Text accent>{` ${'|'} `}</Text>
            <Link to="/auth/signup">
              {' '}
              <Text accent>
                Don't have an account? <strong>Register</strong>
              </Text>
            </Link>
          </Block>
        </Block>
      </Container>

      {
        //   <Block flex={false} margin={[sizes.margin, 0]}>
        //   <Button onClick={resetMerchantDomain}>
        //     <Text primary>Sign in to another merchant domain</Text>
        //   </Button>
        // </Block>
      }
    </Block>
  );
};

export default AppSignIn;
