import React from 'react';
import { styled } from '@mui/material/styles';
import AboutHero from '../about/AboutHero';
import useAppAxios from 'app/context/useAppAxios';
import { Block, Text, ListMenuItem, ListMenu } from '@cloudhub-ux/core';
import Pagination from '@mui/material/Pagination';
import IconButton from '@cloudhub-ux/core/IconButton';
import { Download } from '@mui/icons-material';
import { sizes } from 'theme';

const RootStyle = styled('div')({
  height: '100%',
});

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export default function Reports({ Title }) {
  const [page, setPage] = React.useState(0);
  const [docs, setDocs] = React.useState([]);
  const [docCount, setdcoCount] = React.useState(0);
  const [aboutPage] = React.useState({
    Title: 'Explore REDD+ Resources',
    LongTitle: "Read Reports and Learn More about Kenya's REDD+ Efforts",
    Body: 'Project Reports',
  });

  const { getData } = useAppAxios({});

  const getDocuments = React.useCallback(async () => {
    try {
      const data = await getData({
        url: '/app/safeguards/documents/getall',
        params: { skip: page * 20, limit: 20 },
      });
      if (data && data.items) {
        setDocs(data.items);
        setdcoCount(data.totalCount);
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [page]);

  React.useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  return (
    <RootStyle title="REDD+ Kenya SIS" id="about_sis">
      <AboutHero page={aboutPage} />
      <ContentStyle>
        <ListMenu>
          {docs.map((doc, index) =>
            doc && doc.File ? (
              <ListMenuItem
                key={doc.id}
                primary={
                  <Block>
                    <Text h4 bold>
                      {doc.Title}
                    </Text>
                    <Text
                      body
                      dangerouslySetInnerHTML={{ __html: doc.Description }}
                    />
                    <a
                      href={doc.File.Location}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IconButton>
                        <Download /> Download
                      </IconButton>
                    </a>
                  </Block>
                }
              />
            ) : null
          )}
        </ListMenu>
        <Block center flex={false} margin={[sizes.doubleBaseMargin, 0]}>
          <Pagination
            classes={{}}
            count={docCount}
            rowsPerPage={20}
            onChangePage={(current) => setPage(current)}
          />
        </Block>
      </ContentStyle>
    </RootStyle>
  );
}
