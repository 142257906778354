import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  LinearProgress,
  Box,
} from '@mui/material';
import { fShortenNumber } from '@cloudhub-ux/min/utils/formatNumber';
import AdminContext from 'admin/context/AdminContext';

export default function ValidationsByStatus() {
  const {
    Tasks: { Tasks },
  } = React.useContext(AdminContext);
  const { Validations } = Tasks;
  const {
    PendingValidations,
    SubmittedValidations,
    AllValidations,
    OverdueValidations,
  } = Validations || {};

  return (
    <Card>
      <CardHeader title="Action Validations" />
      <Stack spacing={3} sx={{ px: 3, my: 5 }}>
        <LinearProgress
          variant="determinate"
          key="pending_validations"
          value={((PendingValidations || 0) / (AllValidations || 1)) * 100}
          color="warning"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          key="submitted_validations"
          value={((SubmittedValidations || 0) / (AllValidations || 1)) * 100}
          color="success"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          key="overdue_validations"
          value={((OverdueValidations || 0) / (AllValidations || 1)) * 100}
          color="error"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, pb: 3 }}
      >
        <Stack key="pending_validations" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'warning.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Pending
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(PendingValidations)}
          </Typography>
        </Stack>
        <Stack key="submitted_validations" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'success.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Submitted
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(SubmittedValidations)}
          </Typography>
        </Stack>
        <Stack key="overdue_validations" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'error.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Overdue
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(OverdueValidations)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
