import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Grievance from './Grievance';
import Feedback from './Feedback';
import Grievancereport from './Grievancereport';

const FgrmRoutes = () => {
  return (
    <Router style={{ flex: 1 }}>
      <Grievance path="grievances" />
      <Feedback path="feedback" />
      <Grievancereport path="grievancereports" />
    </Router>
  );
};

export default FgrmRoutes;
