import React from 'react';
import { FieldBlock, Input } from '@cloudhub-ux/core';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';
import { Field } from '@cloudhub-ux/core/form';
import { counties, constituencies } from 'kenya';

const Counties = [];
const Constituencies = [];
const Wards = [];
try {
  for (const county of counties) {
    Counties.push({
      id: county.code,
      CountyCode: county.code,
      Name: county.name,
      Center: county.center,
    });
    for (const constituency of county.constituencies) {
      Constituencies.push({
        id: constituency.code,
        ConstituencyCode: constituency.code,
        Name: constituency.name,
        Center: constituency.center,
        CountyCode: county.code,
      });
      const constituencyind = constituencies.findIndex(
        (c) => c.code === constituency.code
      );
      for (const ward of constituencies[constituencyind].wards) {
        Wards.push({
          id: ward.code,
          WardCode: ward.code,
          Name: ward.name,
          Center: ward.center,
          ConstituencyCode: constituency.code,
          CountyCode: county.code,
        });
      }
    }
  }
} catch (error) {}

export const Component = ({ values }) => {
  const { County, Constituency } = values || {};
  const Scope = (values || {}).Scope || (values || {}).OperatingScope || '';
  if (!Scope || Scope === 'COUNTRY_WIDE') {
    return null;
  }
  return (
    <FieldBlock row wrap>
      <Field
        name="County"
        label="County"
        component={StaticListSelector}
        options={Counties}
        labelExtractor={(row) => (row || {}).Name || ''}
        valueExtractor={(row) => row}
        keyExtractor={(row) => (row || {}).id || `${row}`}
        flex
        required
      />
      {['CONSTITUENCY', 'WARD', 'VILLAGE'].includes(Scope) && (
        <Field
          name="Constituency"
          label="Constituency"
          component={StaticListSelector}
          options={Constituencies.filter(
            (c) => c && c.CountyCode === (County || {}).CountyCode
          )}
          labelExtractor={(row) => (row || {}).Name || ''}
          valueExtractor={(row) => row}
          keyExtractor={(row) => (row || {}).id || `${row}`}
          readOnly={!(County || {}).CountyCode}
          flex
          required
        />
      )}
      {['WARD', 'VILLAGE'].includes(Scope) && (
        <Field
          name="Ward"
          label="Ward"
          component={StaticListSelector}
          options={Wards.filter(
            (w) =>
              w && w.ConstituencyCode === (Constituency || {}).ConstituencyCode
          )}
          labelExtractor={(row) => (row || {}).Name || ''}
          valueExtractor={(row) => row}
          keyExtractor={(row) => (row || {}).id || `${row}`}
          readOnly={!(Constituency || {}).ConstituencyCode}
          flex
          required
        />
      )}
      {['VILLAGE'].includes(Scope) && (
        <Field name="Village" label="Village" component={Input} flex required />
      )}
    </FieldBlock>
  );
};

export default Component;
