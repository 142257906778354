import React from 'react';
import { Text, Block, IconButton } from '@cloudhub-ux/core';
import Dialog from '@cloudhub-ux/core/dialog';
import { colors, sizes } from 'theme';
import { useMetrics } from '@cloudhub-ux/core/customhooks';
import ImageGallery from '@cloudhub-ux/core/slideshow/ImageGallery';
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  notlarge: {
    '@media (min-width: 960px)': {
      display: 'none!important',
    },
  },
  dialog: {
    '@media (max-width: 960px)': {
      display: 'none!important',
    },
  },
  header: {
    backgroundColor: colors.primary,
    color: colors.milkyWhite,
    '@media (max-width: 600px)': {
      backgroundColor: colors.milkyWhite,
      color: colors.gray,
    },
  },
});
const SlideShow = ({ viewingpictures, onCloseWindow, title }) => {
  const { maxWidth } = useMetrics();
  const classes = useStyles();
  const images = Array.isArray(viewingpictures)
    ? viewingpictures.map((picture) => ({
        ...picture,
        original: picture.Location,
        thumbnail: picture.Location,
      }))
    : [];
  return (
    <React.Fragment>
      <Dialog
        open={viewingpictures !== null}
        className={classes.dialog}
        maxWidth="md"
      >
        <Block
          flex={false}
          row
          color={colors.primary}
          style={{ height: sizes.inputHeight }}
          space="between"
          middle
          padding={[0, sizes.padding]}
        >
          <Text header bold milkyWhite>
            {title} - Photos
          </Text>
          <IconButton onClick={onCloseWindow}>
            <Close style={{ color: colors.milkyWhite }} />
          </IconButton>
        </Block>
        <Block flex={false}>
          <ImageGallery
            items={images}
            showCaption
            // captionElement={null}
            maxWidth={maxWidth}
            showNav={images && images.length > 1}
          />
        </Block>
      </Dialog>
      {viewingpictures !== null && (
        <Block
          classNam={classes.notlarge}
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
          }}
        >
          <Block
            className={classes.header}
            style={{ height: sizes.navBarHeight }}
            flex={false}
            padding={sizes.padding}
            row
            middle
            space="between"
          >
            <Text header bold>
              {title}
            </Text>
            <IconButton onClick={onCloseWindow}>
              <Close
                style={{
                  fontWeight: 700,
                }}
              />
            </IconButton>
          </Block>
          <Block middle color={colors.milkyWhite}>
            <ImageGallery
              items={images}
              showBullets={maxWidth === 'sm'}
              showCaption
              // captionElement={null}
              maxWidth={maxWidth}
              showThumbnails={maxWidth === 'md'}
              showNav={images && images.length > 1}
            />
          </Block>
        </Block>
      )}
    </React.Fragment>
  );
};

export default SlideShow;
