import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Fab,
} from '@mui/material';
import SvgIconStyle from 'app/static/SvgIconStyle';
import { Images } from 'theme';
import LocationOn from '@mui/icons-material/LocationOn';
import { PlayArrowOutlined } from '@mui/icons-material';

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const TitleStyle = styled('div')(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 44,
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ImageCard.propTypes = {
  sttakeholder: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function ImageCard({
  gallery,
  index,
  onView = () => {},
  onWatch = () => {},
}) {
  const { Title, Description, Location, Pictures, Video } = gallery || {};
  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle
          sx={{
            '&:after': {
              top: 0,
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.02),
              cursor: 'pointer',
            },
            pt: {
              xs: 'calc(100% * 4 / 3)',
              sm: 'calc(100% * 3 / 4.66)',
            },
          }}
          onClick={() => onView(gallery)}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              display: 'none',
            }}
          />
          <AvatarStyle
            alt="logo"
            src={((Pictures || [])[0] || {}).Location || Images.landing}
            sx={{
              zIndex: 9,
              top: 24,
              left: 24,
              width: 40,
              height: 40,
            }}
          />

          <CoverImgStyle
            alt={Title}
            src={((Pictures || [])[0] || {}).Location || Images.landingbg}
          />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
            bottom: 0,
            width: '100%',
            position: 'absolute',
          }}
        >
          <TitleStyle
            sx={{ typography: 'h5', height: 60, color: 'common.white' }}
          >
            {Title || ''}
          </TitleStyle>
          <Typography
            variant="body2"
            color="common.white"
            dangerouslySetInnerHTML={{ __html: Description }}
            sx={{ maxHeight: '150px', overflow: 'auto' }}
          />
          {Video && Video.Location && (
            <Fab
              sx={{ float: 'right', marginTop: -4 }}
              variant="extended"
              onClick={(event) => {
                event.preventDefault();
                onWatch(gallery);
              }}
            >
              <PlayArrowOutlined /> Play Video
            </Fab>
          )}

          <InfoStyle>
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: index === 0 ? 0 : 1.5,
                color: 'grey.500',
              }}
            >
              <Box
                component={LocationOn}
                sx={{ width: 16, height: 16, mr: 0.5 }}
              />
              <Typography variant="caption">{Location || ''}</Typography>
            </Box>
          </InfoStyle>
        </CardContent>
      </Card>
    </Grid>
  );
}
