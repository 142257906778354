import React from 'react';
import {
  GridContainer,
  GridItem,
  Block,
  TextArea,
  Input,
  DatePicker,
  Alert,
  Text,
  Tab,
  Tabs,
  ListSubMenu,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { makeStyles } from '@mui/styles';
import {
  StaticListSelector,
  LayersManager,
  RemoteSelector,
} from '@cloudhub-ux/core/baseweb';
import ScopeForm from 'localcomponents/ScopeForm';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import { sizes } from 'theme';
import Interventions from 'actionverification/actions/forms/Interventions';
import VerificationContext from 'actionverification/context/VerificationContext';
import { useLocation } from '@cloudhub-ux/core/customhooks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: `calc(100vh - ${sizes.adminNacbarHeight}px)`,
    overflow: 'auto',
    paddingBottom: sizes.padding * 2,
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const ActionForm = () => {
  const [tabvalue, settabvalue] = React.useState(0);
  const classes = useStyles();
  const { error, reset, appAxiosInstance } = useVerificationAxios({});
  const {
    VerificationState: { CurrentAction },
  } = React.useContext(VerificationContext);

  const { location } = useLocation();

  return (
    <div className={classes.root}>
      <Block>
        <Tabs
          value={tabvalue}
          onChange={(event, val) => settabvalue(val)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Action Details" />
          <Tab
            label={
              location.pathname.includes('validations')
                ? 'Validator Followup'
                : 'Verifier Followup'
            }
          />
        </Tabs>
      </Block>
      {tabvalue === 0 && (
        <Block>
          <Form
            onSubmit={() => {}}
            initialValues={{ ...CurrentAction }}
            validateOnChange={false}
            render={({ values }) => (
              <Block
                style={{
                  maxHeight: '100%',
                  overflow: 'auto',
                }}
              >
                <LayersManager zIndex={1}>
                  <GridContainer spacing={2}>
                    <GridItem>
                      {Boolean(error) && (
                        <Alert
                          error
                          message={error}
                          onClose={() => reset('')}
                        />
                      )}
                    </GridItem>

                    <GridItem sm={12}>
                      <Text h5>
                        Submitted By: {((values || {}).User || {}).Name}
                      </Text>
                    </GridItem>
                    <GridItem sm={12}>
                      <Text h5>
                        Institution:{' '}
                        {((values || {}).User || {}).InstitutionName}
                      </Text>
                    </GridItem>
                    <GridItem sm={12}>
                      <Field
                        readOnly
                        name="ActionName"
                        type="text"
                        label="Action Name"
                        component={Input}
                        required
                        maxLength={200}
                      />
                    </GridItem>
                    <GridItem sm={12} md={4}>
                      <Field
                        readOnly
                        name="Programme"
                        label="Programme"
                        component={RemoteSelector}
                        axiosinstance={appAxiosInstance}
                        url="/programme/filtersearch"
                        valueExtractor={(row) =>
                          row
                            ? {
                                id: row.id,
                                _id: row._id,
                                ProgrammeName: row.ProgrammeName,
                                ProgrammeCode: row.ProgrammeCode,
                              }
                            : row
                        }
                        labelExtractor={(row) =>
                          (row || {}).ProgrammeName || ''
                        }
                        keyExtractor={(row) => (row || {}).id}
                        flex
                      />
                    </GridItem>
                    <GridItem sm={12} md={4}>
                      <Field
                        readOnly
                        name="Project"
                        label="Project"
                        component={RemoteSelector}
                        axiosinstance={appAxiosInstance}
                        url="/project/filtersearch"
                        valueExtractor={(row) =>
                          row
                            ? {
                                id: row.id,
                                _id: row._id,
                                ProjectName: row.ProjectName,
                                ProjectCode: row.ProjectCode,
                              }
                            : row
                        }
                        labelExtractor={(row) => (row || {}).ProjectName || ''}
                        keyExtractor={(row) => (row || {}).id}
                        params={
                          ((values || {}).Programme || {}).id
                            ? {
                                'Programme.id': ((values || {}).Programme || {})
                                  .id,
                              }
                            : {}
                        }
                        flex
                      />
                    </GridItem>
                    <GridItem sm={12} md={4}>
                      <Field
                        readOnly
                        name="Scope"
                        label="Scope"
                        component={StaticListSelector}
                        options={[
                          'COUNTRY_WIDE',
                          'COUNTY',
                          'CONSTITUENCY',
                          'WARD',
                          'VILLAGE',
                        ]}
                        required
                        flex
                      />
                    </GridItem>
                    <GridItem sm={12}>
                      <ScopeForm values={values} />
                    </GridItem>
                    <GridItem xs={12}>
                      <Block middle center>
                        {(values || {}).Coordinates &&
                          values.Coordinates.Longitude && (
                            <Text h6 primary>
                              Longitude: {values.Coordinates.Longitude}{' '}
                              Latitude:
                              {values.Coordinates.Latitude}
                            </Text>
                          )}
                      </Block>
                    </GridItem>
                    <GridItem sm={12} md={4}>
                      <Field
                        readOnly
                        name="StartDate"
                        label="Start Date of Action"
                        component={DatePicker}
                        required
                      />
                    </GridItem>
                    <GridItem sm={12} md={4}>
                      <Field
                        readOnly
                        name="EndDate"
                        label="End Date of Action"
                        component={DatePicker}
                        required
                        flex
                      />
                    </GridItem>
                    <GridItem sm={12} md={4}>
                      <Field
                        readOnly
                        name="ActionStatus"
                        label="ActionStatus"
                        component={StaticListSelector}
                        options={[
                          'ACTIVE',
                          'COMPLETTED',
                          'ON_HOLD',
                          'CANCELED',
                          'ABANDONED',
                        ]}
                        required
                        flex
                      />
                    </GridItem>

                    <GridItem>
                      <Field
                        readOnly
                        name="Description"
                        type="text"
                        label="Description of Action"
                        component={TextArea}
                        rows={10}
                        required
                        maxLength={5000}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      {(((values || {}).Risks || {}).Files || []).map((file) =>
                        file && file.Location ? (
                          <a
                            style={{ width: '100%' }}
                            href={file.Location}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {file.filename || file.name}
                          </a>
                        ) : null
                      )}
                    </GridItem>
                    {((values || {}).Safeguard || []).map((safeguard) => {
                      const {
                        SafeguardName,
                        SafeguardCode,
                        Description,
                        Indicators,
                        id,
                      } = safeguard;
                      if (
                        !id ||
                        !SafeguardName ||
                        !SafeguardCode ||
                        (Indicators || []).length === 0
                      ) {
                        return null;
                      }
                      return (
                        <ListSubMenu
                          header={safeguard.SafeguardName}
                          key={safeguard.id}
                        >
                          <Block
                            shadow
                            margin={sizes.baseMargin}
                            padding={sizes.padding}
                          >
                            {Boolean(error) && (
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Alert error message={error} onClose={reset} />
                              </GridItem>
                            )}
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Text h4 bold primary>
                                {SafeguardName}
                              </Text>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: Description,
                                }}
                              />
                            </GridItem>
                            {Indicators.map((indicator) => {
                              const {
                                IndicatorName,
                                AssessmentQuestion,
                              } = indicator;
                              if (!IndicatorName || !AssessmentQuestion) {
                                return null;
                              }
                              return (
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  key={IndicatorName}
                                >
                                  <Field
                                    name={`Safeguards.${SafeguardCode}.${IndicatorName.replace(
                                      /[\s.,]/g,
                                      '_'
                                    )}`}
                                    label={
                                      <Text>
                                        <Text bold>{IndicatorName}:</Text>{' '}
                                        {AssessmentQuestion}{' '}
                                      </Text>
                                    }
                                    component={TextArea}
                                    required
                                    readOnly
                                  />
                                </GridItem>
                              );
                            })}
                          </Block>
                        </ListSubMenu>
                      );
                    })}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      {((values || {}).Files || []).map((file) =>
                        file && file.Location ? (
                          <a
                            style={{ width: '100%' }}
                            href={file.Location}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {file.filename || file.name}
                          </a>
                        ) : null
                      )}
                    </GridItem>
                  </GridContainer>
                </LayersManager>
              </Block>
            )}
          />
        </Block>
      )}
      <Block>
        {tabvalue === 1 && (
          <Block>
            <Interventions />
          </Block>
        )}
      </Block>
    </div>
  );
};

export default ActionForm;
