import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Institutions from './Institutions';
import AboutPage from '../about/AboutPage';
import Secretariat from './Secretariat';
const StakeholderRoutes = () => {
  return (
    <Router style={{ flex: 1 }}>
      <Institutions path="institutions" />
      <Secretariat path="secretariat" />
      <AboutPage path="/:Title" />
    </Router>
  );
};

export default StakeholderRoutes;
