import React from 'react';
import {
  Block,
  Text,
  Input,
  FieldBlock,
  ListMenuItem,
  IconButton,
  ListMenu,
  Alert,
  Button,
  TextArea,
} from '@cloudhub-ux/core';
import Pagination from '@mui/material/Pagination';
import { sizes, colors } from 'theme';
import { VisibilityOutlined } from '@mui/icons-material';
import { Form, Field } from '@cloudhub-ux/core/form';
import { StaticListSelector, LayersManager } from '@cloudhub-ux/core/baseweb';
import InterventionEvents from './InterventionEvents';
import useAppAxios from 'app/context/useAppAxios';

const Interventions = ({ ActionId }) => {
  const [interventions, setinterventions] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [totalCount, settotalCount] = React.useState(0);
  const [editing, setediting] = React.useState({});

  const { getData, error, reset, postData } = useAppAxios({});

  const getInterventions = React.useCallback(async () => {
    try {
      if (ActionId) {
        const data = await getData({
          url: `/app/actions/intervention/actioninterventions`,
          params: { ActionId, skip: page * 20, limit: 20 },
        });
        if (data && Array.isArray(data.items)) {
          setinterventions(data.items);
          settotalCount(data.totalCount);
        }
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [ActionId, page]);

  React.useEffect(() => {
    getInterventions();
  }, [getInterventions]);

  const submitFollowup = async (values, form) => {
    try {
      const data = await postData({
        url: `/app/actions/reddaction/followup`,
        params: { ActionId, Request: values },
      });
      if (data && Array.isArray(data.items)) {
        getInterventions();
        form.reset();
        reset();
      }
    } catch (error) {}
    // eslint-disable-next-line
  };

  return (
    <Block row wrap>
      {interventions.length > 0 && (
        <Block flex={false}>
          <ListMenu>
            {interventions.map((intervention) => (
              <ListMenuItem
                divider
                // button={false}
                key={intervention.id}
                primary={
                  <Text h6 bold style={{ paddingRight: 75 }}>
                    {intervention.Name}
                  </Text>
                }
                secondary={`${intervention.InterventionType} - ${intervention.InterventionCode}`}
                action={
                  <IconButton
                    onClick={() => {
                      setediting(intervention);
                    }}
                  >
                    <VisibilityOutlined color="primary" />
                  </IconButton>
                }
              />
            ))}
          </ListMenu>
          {totalCount > 20 && (
            <Block center flex={false} margin={[sizes.doubleBaseMargin, 0]}>
              <Pagination
                classes={{}}
                count={totalCount}
                rowsPerPage={20}
                onChange={(event, current) => setPage(current)}
              />
            </Block>
          )}
        </Block>
      )}
      <Form
        onSubmit={submitFollowup}
        initialValues={editing || {}}
        render={({ values, pristine, submitting, handleSubmit }) => (
          <Block>
            <Button
              style={{ alignSelf: 'flex-end' }}
              onClick={() => setediting({})}
            >
              <Text error> Clear</Text>
            </Button>
            <LayersManager zIndex={1}>
              <FieldBlock row wrap>
                <Field
                  name="InterventionType"
                  label="Type of Followup"
                  component={StaticListSelector}
                  options={['Request for Information', 'Request for Action']}
                  flex
                  readOnly
                />
                {(editing || {}).InterventionCode && (
                  <Field
                    name="InterventionCode"
                    label="Followup Code"
                    component={Input}
                    flex
                    readOnly
                  />
                )}
              </FieldBlock>
              <Field
                name="Description"
                label="Describe the Followup"
                component={TextArea}
                readOnly
              />

              <FieldBlock row wrap paper>
                {((values || {}).Files || []).map((file) => (
                  <a
                    href={(file || {}).Location}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {(file || {}).filename}
                  </a>
                ))}
              </FieldBlock>
              {(editing || {}).id && (
                <InterventionEvents
                  InterventionId={editing.id}
                  Events={editing.Events || []}
                  onUpdate={(val) => {
                    setinterventions((current) =>
                      (current || []).map((item) => {
                        if (item.id === editing.id) {
                          return { ...item, Events: val };
                        }
                        return item;
                      })
                    );
                    setediting(val);
                  }}
                />
              )}
              {!(editing || {}).id && (
                <Button
                  color={colors.primary}
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                  fullWidth
                >
                  Submit Followup Question
                </Button>
              )}
            </LayersManager>
            {Boolean(error) && <Alert error message={error} onClose={reset} />}
          </Block>
        )}
      />
    </Block>
  );
};
export default Interventions;
