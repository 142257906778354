import React from 'react';
import { Block, Container } from '@cloudhub-ux/core';

import SignInForm from './SignInForm';
// import Logo from '../../assets/images/no_available_image.png';
import BG from './assets/images/CloudHubBg.svg';
import './auth.css';

const styles = {
  introBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -150,
    background: `url(${BG}), linear-gradient(to right, #3b3d61, #4d4a78)`,
    transform: 'skew(0, -3.5deg)',
    minHeight: 600,
    zIndex: -10,
  },
};

const Signin = ({ navigate }) => (
  <Block>
    <Block flex={false} style={styles.introBg} />

    <Container maxWidth="lg">
      <Block paper padding={[50, 0]}>
        <SignInForm />
      </Block>
    </Container>
  </Block>
);

export default Signin;
