/* eslint-disable capitalized-comments */
import React from 'react';
import { TableCell } from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import { Block, Alert } from '@cloudhub-ux/core';

import GrievancereportForm from './forms/GrievancereportForm';
import VerificationContext from 'actionverification/context/VerificationContext';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Grievancereport = () => {
  const { verificationAxiosInstance } = React.useContext(VerificationContext);
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const {
    VerificationState: { Grievancehandler, Disseminator, Aggregator },
  } = React.useContext(VerificationContext);

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    if (column.name === 'Author') {
      return <TableCell>{(row.Author || {}).Name}</TableCell>;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'Title' },
    { name: 'DocumentNo', title: 'Document No' },
    { name: 'Author' },
  ];

  const columnWidths = [
    { columnName: 'Title', width: 200 },
    { columnName: 'DocumentNo', width: 150 },
    { columnName: 'Author', width: 50 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Grievance Reports"
        url="/grievancereport/getall"
        axiosinstance={verificationAxiosInstance}
        params={{
          ...((Grievancehandler || {}).id &&
            !(Disseminator || {}).id &&
            !(Aggregator || {}).id && { 'Author.id': Grievancehandler.id }),
        }}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onEdit={(row) => setEditingRow(row)}
        onDeleteRows={() => {}}
        ref={gridRef}
      />
      <GrievancereportForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Grievancereport;
