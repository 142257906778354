import React from 'react';

import { FieldBlock, Input } from '@cloudhub-ux/core';
import { Fieldset, Field } from '@cloudhub-ux/core/form';
import { CountrySelector } from '@cloudhub-ux/core/baseweb';
import PhoneInput from '@cloudhub-ux/core/PhoneInput';

const AddressComponent = () => (
  <React.Fragment>
    <Fieldset label="Address">
      <Field
        label="Physical Address"
        type="text"
        name="Address.PhysicalAddress"
        component={Input}
        required
      />
      <Field
        label="Post Address"
        type="text"
        name="Address.PostAddress"
        component={Input}
        required
      />
      <FieldBlock row>
        <Field
          label="Phone"
          type="number"
          name="Address.Phone"
          component={PhoneInput}
          required
          flex
        />
        <Field
          label="Email"
          type="email"
          name="Address.Email"
          component={Input}
          required
          flex
        />
        <Field
          label="Alternative  Phone"
          type="tel"
          name="Address.Phone2"
          component={Input}
          flex
        />
      </FieldBlock>
      <FieldBlock row>
        <Field
          label="City/Town"
          type="text"
          name="Address.City"
          component={Input}
          required
          flex
        />
        <Field
          label="Country"
          type="text"
          name="Address.Country"
          component={CountrySelector}
          flex
        />
        <Field
          label="Website"
          type="text"
          name="Address.Website"
          component={Input}
          flex
        />
      </FieldBlock>
    </Fieldset>
  </React.Fragment>
);

export default AddressComponent;
