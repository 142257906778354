import React from 'react';
import { Dialog, Text, GridContainer, GridItem } from '@cloudhub-ux/core';
import { DialogHeader } from '@cloudhub-ux/core/dialog';
import DialogLayer from 'localcomponents/DialogLayer';
import ActionForm from 'actionverification/actions/forms/ActionForm';
import ActionverificationForm from 'actionverification/actions/forms/verification/ActionverificationForm';
import VerificationContext from 'actionverification/context/VerificationContext';

const VerificationForm = (props) => {
  const { editingRow } = props;
  const { _from } = editingRow || {};

  const {
    VerificationState: { verificationActions },
  } = React.useContext(VerificationContext);

  const { getAction } = verificationActions;

  React.useEffect(() => {
    if (_from) {
      getAction((_from || '').split('/')[1]);
    }
    // eslint-disable-next-line
  }, [_from]);

  return (
    <Dialog open={editingRow !== null} fullScreen onClose={props.onCancelEdit}>
      <DialogHeader onClose={props.onCancelEdit}>
        <Text h4 bold>
          {(editingRow || {}).ActionName || (editingRow || {}).ActionCode || ''}
        </Text>
      </DialogHeader>
      <DialogLayer>
        <GridContainer>
          <GridItem
            sm={12}
            md={8}
            style={{ maxHeight: '100%', overflow: 'auto', paddingBottom: 100 }}
          >
            <ActionForm />
          </GridItem>
          <GridItem
            sm={12}
            md={4}
            style={{ maxHeight: '100%', overflow: 'auto', paddingBottom: 100 }}
          >
            <ActionverificationForm Verification={editingRow} />
          </GridItem>
        </GridContainer>
      </DialogLayer>
    </Dialog>
  );
};

export default VerificationForm;
