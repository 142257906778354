import React from 'react';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { varFadeIn, MotionInView } from '@cloudhub-ux/min/theme/animate';
import useAppAxios from 'app/context/useAppAxios';

export default function FaqsList() {
  const [faqs, setfaqs] = React.useState([]);

  const { getData } = useAppAxios({});
  const getFaqs = async () => {
    try {
      const data = await getData({
        url: '/app/website/faq/getall',
      });
      if (data && Array.isArray(data.items)) {
        setfaqs(data.items);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getFaqs();
    // eslint-disable-next-line
  }, []);

  return (
    <MotionInView variants={varFadeIn}>
      {faqs.map((accordion) => (
        <Accordion key={accordion.Question}>
          <AccordionSummary
            expandIcon={
              <Icon icon={arrowIosDownwardFill} width={20} height={20} />
            }
          >
            <Typography variant="subtitle1">{accordion.Question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{accordion.Answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </MotionInView>
  );
}
