import React from 'react';
import {
  GridContainer,
  GridItem,
  Button,
  Block,
  TextArea,
  Input,
  Alert,
  toastr,
  PhoneInput,
  CheckBox,
  Text,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import AuthContext from 'auth/context/AuthContext';
import { colors, sizes } from 'theme';
import { LayersManager } from '@cloudhub-ux/core/baseweb';
import FeedbackLocationForm from 'localcomponents/FeedbackLocationForm';
import useAppAxios from 'app/context/useAppAxios';
import AppContext from 'app/context/AppContext';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import GrievanceSubmitted from './GrievanceSubmitted';
import GrievanceStatus from './GrievanceStatus';

const NewGrievanceForm = () => {
  const [uploading, setuploading] = React.useState(false);
  const [submittingGrievance, setsubmittingGrievance] = React.useState(null);
  const [submittedGrievance, setsubmittedGrievance] = React.useState(null);
  const { error, reset, postData } = useAppAxios({});

  const {
    GrievanceState: { TrackingGrievance },
  } = React.useContext(AppContext);
  const { currentUser, isAuthenticated } = React.useContext(AuthContext);

  const submitGrievance = async (values) => {
    try {
      delete values.ConfirmPassword;
      const data = await postData({
        url: '/app/fgrm/grievance/submitgrievance',
        params: values,
      });
      if (data && data.GrievanceCode) {
        toastr.success(
          'Success, Grievance submitted successfully and notifications sent to start verification'
        );
        setsubmittedGrievance(data);
        reset();
      }
    } catch (error) {
      //
    }
  };

  const changeFile = (event, form) => {
    if (event && event.target && event.target.value) {
      const v = event.target.value || [];
      const ind = v.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', v);
    }
    if (event && Array.isArray(event)) {
      const ind = event.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', event);
    }
  };
  const onRemoveFiles = async (file) => {
    try {
      const data = await postData({
        url: '/media/upload/deletefiles',
        params: { files: [file] },
      });
      if (data) {
        toastr.success('Files deleted successfully');
        return data;
      }
    } catch (error) {}
  };
  return !(TrackingGrievance || {}).id &&
    !(submittedGrievance || {}).GrievanceCode ? (
    <GridItem xs={12} md={8}>
      <Form
        onSubmit={submitGrievance}
        initialValues={
          isAuthenticated & !(TrackingGrievance || {}).id
            ? {
                Name: currentUser.Name,
                Email: currentUser.Email,
                Phone: currentUser.Phone,
              }
            : { ...(TrackingGrievance || {}) }
        }
        validateOnChange={false}
        render={({
          handleSubmit,
          pristine,
          submitting,
          form,
          values,
          valid,
        }) => (
          <Block>
            <LayersManager zIndex={1}>
              <GridContainer spacing={2}>
                {!submittingGrievance && (
                  <React.Fragment>
                    <GridContainer spacing={2}>
                      <GridItem sm={12}>
                        <Field
                          name="Anonymous"
                          tag="Submit as Anonymous"
                          component={CheckBox}
                          height={sizes.icons.small}
                        />
                      </GridItem>
                      {!(values || {}).Anonymous && (
                        <React.Fragment>
                          <GridItem sm={12}>
                            <Field
                              name="Name"
                              type="text"
                              label="Name"
                              component={Input}
                              required
                              maxLength={200}
                            />
                          </GridItem>
                          <GridItem sm={12} md={6}>
                            <Field
                              name="Email"
                              type="text"
                              label="Email"
                              component={Input}
                              required
                              maxLength={200}
                            />
                          </GridItem>
                          <GridItem sm={12} md={6}>
                            <Field
                              name="Phone"
                              type="text"
                              label="Phone"
                              component={PhoneInput}
                              required
                              maxLength={200}
                            />
                          </GridItem>
                          <GridItem sm={12}>
                            <FeedbackLocationForm
                              values={values}
                              required={{
                                County: true,
                                Constituency: true,
                                Ward: true,
                              }}
                            />
                          </GridItem>

                          <GridItem sm={12}>
                            <Field
                              name="PhysicalAddress"
                              type="text"
                              label="Physical Address"
                              component={Input}
                              required
                              maxLength={200}
                            />
                          </GridItem>
                        </React.Fragment>
                      )}
                    </GridContainer>
                    <GridItem sm={12}>
                      <Field
                        name="Subject"
                        type="text"
                        label="Subject of Grievance"
                        component={Input}
                        required
                        maxLength={200}
                      />
                    </GridItem>
                    <GridItem>
                      <Field
                        name="Body"
                        type="text"
                        label="Description of Grievance"
                        component={TextArea}
                        rows={10}
                        required
                        maxLength={5000}
                        minLength={200}
                      />
                    </GridItem>
                    <GridItem>
                      {Boolean(error) && (
                        <Alert
                          error
                          message={error}
                          onClose={() => reset('')}
                        />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field
                        type="text"
                        label="Response Files"
                        name="Files"
                        url={`/api/media/upload/file`}
                        // axiosinstance={axiosinstance}
                        component={FilesUpload}
                        limit={5}
                        onChange={(event) => changeFile(event, form)}
                        onRemove={onRemoveFiles}
                      />
                    </GridItem>
                  </React.Fragment>
                )}
                {submittingGrievance && (
                  <React.Fragment>
                    <GridItem xs={12} sm={12} md={12}>
                      <Text
                        h6
                        bold
                        style={{ width: '100%', textAlign: 'center' }}
                      >
                        Set a password to help you keep track of your grievance
                      </Text>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Text body>
                        To track your grievance or respond to information
                        requests, you will need to provide a Code and password.
                        Please set a password that you can remember.{' '}
                        {(values || {}).Anonymous && (
                          <strong>
                            Your Grievance Submission will remain anonymous.
                          </strong>
                        )}{' '}
                        The code will be displayed here once the grievance is
                        submittted.
                      </Text>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field
                        name="Password"
                        type="password"
                        label="Password"
                        component={Input}
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Field
                        name="ConfirmPassword"
                        type="password"
                        label="Confirm Password"
                        component={Input}
                        validate={(val) =>
                          val === (values || {}).Password
                            ? undefined
                            : 'Does not match password'
                        }
                      />
                    </GridItem>
                  </React.Fragment>
                )}
              </GridContainer>
            </LayersManager>

            <Block
              row
              style={{
                justifyContent: 'stretch',
                marginTop: sizes.doubleBaseMargin,
              }}
            >
              {!submittingGrievance && (
                <Button
                  contained
                  color={colors.primary}
                  disabled={submitting || pristine || uploading || !valid}
                  onClick={() => {
                    setsubmittingGrievance(values);
                  }}
                  fullWidth
                >
                  {`${uploading ? 'Uploading...' : 'Submit Grievance'}`}
                </Button>
              )}
              {submittingGrievance && (
                <Button
                  contained
                  color={colors.primary}
                  disabled={submitting || pristine || uploading}
                  onClick={handleSubmit}
                  fullWidth
                >
                  {`${submitting ? 'Submitting...' : 'Submit Grievance'}`}
                </Button>
              )}
            </Block>
          </Block>
        )}
      />
    </GridItem>
  ) : (
    <React.Fragment>
      {submittedGrievance && (
        <GrievanceSubmitted Grievance={submittedGrievance} />
      )}
      {TrackingGrievance && <GrievanceStatus />}
    </React.Fragment>
  );
};

export default NewGrievanceForm;
