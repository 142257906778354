import React from 'react';
import axios from 'axios';
import CONFIG from 'auth/context/Config';
import useSocketEventListener from 'socket/useSocketEventListener';
import useServiceWorkerStore from './store/useServiceWorkerStore';

let localapp = localStorage.getItem('appsetting');

if (localapp) {
  try {
    localapp = JSON.parse(localapp);
  } catch (error) {
    localapp = null;
  }
}

const axiosInstance = () =>
  axios.create({
    baseURL: CONFIG.API_ENDPOINT,
    headers: {
      'Content-Type': 'application/json',
    },
  });

const UpdatesListener = () => {
  const [app, setApp] = React.useState(localapp || {});

  const serviceWorkerUpdated = useServiceWorkerStore(
    (state) => state.serviceWorkerUpdated
  );
  const registeredServiceWorker = useServiceWorkerStore(
    (state) => state.registeredServiceWorker
  );

  const getApp = async () => {
    try {
      const { data } = await axiosInstance().get(
        '/app/settings/appsetting/getapp'
      );

      if (data) {
        setApp(data);
        if (localStorage) {
          localStorage.setItem('appsetting', JSON.stringify(data));
        }
      }
    } catch (error) {}
  };

  const checkAppVersion = (appsettings) => {
    try {
      const settings = appsettings || {};
      if (settings.Version !== app.Version) {
        setApp(appsettings);
        localStorage.setItem('appsetting', JSON.stringify(appsettings));
        // Reload app to trigger update if, app version is different
        window.location.reload();
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getApp();
  }, []);

  React.useEffect(() => {
    const updateApplication = () => {
      const registrationWaiting = registeredServiceWorker.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

        registrationWaiting.addEventListener('statechange', (e) => {
          if (e.target.state === 'activated') {
            window.location.reload();
          }
        });
      }
    };
    if (serviceWorkerUpdated) {
      updateApplication();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceWorkerUpdated]);

  // We can use sockets to force reload on the clients whenever we push a new update
  useSocketEventListener('APP_SETTINGS', checkAppVersion);

  return null;
};

export default UpdatesListener;
