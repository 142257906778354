import React from 'react';
import { GridContainer, GridItem, Container } from '@cloudhub-ux/core';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { MHidden } from '@cloudhub-ux/min/theme/@material-extend';
import GrievanceHero from './GrievanceHero';
import RetrieveGrievance from './RetrieveGrievance';
import NewGrievanceForm from './NewGrievanceForm';
import AppContext from 'app/context/AppContext';
import Block from '@cloudhub-ux/core/Block';
import { sizes } from 'theme';

const RootStyle = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(8),
  // [theme.breakpoints.up('md')]: {
  //   paddingTop: theme.spacing(11),
  // },
}));

const GrievancePage = () => {
  const {
    AppSettings: { Organization },
  } = React.useContext(AppContext);
  const { Address, Phone, Email } = Organization || {};
  const { Line1, Line2, Location } = Address || {};
  return (
    <RootStyle>
      <MHidden width="mdDown">
        <GrievanceHero />
      </MHidden>
      <Container sx={{ my: 10 }}>
        <GridContainer spacing={4}>
          <NewGrievanceForm />
          <GridItem xs={12} md={4}>
            {Line1 && (
              <Block
                paper
                flex={false}
                padding={sizes.padding}
                style={{ textAlign: 'right' }}
              >
                <Typography variant="h5" gutterBottom>
                  OR Contact Us
                </Typography>
                <motion.div>
                  <Typography variant="h6" paragraph color="primary">
                    {Line1}
                    <br />
                    {Line2}
                    <br />
                    {Location}
                  </Typography>
                </motion.div>
                <motion.div>
                  <Typography variant="h6" color="primary">
                    {Email}
                    <br /> {Phone}
                  </Typography>
                </motion.div>
              </Block>
            )}

            <RetrieveGrievance />
          </GridItem>
        </GridContainer>
      </Container>
    </RootStyle>
  );
};

export default GrievancePage;
