/* eslint-disable capitalized-comments */
import React from 'react';
import { TableCell } from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import { Block, Alert, IconButton } from '@cloudhub-ux/core';

import ReddactionForm from './forms/reddaction/ReddactionForm';
import VerificationContext from 'actionverification/context/VerificationContext';
import { Edit } from '@mui/icons-material';

const Reddaction = () => {
  const { verificationAxiosInstance } = React.useContext(VerificationContext);
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'ActionName', title: 'Action Name' },
    { name: 'ActionCode', title: 'Action Code' },
    { name: 'Status' },
  ];

  const columnWidths = [
    { columnName: 'ActionName', width: 200 },
    { columnName: 'ActionCode', width: 150 },
    { columnName: 'Status', width: 50 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Redd+ Actions"
        url="/reddaction/getall"
        actionsComponent={(props) => {
          return (
            <IconButton onClick={() => setEditingRow((props || {}).row)}>
              <Edit color="primary" />
            </IconButton>
          );
        }}
        axiosinstance={verificationAxiosInstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        // params={{ Status: 'VALIDATED' }}
        onAdd={() => {}}
        onEdit={(row) => setEditingRow(row)}
        onDeleteRows={() => {}}
        ref={gridRef}
      />
      <ReddactionForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Reddaction;
