import React from 'react';
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  LinearProgress,
  Box,
} from '@mui/material';
import { fShortenNumber } from '@cloudhub-ux/min/utils/formatNumber';
import AdminContext from 'admin/context/AdminContext';

export default function VerificationsByStatus() {
  const {
    Tasks: { Tasks },
  } = React.useContext(AdminContext);
  const { Verifications } = Tasks;
  const {
    PendingVerifications,
    SubmittedVerifications,
    AllVerifications,
    OverdueVerifications,
  } = Verifications || {};

  return (
    <Card>
      <CardHeader title="Action Verifications" />
      <Stack spacing={3} sx={{ px: 3, my: 5 }}>
        <LinearProgress
          variant="determinate"
          key="pending_verifications"
          value={((PendingVerifications || 0) / (AllVerifications || 1)) * 100}
          color="warning"
          // {
          //   (progress.status === 'Pending' && 'warning') ||
          //   (progress.status === 'Cancel' && 'error') ||
          //   'success'
          // }
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          key="submitted_verifications"
          value={
            ((SubmittedVerifications || 0) / (AllVerifications || 1)) * 100
          }
          color="success"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          key="overdue_verifications"
          value={((OverdueVerifications || 0) / (AllVerifications || 1)) * 100}
          color="error"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, pb: 3 }}
      >
        <Stack key="pending_verifications" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'warning.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Pending
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(PendingVerifications)}
          </Typography>
        </Stack>
        <Stack key="submitted_verifications" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'success.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Submitted
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(SubmittedVerifications)}
          </Typography>
        </Stack>
        <Stack key="overdue_verifications" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: 0.5,
                bgcolor: 'error.main',
              }}
            />
            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
              Overdue
            </Typography>
          </Stack>

          <Typography variant="h6">
            {fShortenNumber(OverdueVerifications)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
