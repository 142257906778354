import React from 'react';
import { motion } from 'framer-motion';
// import { Icon } from '@iconify/react';
// import searchFill from '@iconify/icons-eva/search-fill';
import { alpha, styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import {
  // varFadeInUp,
  TextAnimate,
  // MotionInView,
  varWrapEnter,
  varFadeInRight,
} from '@cloudhub-ux/min/theme/animate';
import { Images, colors } from 'theme';

const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundImage: `linear-gradient(to right, ${alpha(colors.dark, 1)}, ${alpha(
    colors.dark,
    0.7
  )}), url(${Images.landing}), url(${Images.landing2})`,
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
    backgroundColor: 'cyan',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10),
  },
}));

// const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
//   width: 320,
//   color: theme.palette.common.white,
//   transition: theme.transitions.create(['box-shadow', 'width'], {
//     easing: theme.transitions.easing.easeInOut,
//     duration: theme.transitions.duration.shorter,
//   }),
//   '&.Mui-focused': {
//     backgroundColor: alpha(theme.palette.common.white, 0.04),
//     [theme.breakpoints.up('md')]: {
//       width: 480,
//     },
//   },
//   '& fieldset': {
//     borderWidth: `1px !important`,
//     borderColor: `${theme.palette.grey[500_32]} !important`,
//   },
// }));

export default function FaqsHero() {
  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      <Container maxWidth="lg" sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle>
          <TextAnimate
            text="Get"
            sx={{ color: 'primary.main', mr: 2 }}
            variants={varFadeInRight}
          />
          <TextAnimate
            text="Started"
            sx={{ color: 'primary.main' }}
            variants={varFadeInRight}
          />
          <br />
          <Box sx={{ display: 'inline-flex', color: 'common.white' }}>
            <TextAnimate text="With" sx={{ mr: 2 }} />
            <TextAnimate text="the" sx={{ mr: 2 }} />
            <TextAnimate text="Kenya" sx={{ mr: 2 }} />
            <TextAnimate text="REDD+" />
            <TextAnimate text="SIS" />
          </Box>
          {/* <MotionInView variants={varFadeInUp} sx={{ mt: 5 }}>
            <SearchStyle
              placeholder="Search support"
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: 'text.disabled' }}
                  />
                </InputAdornment>
              }
            />
          </MotionInView> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
