import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Dashboard from './dashboard/Dashboard';
import VerificationRoute from './authroutes/VerificationRoute';
import Validation from './actions/Validation';
import Verification from './actions/Verification';
import Grievance from './grievance/Grievance';
import Reddaction from './actions/Reddaction';
import Grievancereport from './grievance/Grievancereport';
import Infopage from './dissemination/Infopage';
import Documents from './dissemination/Documents';

const VerificationRoutes = () => (
  <Router
    style={{
      display: 'flex',
      flex: 1,
    }}
  >
    <Dashboard default path="dashboard" />
    <VerificationRoute
      component={Validation}
      Action="Validation"
      path="validations"
    />
    <VerificationRoute
      component={Verification}
      Action="Validation"
      path="verifications"
    />
    <VerificationRoute
      component={Grievance}
      Action="Grievance"
      path="grievances"
    />
    <VerificationRoute
      component={Grievancereport}
      Action="Aggregation/Grievance"
      path="grievancereports"
    />
    <VerificationRoute
      component={Reddaction}
      Action="Aggregation"
      path="actions"
    />
    <VerificationRoute
      component={Infopage}
      Action="Aggregation/Dissemination"
      path="infopages"
    />
    <VerificationRoute
      component={Documents}
      Action="Aggregation/Dissemination"
      path="documents"
    />
  </Router>
);

export default VerificationRoutes;
