import React, { useState } from 'react';
import {
  FieldButton,
  Text,
  Input,
  IconButton,
  Block,
  ListMenu,
  ListMenuItem,
  toastr,
  Button,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { Close as CloseIcon, Edit, DragHandle } from '@mui/icons-material';
import SortableList from 'react-sortablejs-list';
import { colors, sizes } from 'theme';
import CKEditor from '@cloudhub-ux/core/CKEditor';

const PageSectionForm = ({ input: { onChange, value } }) => {
  const [selectedIndex, selectIndex] = useState(-1);
  const [editing, setediting] = React.useState({});

  const onAddSection = (values, form) => {
    const ind = [...(value || [])].findIndex(
      (section) => section && section.Title === values.Title
    );
    if (ind > -1) {
      toastr.error('Section already added');
      return;
    }
    onChange([...(value || []), values]);
    setTimeout(() => {
      form.reset();
    });
  };

  const onUpdateSection = (values) => {
    const ind = [...(value || [])].findIndex(
      (section, idx) =>
        section && section.Title === values.Title && idx !== selectedIndex
    );
    if (ind > -1) {
      toastr.error('Duplicate Section');
      return;
    }
    const index = selectedIndex;
    const sections = [...(value || [])];
    sections[index] = values || {};
    setediting({});
    selectIndex(0);
    setTimeout(() => {
      onChange([...sections]);
    });
  };
  const handleFormSubmit = (section, form) => {
    if (selectedIndex > -1) {
      onUpdateSection(section, form);
    } else {
      onAddSection(section, form);
    }
    setTimeout(() => {
      form.reset();
    });
  };
  const onSortSections = (sections) => {
    onChange([...(sections || [])]);
  };

  const onEditSection = (section, index) => {
    setediting(section);
    selectIndex(index);
  };

  const removeSection = (index) => {
    const sections = [...(value || [])];
    sections.splice(index, 1);
    onChange([...sections]);
  };

  const renderSections = () => {
    const groups = [...(value || [])];
    const renderItems = groups.map((section, index) => (
      <ListMenuItem
        dense
        key={section.Title}
        button={false}
        icon={<DragHandle style={{ cursor: 'move' }} />}
        primary={
          <Text h5 style={{ marginRight: 75 }}>
            {index + 1}. {section.Title || ''}
          </Text>
        }
        action={
          <Block flex={false} row>
            <IconButton
              aria-label={section || ''}
              onClick={() => onEditSection(section, index)}
            >
              <Edit color="primary" />
            </IconButton>
            <IconButton
              aria-label={section || ''}
              onClick={() => removeSection(index)}
            >
              <CloseIcon style={{ color: colors.error }} />
            </IconButton>
          </Block>
        }
      />
    ));

    return (
      <ListMenu>
        <SortableList options={groups} onChange={onSortSections}>
          {renderItems}
        </SortableList>
      </ListMenu>
    );
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      initialValues={editing || {}}
      render={({ handleSubmit, submitting, pristine }) => (
        <Block row padding={sizes.padding} margin={sizes.smallMargin} paper>
          <Block flex={false}>
            <Text h5 semibold>
              Page Sections
            </Text>
            {renderSections()}
          </Block>
          <Block>
            <Button
              onClick={() => {
                setediting({});
                selectIndex(-1);
              }}
              style={{ float: 'right' }}
            >
              <Text small error>
                Cancel
              </Text>
            </Button>
            <Field
              type="text"
              label="Section Title"
              required
              name="Title"
              component={Input}
            />
            <Field
              type="text"
              label="Section Content"
              required
              name="Body"
              component={CKEditor}
              height="200px"
            />

            {selectedIndex > -1 ? (
              <FieldButton
                disabled={submitting || pristine}
                contained
                color={colors.primary}
                onClick={handleSubmit}
              >
                Update Section
              </FieldButton>
            ) : (
              <FieldButton
                disabled={submitting || pristine}
                contained
                color={colors.primary}
                onClick={handleSubmit}
              >
                Add Section
              </FieldButton>
            )}
          </Block>
        </Block>
      )}
    />
  );
};
PageSectionForm.defaultProps = {
  input: {
    onChange: () => {},
    value: [],
  },
};

export default PageSectionForm;
