import React from 'react';
import CKEditor from '@cloudhub-ux/core/CKEditor';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Tab,
  Tabs,
  Block,
  Text,
  Alert,
  PhoneInput,
  CheckBox,
  TextArea,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import DialogTabs from '@cloudhub-ux/core/dialog/DialogTabs';
import { Form, Field, Fieldset } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { StaticListSelector } from '@cloudhub-ux/core/baseweb';
import ScopeForm from 'localcomponents/ScopeForm';
import DialogLayer from 'localcomponents/DialogLayer';
import AddressComponent from 'localcomponents/AddressComponent';
import StakeholderImplementor from './StakeholderImplementor';
import StakeholderDisseminator from './StakeholderDisseminator';
import StakeholderValidator from './StakeholderValidator';
import StakeholderVerifier from './StakeholderVerifier';
import StakeholderGrievancehandler from './StakeholderGrievancehandler';
import ImagesUpload from '@cloudhub-ux/core/uploaders/ImagesUpload';
import StakeholderAggregator from './StakeholderAggregator';
function TabContainer(props) {
  return <Block style={{ padding: 20 }}>{props.children}</Block>;
}
const StakeholderForm = (props) => {
  const [tabvalue, setTabValue] = React.useState(0);
  const { postData, error, reset } = useAdminAxios({});

  const addStakeholder = async (values) => {
    try {
      const data = await postData({
        url: `/stakeholders/stakeholder/savestakeholder`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="80vh">
      <Form
        onSubmit={addStakeholder}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).StakeholderName || 'Add Stakeholder'}
              </Text>
            </DialogHeader>
            <DialogTabs>
              <Tabs
                value={tabvalue}
                onChange={(e, tabvalue) => setTabValue(tabvalue)}
              >
                <Tab label="Stakeholder Details" />
                <Tab label="Contact Info" />
                {(editingRow || {}).id && <Tab label="Implementors" />}
                {(editingRow || {}).id && <Tab label="Verifiers" />}
                {(editingRow || {}).id && <Tab label="Validators" />}
                {(editingRow || {}).id && <Tab label="Aggregators" />}
                {(editingRow || {}).id && <Tab label="Disseminators" />}
                {(editingRow || {}).id && <Tab label="Grievance Handlers" />}
              </Tabs>
            </DialogTabs>
            <DialogLayer>
              {tabvalue === 0 && (
                <TabContainer>
                  <FieldBlock row wrap>
                    <Field
                      type="text"
                      label="Stakeholder Name"
                      required
                      name="StakeholderName"
                      component={Input}
                      flex
                    />
                    {(editingRow || {}).id && (
                      <Field
                        type="text"
                        label="Stakeholder Code"
                        required
                        name="StakeholderCode"
                        component={Input}
                        flex
                      />
                    )}
                  </FieldBlock>
                  <FieldBlock row wrap>
                    <Field
                      type="text"
                      label="Stakeholder Type"
                      required
                      name="StakeholderType"
                      component={StaticListSelector}
                      options={[
                        'GOVERNMENT AGENCY',
                        'NGO',
                        'PRIVATE ORGANISATION',
                        'CONSTITUTIONAL COMMISSION',
                        'CIVIL SOCIETY ORGANIZATION',
                      ]}
                      flex
                    />
                    <Field
                      type="text"
                      label="Operating Scope"
                      required
                      name="OperatingScope"
                      component={StaticListSelector}
                      options={[
                        'COUNTRY_WIDE',
                        'COUNTY',
                        'CONSTITUENCY',
                        'WARD',
                        'VILLAGE',
                      ]}
                      flex
                    />
                  </FieldBlock>
                  <ScopeForm values={values} />
                  <Field
                    type="text"
                    label="Description"
                    name="Description"
                    component={CKEditor}
                    height={300}
                  />
                  <Field
                    url="/api/media/upload/file"
                    // axiosinstance={axiosinstance}
                    component={ImagesUpload}
                    name="Logo"
                  />
                </TabContainer>
              )}
              {tabvalue === 1 && (
                <TabContainer>
                  <AddressComponent />

                  <Fieldset label="Contact Person">
                    <FieldBlock row wrap>
                      <Field
                        type="text"
                        label="Contact Person Name"
                        name="ContactPerson.Name"
                        component={Input}
                        flex
                      />
                      <Field
                        type="text"
                        label="Contact Person Phone"
                        name="ContactPerson.Phone"
                        component={PhoneInput}
                        flex
                      />
                      <Field
                        type="text"
                        label="Contact Person Email"
                        name="ContactPerson.Email"
                        component={Input}
                        flex
                      />
                      <Field
                        type="text"
                        label="Contact Person Designation"
                        name="ContactPerson.Designation"
                        component={Input}
                        flex
                      />
                    </FieldBlock>
                  </Fieldset>

                  <Fieldset label="Grievance Redress">
                    <Field
                      name="GrievanceRedress.HasMechanism"
                      component={CheckBox}
                      height={sizes.icons.small}
                      tag="Does Stakeholder have an online grievance redress mechanism"
                      style={{ marginTop: sizes.baseMargin }}
                    />
                    {((values || {}).GrievanceRedress || {}).HasMechanism && (
                      <React.Fragment>
                        <Field
                          name="GrievanceRedress.URL"
                          label="Link to Grievance redress portal"
                          component={Input}
                          required
                          validate={(val) =>
                            (val || '').match(
                              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
                            )
                              ? undefined
                              : 'Invalid URL'
                          }
                        />
                        <Field
                          name="GrievanceRedress.Description"
                          label="Describe Grievances Addressed by Stakeholder"
                          component={TextArea}
                          required
                        />
                      </React.Fragment>
                    )}
                  </Fieldset>
                </TabContainer>
              )}
              {tabvalue === 2 && (editingRow || {}).id && (
                <TabContainer>
                  <StakeholderImplementor
                    StakeholderName={(editingRow || {}).StakeholderName}
                    StakeholderCode={(editingRow || {}).StakeholderCode}
                    id={(editingRow || {}).id}
                  />
                </TabContainer>
              )}
              {tabvalue === 3 && (editingRow || {}).id && (
                <TabContainer>
                  <StakeholderVerifier
                    StakeholderName={(editingRow || {}).StakeholderName}
                    StakeholderCode={(editingRow || {}).StakeholderCode}
                    id={(editingRow || {}).id}
                  />
                </TabContainer>
              )}
              {tabvalue === 4 && (editingRow || {}).id && (
                <TabContainer>
                  <StakeholderValidator
                    StakeholderName={(editingRow || {}).StakeholderName}
                    StakeholderCode={(editingRow || {}).StakeholderCode}
                    id={(editingRow || {}).id}
                  />
                </TabContainer>
              )}
              {tabvalue === 5 && (editingRow || {}).id && (
                <TabContainer>
                  <StakeholderAggregator
                    StakeholderName={(editingRow || {}).StakeholderName}
                    StakeholderCode={(editingRow || {}).StakeholderCode}
                    id={(editingRow || {}).id}
                  />
                </TabContainer>
              )}
              {tabvalue === 6 && (editingRow || {}).id && (
                <TabContainer>
                  <StakeholderDisseminator
                    StakeholderName={(editingRow || {}).StakeholderName}
                    StakeholderCode={(editingRow || {}).StakeholderCode}
                    id={(editingRow || {}).id}
                  />
                </TabContainer>
              )}
              {tabvalue === 7 && (editingRow || {}).id && (
                <TabContainer>
                  <StakeholderGrievancehandler
                    StakeholderName={(editingRow || {}).StakeholderName}
                    StakeholderCode={(editingRow || {}).StakeholderCode}
                    id={(editingRow || {}).id}
                  />
                </TabContainer>
              )}
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <React.Fragment>
                {tabvalue < 2 && (
                  <Button
                    contained
                    onClick={handleSubmit}
                    disabled={submitting || pristine || form.invalid}
                    color={colors.secondary}
                  >
                    Save
                  </Button>
                )}
              </React.Fragment>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default StakeholderForm;
