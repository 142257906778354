import React from 'react';
import {
  GridContainer,
  GridItem,
  Button,
  Block,
  TextArea,
  Input,
  Alert,
  toastr,
  PhoneInput,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';
import useAppAxios from 'app/context/useAppAxios';
import { LayersManager } from '@cloudhub-ux/core/baseweb';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import FeedbackLocationForm from 'localcomponents/FeedbackLocationForm';
import AuthContext from 'auth/context/AuthContext';
const FeedbackForm = () => {
  const { navigate } = useLocation();
  const { error, reset, postData } = useAppAxios({});
  const { currentUser, isAuthenticated } = React.useContext(AuthContext);

  const submitFeedback = async (values) => {
    try {
      const data = await postData({
        url: '/app/fgrm/feedback/submitfeedback',
        params: values,
      });
      if (data) {
        toastr.success(
          'Success, Feedback submitted successfully and notifications sent to start verification'
        );
        reset();
        navigate('/');
      }
    } catch (error) {
      //
    }
  };
  return (
    <Form
      onSubmit={submitFeedback}
      initialValues={
        isAuthenticated
          ? {
              Name: currentUser.Name,
              Email: currentUser.Email,
              Phone: currentUser.Phone,
            }
          : {}
      }
      validateOnChange={false}
      render={({ handleSubmit, pristine, submitting, form, values }) => (
        <Block>
          <LayersManager zIndex={1}>
            <GridContainer spacing={2}>
              <GridContainer spacing={2}>
                <GridItem sm={12}>
                  <Field
                    name="Name"
                    type="text"
                    label="Name"
                    component={Input}
                    required
                    maxLength={200}
                  />
                </GridItem>
                <GridItem sm={12} md={6}>
                  <Field
                    name="Email"
                    type="text"
                    label="Email"
                    component={Input}
                    required
                    maxLength={200}
                  />
                </GridItem>
                <GridItem sm={12} md={6}>
                  <Field
                    name="Phone"
                    type="text"
                    label="Phone"
                    component={PhoneInput}
                    required
                    maxLength={200}
                  />
                </GridItem>
                <GridItem sm={12}>
                  <FeedbackLocationForm
                    values={values}
                    required={
                      {
                        //   County: true,
                        //   Constituency: true,
                        //   Ward: true,
                      }
                    }
                  />
                </GridItem>

                <GridItem sm={12}>
                  <Field
                    name="PhysicalAddress"
                    type="text"
                    label="Physical Address"
                    component={Input}
                  />
                </GridItem>
              </GridContainer>
              <GridItem sm={12}>
                <Field
                  name="Subject"
                  type="text"
                  label="Subject of Feedback"
                  component={Input}
                  required
                  maxLength={200}
                />
              </GridItem>
              <GridItem>
                <Field
                  name="Body"
                  type="text"
                  label="Description of Feedback"
                  component={TextArea}
                  rows={10}
                  required
                  maxLength={5000}
                />
              </GridItem>
              <GridItem>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset('')} />
                )}
              </GridItem>
            </GridContainer>
          </LayersManager>

          <Block
            row
            style={{
              justifyContent: 'stretch',
            }}
          >
            <Button
              contained
              color={colors.primary}
              disabled={submitting || pristine}
              onClick={handleSubmit}
              fullWidth
            >
              {`${submitting ? 'Submitting...' : 'Submit Feedback'}`}
            </Button>
          </Block>
        </Block>
      )}
    />
  );
};
export default FeedbackForm;
