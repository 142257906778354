import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Implementor from './Implementor';
import Validator from './Validator';
import Verifier from './Verifier';
import Stakeholder from './Stakeholder';
import Disseminator from './Disseminator';
import Secretariatmember from './Secretariatmember';

const StakeholderRoutes = (props) => {
  return (
    <Router style={{ flex: 1 }}>
      <Stakeholder path="/" />
      <Implementor path="implementors" />
      <Verifier path="verifiers" />
      <Validator path="validators" />
      <Disseminator path="disseminators" />
      <Secretariatmember path="secretariat" />
    </Router>
  );
};

export default StakeholderRoutes;
