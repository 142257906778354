import React from 'react';

import { Tab, Tabs, Block } from '@cloudhub-ux/core';

import { CompanyInfoForm, EmailsConfigForm } from './forms';
import SystemSettingsForm from './forms/SystemSettingsForm';
import { sizes } from 'theme';

function TabContainer(props) {
  return (
    <Block style={{ padding: 20 }} absolute>
      {props.children}
    </Block>
  );
}

const SystemSettings = (props) => {
  const [tabvalue, setTabValue] = React.useState(0);

  return (
    <Block padding={sizes.padding}>
      <Block flex={false}>
        <Tabs
          value={tabvalue}
          onChange={(e, tabvalue) => setTabValue(tabvalue)}
        >
          <Tab label="System Settings" />
          <Tab label="Organization Information" />
          <Tab label="Email Settings" />
        </Tabs>
      </Block>

      <Block>
        {tabvalue === 0 && (
          <TabContainer>
            <SystemSettingsForm />
          </TabContainer>
        )}
        {tabvalue === 1 && (
          <TabContainer>
            <CompanyInfoForm />
          </TabContainer>
        )}
        {tabvalue === 2 && (
          <TabContainer>
            <EmailsConfigForm />
          </TabContainer>
        )}
      </Block>
    </Block>
  );
};

export default SystemSettings;
