import React from 'react';
import CKEditor from '@cloudhub-ux/core/CKEditor';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
  DatePicker,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { StaticListSelector, RemoteSelector } from '@cloudhub-ux/core/baseweb';
import ScopeForm from '../../../localcomponents/ScopeForm';
import DialogLayer from 'localcomponents/DialogLayer';

const ProjectForm = (props) => {
  const { postData, error, reset, adminAxiosInstance } = useAdminAxios({});
  const addProject = async (programme) => {
    try {
      const data = await postData({
        url: `/actions/project/saveproject`,
        params: programme,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight={700}>
      <Form
        onSubmit={addProject}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).ProjectCode || 'Add Project'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <FieldBlock row wrap>
                  <Field
                    type="text"
                    label="Project Name"
                    required
                    name="ProjectName"
                    component={Input}
                    flex
                  />
                  {(editingRow || {}).id && (
                    <Field
                      type="text"
                      label="Project Code"
                      required
                      name="ProjectCode"
                      component={Input}
                      flex
                      readOnly
                    />
                  )}
                </FieldBlock>
                <FieldBlock row wrap margin={[sizes.baseMargin, 0]}>
                  <Field
                    name="Programme"
                    component={RemoteSelector}
                    axiosinstance={adminAxiosInstance}
                    url="/actions/programme/filtersearch"
                    label="Programme"
                    labelExtractor={(row) => (row || {}).ProgrammeName || ''}
                    valueExtractor={(row) =>
                      row
                        ? {
                            id: row.id,
                            _id: row._id,
                            ProgrammeName: row.ProgrammeName,
                            ProgrammeCode: row.ProgrammeCode,
                            Scope: row.Scope,
                          }
                        : row
                    }
                    flex
                  />
                  <Field
                    name="Scope"
                    label="Scope"
                    component={StaticListSelector}
                    options={[
                      'COUNTRY_WIDE',
                      'COUNTY',
                      'CONSTITUENCY',
                      'WARD',
                      'VILLAGE',
                    ]}
                    flex
                  />
                </FieldBlock>
                <ScopeForm values={values} />
                <FieldBlock row wrap margin={[sizes.baseMargin, 0]}>
                  <Field
                    name="StartDate"
                    component={DatePicker}
                    label="Start Date"
                    flex
                    required
                  />

                  <Field
                    name="EndDate"
                    component={DatePicker}
                    label="End Date"
                    flex
                    required
                  />
                  <Field
                    name="Status"
                    label="Status"
                    component={StaticListSelector}
                    options={[
                      'ACTIVE',
                      'COMPLETTED',
                      'ON_HOLD',
                      'CANCELED',
                      'ABANDONED',
                    ]}
                    flex
                  />
                </FieldBlock>
                <Field
                  type="text"
                  label="Description"
                  name="Description"
                  component={CKEditor}
                  height={300}
                />
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default ProjectForm;
