import React from 'react';
import {
  GridContainer,
  GridItem,
  Button,
  Block,
  TextArea,
  Input,
  DatePicker,
  Alert,
  toastr,
  Tab,
  Tabs,
  Container,
  Text,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';
import useAppAxios from 'app/context/useAppAxios';
import { makeStyles } from '@mui/styles';
import {
  StaticListSelector,
  LayersManager,
  RemoteSelector,
} from '@cloudhub-ux/core/baseweb';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import ScopeForm from 'localcomponents/ScopeForm';
import ActionSafeguards from './ActionSafeguards';
import Interventions from './Interventions';
import moment from 'moment';
import AuthContext from 'auth/context/AuthContext';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const NewAction = () => {
  const [tabvalue, setTabValue] = React.useState(0);
  const [initValues, setinitValues] = React.useState({});
  const [uploading, setuploading] = React.useState(false);
  const [saving, setsaving] = React.useState(false);
  const classes = useStyles();
  const { navigate, location } = useLocation();
  const { error, reset, postData, appAxiosInstance } = useAppAxios({});
  const { Implementor } = React.useContext(AuthContext);

  const saveAction = async (values) => {
    try {
      setsaving(true);
      const {
        ActionName,
        Scope,
        StartDate,
        EndDate,
        Description,
        County,
        Constituency,
        Ward,
        Village,
      } = values || {};
      if (!ActionName || !Scope || !StartDate || !EndDate || !Description) {
        toastr.error(
          'Error, Please fill in Name, scope, start date, end date and narration'
        );
        setsaving(false);
        return;
      }
      if (
        (['COUNTY', 'CONSTITUENCY', 'WARD', 'VILLAGE'].includes(Scope) &&
          !County) ||
        (['CONSTITUENCY', 'WARD', 'VILLAGE'].includes(Scope) &&
          !Constituency) ||
        (['WARD', 'VILLAGE'].includes(Scope) && !Ward) ||
        (Scope === 'VILLAGE' && !Village)
      ) {
        toastr.error('Error, Please fill in action location details');
        setsaving(false);
        return;
      }
      const data = await postData({
        url: '/app/actions/reddaction/saveaction',
        params: values,
      });
      if (data) {
        toastr.success(
          'Success, Action saved successfully. make sure to submit once done filling information for the action to proceed to validation'
        );
        reset();
      }
      setsaving(false);
    } catch (error) {
      setsaving(false);
    }
  };

  const submitAction = async (values) => {
    try {
      const { Safeguards } = values;
      const indicators = [];
      for (const safeguard in Safeguards || {}) {
        if (
          (Safeguards || {})[safeguard] &&
          typeof Safeguards[safeguard] === 'object'
        ) {
          for (const indicator in Safeguards[safeguard]) {
            if (Safeguards[safeguard][indicator]) {
              indicators.push(indicator);
            }
          }
        }
      }
      if (indicators.length === 0) {
        toastr.error(
          'Error, You must provide information for feedback indicators'
        );
        return;
      }
      const data = await postData({
        url: '/app/actions/reddaction/submitaction',
        params: {
          ...values,
          User: {
            ...(Implementor.User || {}),
            Institution: (Implementor.Stakeholder || {}).StakeholderName || '',
          },
        },
      });
      if (data) {
        toastr.success(
          'Success, Action submitted successfully and notifications sent to start verification'
        );
        reset();
        navigate('/actions/myactions');
      }
    } catch (error) {
      //
    }
  };

  React.useEffect(() => {
    if (location.state && location.state.action) {
      setinitValues(location.state.action);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(location.state)]);

  const getLocation = async (form) => {
    try {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          form.change('Coordinates', {
            Latitude: position.coords.latitude,
            Longitude: position.coords.longitude,
          });
          toastr.success('GPS coordinates recorded');
        });
      } else {
        toastr.error('geolocation IS NOT available');
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <GridContainer spacing={4}>
          <GridItem xs={12} md={12}>
            <Form
              onSubmit={submitAction}
              initialValues={{ ...initValues }}
              validateOnChange={false}
              render={({
                handleSubmit,
                pristine,
                submitting,
                form,
                values,
              }) => (
                <Block>
                  <Block flex={false}>
                    <Tabs
                      value={tabvalue}
                      onChange={(e, tabvalue) => {
                        if (!uploading) {
                          setTabValue(tabvalue);
                        }
                      }}
                    >
                      <Tab label="Action Information" />
                      <Tab label="Safeguards" />
                      {[
                        'SUBMITTED',
                        'VERIFIED',
                        'VALIDATED',
                        'REPORTED',
                      ].includes((values || {}).Status) && (
                        <Tab label="Follow Up" />
                      )}
                    </Tabs>
                  </Block>
                  <Block>
                    {tabvalue === 0 && (
                      <LayersManager zIndex={1}>
                        <GridContainer spacing={2}>
                          <GridContainer spacing={2}>
                            <GridItem sm={12}>
                              <Field
                                name="ActionName"
                                type="text"
                                label="REDD+ Action/PaM Name"
                                component={Input}
                                required
                                maxLength={200}
                              />
                            </GridItem>

                            <GridItem sm={12} md={4}>
                              <Field
                                name="Programme"
                                label="Programme"
                                component={RemoteSelector}
                                axiosinstance={appAxiosInstance}
                                url="/app/actions/programme/filtersearch"
                                valueExtractor={(row) =>
                                  row
                                    ? {
                                        id: row.id,
                                        _id: row._id,
                                        ProgrammeName: row.ProgrammeName,
                                        ProgrammeCode: row.ProgrammeCode,
                                      }
                                    : row
                                }
                                labelExtractor={(row) =>
                                  (row || {}).ProgrammeName || ''
                                }
                                keyExtractor={(row) => (row || {}).id}
                                flex
                              />
                            </GridItem>
                            <GridItem sm={12} md={4}>
                              <Field
                                name="Project"
                                label="Project"
                                component={RemoteSelector}
                                axiosinstance={appAxiosInstance}
                                url="/app/actions/project/filtersearch"
                                valueExtractor={(row) =>
                                  row
                                    ? {
                                        id: row.id,
                                        _id: row._id,
                                        ProjectName: row.ProjectName,
                                        ProjectCode: row.ProjectCode,
                                      }
                                    : row
                                }
                                labelExtractor={(row) =>
                                  (row || {}).ProjectName || ''
                                }
                                keyExtractor={(row) => (row || {}).id}
                                params={
                                  ((values || {}).Programme || {}).id
                                    ? {
                                        'Programme.id': (
                                          (values || {}).Programme || {}
                                        ).id,
                                      }
                                    : {}
                                }
                                flex
                              />
                            </GridItem>
                            <GridItem sm={12} md={4}>
                              <Field
                                name="Scope"
                                label="Scope"
                                component={StaticListSelector}
                                options={[
                                  'COUNTRY_WIDE',
                                  'COUNTY',
                                  'CONSTITUENCY',
                                  'WARD',
                                  'VILLAGE',
                                ]}
                                required
                                flex
                              />
                            </GridItem>
                            <GridItem sm={12}>
                              <ScopeForm values={values} />
                            </GridItem>
                            <GridItem xs={12}>
                              <Block middle center>
                                <Button onClick={() => getLocation(form)}>
                                  Collect GPS Coordinates
                                </Button>
                                {(values || {}).Coordinates &&
                                  values.Coordinates.Longitude && (
                                    <Text h6 primary>
                                      Longitude: {values.Coordinates.Longitude}{' '}
                                      Latitude:{values.Coordinates.Latitude}
                                    </Text>
                                  )}
                              </Block>
                            </GridItem>
                            <GridItem sm={12} md={4}>
                              <Field
                                name="StartDate"
                                label="Start Date of Action"
                                component={DatePicker}
                                required
                              />
                            </GridItem>
                            <GridItem sm={12} md={4}>
                              <Field
                                name="EndDate"
                                label="End Date of Action"
                                component={DatePicker}
                                required
                                flex
                                filterDate={(date) =>
                                  moment(date).isBefore(
                                    moment((values || {}).StartDate)
                                  )
                                }
                              />
                            </GridItem>
                            <GridItem sm={12} md={4}>
                              <Field
                                name="ActionStatus"
                                label="ActionStatus"
                                component={StaticListSelector}
                                options={[
                                  'ACTIVE',
                                  'COMPLETTED',
                                  'ON_HOLD',
                                  'CANCELED',
                                  'ABANDONED',
                                ]}
                                required
                                flex
                              />
                            </GridItem>
                          </GridContainer>

                          <GridItem>
                            <Field
                              name="Description"
                              type="text"
                              label="Description of Action"
                              component={TextArea}
                              rows={10}
                              required
                              maxLength={5000}
                            />
                          </GridItem>
                        </GridContainer>
                      </LayersManager>
                    )}
                    {tabvalue === 1 && (
                      <ActionSafeguards
                        values={values}
                        form={form}
                        uploading={uploading}
                        setuploading={setuploading}
                      />
                    )}
                    {tabvalue === 2 && (
                      <Interventions ActionId={(values || {}).id} />
                    )}
                    {Boolean(error) && (
                      <Alert error message={error} onClose={() => reset('')} />
                    )}
                  </Block>
                  {(values || {}).Status === 'NOT_SUBMITTED' && (
                    <Block
                      row
                      style={{
                        justifyContent: 'stretch',
                      }}
                    >
                      <Button
                        contained
                        color={colors.secondary}
                        disabled={submitting || pristine || uploading || saving}
                        onClick={() => saveAction(values)}
                        fullWidth
                      >
                        {`${saving ? 'Saving...' : ''}${
                          uploading ? '...uploading files' : ''
                        }  ${
                          !submitting && !uploading && !saving ? 'Save' : ''
                        }`}
                      </Button>

                      <Button
                        contained
                        color={colors.primary}
                        disabled={submitting || pristine || uploading || saving}
                        onClick={handleSubmit}
                        fullWidth
                      >
                        {`${saving ? 'Submitting...' : ''}${
                          uploading ? '...uploading files' : ''
                        }  ${
                          !submitting && !uploading && !saving
                            ? 'Submit Action'
                            : ''
                        }`}
                      </Button>
                    </Block>
                  )}
                </Block>
              )}
            />
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
};

export default NewAction;
