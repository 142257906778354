import React from 'react';
import { sizes, colors, Images } from 'theme';
import {
  Container,
  Block,
  Text,
  GridContainer,
  GridItem,
} from '@cloudhub-ux/core';
import { Link } from '@cloudhub-ux/core/reach';
import CustomerSignupForm from './CustomerSignUpForm';
const AppSignIn = (props) => {
  return (
    <Block center padding={[sizes.padding, 0]}>
      <Container maxWidth="lg">
        <Block paper color={colors.gray4} flex={false} padding={sizes.padding}>
          <GridContainer style={{ display: 'flex', alignItems: 'center' }}>
            <GridItem sm={12} md={6} style={{}}>
              <Block center middle>
                <img
                  alt="REDDSIS Kenya"
                  style={{
                    width: 400,
                  }}
                  src={Images.logoicon}
                />
                <Text h3 center>
                  Kenya REDD+ SIS
                </Text>
              </Block>
            </GridItem>
            <GridItem sm={12} md={6}>
              <Block>
                <CustomerSignupForm />
                <Block flex={false} row center>
                  <Link to="/auth/signup">
                    <Text accent>
                      Already Registered? <strong>Signin</strong>
                    </Text>
                  </Link>
                </Block>
              </Block>
            </GridItem>
          </GridContainer>
        </Block>
      </Container>
    </Block>
  );
};

export default AppSignIn;
