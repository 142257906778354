import React from 'react';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
  DatePicker,
  TextArea,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import ImagesUpload from '@cloudhub-ux/core/uploaders/ImagesUpload';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import DialogLayer from 'localcomponents/DialogLayer';

const SecretariatmemberForm = (props) => {
  const { postData, error, reset } = useAdminAxios({});

  const addSecretariatmember = async (values) => {
    try {
      const data = await postData({
        url: `/stakeholders/secretariatmember/savesecretariatmember`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="80vh" maxWidth="lg">
      <Form
        onSubmit={addSecretariatmember}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).SecretariatmemberName ||
                  'Add Secretariat Member'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block padding={sizes.padding}>
                <FieldBlock row wrap margin={[sizes.baseMargin, 0]}>
                  <Field
                    type="text"
                    label="Name"
                    required
                    name="Name"
                    component={Input}
                    flex
                  />
                  <Field
                    type="text"
                    label="Secretariat Role/Position"
                    required
                    name="Position"
                    component={Input}
                    flex
                  />
                </FieldBlock>
                <FieldBlock row wrap margin={[sizes.baseMargin, 0]}>
                  <Field
                    type="text"
                    name="Tenure.Start"
                    required
                    label="Date Appointed"
                    component={DatePicker}
                    flex
                  />
                  <Field
                    type="text"
                    label="Appointed Untill"
                    required
                    name="Tenure.End"
                    component={DatePicker}
                    flex
                  />
                </FieldBlock>
                <FieldBlock row wrap margin={[sizes.baseMargin, 0]}>
                  <Field
                    type="text"
                    name="Organization"
                    required
                    label="Organization"
                    component={Input}
                    flex
                  />
                  <Field
                    type="text"
                    label="Role in Organization"
                    required
                    name="Designation"
                    component={Input}
                    flex
                  />
                </FieldBlock>
                <FieldBlock row wrap top>
                  <Field
                    type="text"
                    label="Picture"
                    name="Picture"
                    url={`/api/media/upload/image`}
                    component={ImagesUpload}
                  />
                  <Field
                    type="text"
                    label="Bio"
                    name="Bio"
                    component={TextArea}
                    flex
                  />
                </FieldBlock>
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default SecretariatmemberForm;
