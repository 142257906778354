import { SET_PARTNERS, SET_TRACKING_GRIEVANCE } from './grievanceActions';

export const INITIAL_STATE = {
  TrackingGrievance: {},
  Partners: [],
};

const grievanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PARTNERS: {
      return {
        ...state,
        Partners: action.payload,
      };
    }
    case SET_TRACKING_GRIEVANCE: {
      return {
        ...state,
        TrackingGrievance: { ...action.payload },
      };
    }
    default:
      return state;
  }
};
export default grievanceReducer;
