import React from 'react';

const INITIAL_STATE = {
  Safeguards: [],
};

export const safeguardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_SAFEGUARDS': {
      return {
        ...state,
        Safeguards: action.payload,
      };
    }

    default:
      return state;
  }
};

const useSafeguards = ({ axiosinstance }) => {
  const [state, dispatch] = React.useReducer(safeguardsReducer, INITIAL_STATE);

  const getSafeguards = async () => {
    try {
      const { data } = await axiosinstance().get(
        `/app/safeguards/safeguard/getall`
      );

      if (data && Array.isArray(data.items)) {
        dispatch({
          type: 'SET_SAFEGUARDS',
          payload: data.items,
        });
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getSafeguards();
    // eslint-disable-next-line
  }, []);
  return {
    getSafeguards,
    ...state,
  };
};

export default useSafeguards;
