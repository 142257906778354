import React from 'react';
import {
  Block,
  Text,
  Input,
  FieldBlock,
  ListMenuItem,
  IconButton,
  ListMenu,
  Alert,
  Button,
  TextArea,
} from '@cloudhub-ux/core';
import Pagination from '@mui/material/Pagination';
import { sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { VisibilityOutlined } from '@mui/icons-material';
import { Form, Field } from '@cloudhub-ux/core/form';
import { StaticListSelector, LayersManager } from '@cloudhub-ux/core/baseweb';
import InterventionEvents from './InterventionEvents';

const Interventions = ({ ActionId }) => {
  const [interventions, setinterventions] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [totalCount, settotalCount] = React.useState(0);
  const [editing, setediting] = React.useState({});

  const { getData, error, reset } = useAdminAxios({});

  const getInterventions = React.useCallback(async () => {
    try {
      if (ActionId) {
        const data = await getData({
          url: `/actions/intervention/actioninterventions`,
          params: { ActionId: ActionId, skip: page * 20, limit: 20 },
        });
        if (data && Array.isArray(data.items)) {
          setinterventions(data.items);
          settotalCount(data.totalCount);
        }
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [ActionId, page]);

  React.useEffect(() => {
    getInterventions();
  }, [getInterventions]);

  return (
    <Block row wrap>
      {interventions.length > 0 && (
        <Block flex={false}>
          <ListMenu>
            {interventions.map((intervention) => (
              <ListMenuItem
                divider
                button={false}
                key={intervention.id}
                primary={
                  <Block row padding={[0, 75, 0, 0]}>
                    <Text h6 bold>
                      {intervention.Name}
                    </Text>
                    <IconButton
                      onClick={() => {
                        setediting(intervention);
                      }}
                    >
                      <VisibilityOutlined color="primary" />
                    </IconButton>
                  </Block>
                }
                secondary={`${intervention.InterventionType} - ${intervention.InterventionCode}`}
              />
            ))}
          </ListMenu>
          {totalCount > 20 && (
            <Block center flex={false} margin={[sizes.doubleBaseMargin, 0]}>
              <Pagination
                classes={{}}
                count={totalCount}
                rowsPerPage={20}
                onChange={(event, current) => setPage(current)}
              />
            </Block>
          )}
        </Block>
      )}
      <Form
        onSubmit={() => {}}
        initialValues={editing || {}}
        render={({ handleSubmit, values, pristine, submittting }) => (
          <Block>
            <Button
              style={{ alignSelf: 'flex-end' }}
              onClick={() => setediting({})}
            >
              <Text error> Clear</Text>
            </Button>
            <LayersManager zIndex={1}>
              <FieldBlock row wrap>
                <Field
                  name="InterventionType"
                  label="Type of Followup"
                  component={StaticListSelector}
                  options={['Request for Information', 'Request for Action']}
                  flex
                />
                {(editing || {}).InterventionCode && (
                  <Field
                    name="InterventionCode"
                    label="Intervention Code"
                    component={Input}
                    flex
                  />
                )}
              </FieldBlock>
              <Field
                name="Description"
                label="Describe the Followup"
                component={TextArea}
              />
              <FieldBlock paper>
                {Array.isArray((values || {}).Files) &&
                  values.Files.map(
                    (file) =>
                      file &&
                      file.Location && (
                        <a
                          href={file.Location}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {file.filename}
                        </a>
                      )
                  )}
              </FieldBlock>
              {Array.isArray((editing || {}).Events) && (editing || {}).id && (
                <InterventionEvents
                  InterventionId={editing.id}
                  Events={editing.Events}
                  onUpdate={(val) => {
                    setinterventions((current) =>
                      (current || []).map((item) => {
                        if (item.id === editing.id) {
                          return { ...item, Events: val };
                        }
                        return item;
                      })
                    );
                    setediting(val);
                  }}
                />
              )}
            </LayersManager>
            {Boolean(error) && <Alert error message={error} onClose={reset} />}
          </Block>
        )}
      />
    </Block>
  );
};
export default Interventions;
