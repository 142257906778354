import React from 'react';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import FaqsHero from './UsgHero';
import useAppAxios from 'app/context/useAppAxios';

const RootStyle = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(8),
  // [theme.breakpoints.up('md')]: {
  //   paddingTop: theme.spacing(11),
  // },
}));

export default function UserGuide() {
  const [guide, setguide] = React.useState({});
  const { getData } = useAppAxios();
  const getUserGuide = async () => {
    try {
      const data = await getData({
        url: '/app/website/documents/userguide',
      });
      if (data && data.id) {
        setguide(data);
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getUserGuide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RootStyle title="Faqs | Kenya REDD+">
      <FaqsHero />
      <Container sx={{ mt: 15, mb: 10, height: '100vh' }}>
        {((guide || {}).File || {}).Location && (
          <iframe
            title={guide.Title || 'User Guide'}
            src={guide.File.Location}
            // src="https://www.undp.org/sites/g/files/zskgke326/files/2021-12/UNDP-ODI-An-Analytical-Review-A-Decade-of-Urban-Resilience.pdf"
            width="100%"
            height="100%"
          />
        )}
      </Container>
    </RootStyle>
  );
}
