import React from 'react';
import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from '@cloudhub-ux/core/reach';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Hidden,
} from '@mui/material';
import SvgIconStyle from 'app/static/SvgIconStyle';
import { Images } from 'theme';
import LocationOn from '@mui/icons-material/LocationOn';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const TitleStyle = styled(RouterLink)(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 44,
  color: 'inherit',
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

InstitutionCard.propTypes = {
  sttakeholder: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function InstitutionCard({ stakeholder, index }) {
  const {
    StakeholderName,
    StakeholderType,
    OperatingScope,
    County,
    Constituency,
    Ward,
    Village,
    Logo,
    Description,
    Address,
  } = stakeholder || {};
  const { PhysicalAddress, PostAddress, Location, Email, Phone, Website } =
    Address || {};
  return (
    <Grid item xs={12}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle
          sx={{
            '&:after': {
              top: 0,
              content: "''",
              width: '100%',
              height: '100%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
            pt: {
              xs: 'calc(100% * 4 / 3)',
              sm: 'calc(100% * 3 / 4.66)',
            },
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              display: 'none',
            }}
          />
          <AvatarStyle
            alt="logo"
            src={(Logo || {}).Location || Images.landing}
            sx={{
              zIndex: 9,
              top: 24,
              left: 24,
              width: 40,
              height: 40,
            }}
          />

          <CoverImgStyle alt={StakeholderName} src={Images.landingbg} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
            bottom: 0,
            width: '100%',
            position: 'absolute',
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            {StakeholderType}
          </Typography>

          <TitleStyle
            to={`/stakeholders/institutions/${paramCase(StakeholderName)}`}
            sx={{ typography: 'h5', height: 60, color: 'common.white' }}
          >
            {StakeholderName}
          </TitleStyle>
          <Typography
            variant="h6"
            color="common.white"
            dangerouslySetInnerHTML={{ __html: Description }}
            sx={{ maxHeight: '150px', overflow: 'auto' }}
          />
          <Hidden mdDown>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                textAlign: 'right',
              }}
            >
              <Typography
                variant="body2"
                paragraph
                sx={{ color: 'common.white' }}
              >
                {PhysicalAddress}
                <br />
                {PostAddress}
                <br />
                {Location}
              </Typography>
              <Typography variant="body2" sx={{ color: 'common.white' }}>
                {Email}
                <br /> {Phone}
                <br />
                {Website}
              </Typography>
            </Box>
          </Hidden>
          <InfoStyle>
            {['COUNTRY_WIDE'].includes(OperatingScope) && (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                  color: 'grey.500',
                }}
              >
                <Box
                  component={LocationOn}
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
                <Typography variant="caption">
                  Country Wide Operations
                </Typography>
              </Box>
            )}
            {['COUNTY', 'CONSTITUENCY', 'WARD', 'VILLAGE'].includes(
              OperatingScope
            ) && (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                  color: 'grey.500',
                }}
              >
                <Box
                  component={LocationOn}
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
                <Typography variant="caption">
                  {(County || {}).Name || ''}
                </Typography>
              </Box>
            )}
            {['CONSTITUENCY', 'WARD', 'VILLAGE'].includes(OperatingScope) && (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                  color: 'grey.500',
                }}
              >
                <Box
                  component={FiberManualRecord}
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
                <Typography variant="caption">
                  {(Constituency || {}).Name || ''}
                </Typography>
              </Box>
            )}
            {['WARD', 'VILLAGE'].includes(OperatingScope) && (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                  color: 'grey.500',
                }}
              >
                <Box
                  component={FiberManualRecord}
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
                <Typography variant="caption">
                  {(Ward || {}).Name || ''}
                </Typography>
              </Box>
            )}
            {['VILLAGE'].includes(OperatingScope) && (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: index === 0 ? 0 : 1.5,
                  color: 'grey.500',
                }}
              >
                <Box
                  component={FiberManualRecord}
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
                <Typography variant="caption">{Village || ''}</Typography>
              </Box>
            )}
          </InfoStyle>
        </CardContent>
      </Card>
    </Grid>
  );
}
