import React from 'react';
import AuthContext from 'auth/context/AuthContext';
import SocketContext from './SockectContext';
import useSailsIo from './useSailsIo';

const SocketProvider = ({ children }) => {
  const { currentUser } = React.useContext(AuthContext);
  const sockets = useSailsIo({
    headers: {
      userid: currentUser.id,
      username: currentUser.Name,
      phone: currentUser.Phone,
      email: currentUser.Email,
      usertype: currentUser.UserType,
    },
    // requiredHeaderProperty: 'userid',
  });

  return (
    <SocketContext.Provider
      value={{
        ...sockets,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
