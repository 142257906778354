import React from 'react';
import CKEditor from '@cloudhub-ux/core/CKEditor';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import DialogLayer from 'localcomponents/DialogLayer';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import VerificationContext from 'actionverification/context/VerificationContext';
import { RemoteSelector } from '@cloudhub-ux/core/baseweb';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';

const GrievancereportForm = (props) => {
  const {
    VerificationState: { Grievancehandler },
  } = React.useContext(VerificationContext);
  const {
    postData,
    error,
    reset,
    verificationAxiosInstance,
  } = useVerificationAxios({});

  const addGrievancereport = async (values) => {
    try {
      const data = await postData({
        url: `/grievancereport/savegrievancereport`,
        params: {
          ...values,
          ...((Grievancehandler || {}).id &&
            !(editingRow || {}).id && {
              Author: {
                Name: (Grievancehandler.User || {}).Name,
                id: (Grievancehandler.User || {}).id,
                Organization: (Grievancehandler.Stakeholder || {})
                  .StakeholderName,
              },
            }),
        },
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="75%">
      <Form
        onSubmit={addGrievancereport}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).DocumentNo || 'Add Report'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <Field
                  type="text"
                  label="Title"
                  required
                  name="Title"
                  component={Input}
                />

                <FieldBlock
                  padding={[sizes.padding, 0]}
                  margin={[sizes.doubleBaseMargin, 0, 0, 0]}
                >
                  <Field
                    name="Grievances"
                    component={RemoteSelector}
                    axiosinstance={verificationAxiosInstance}
                    url="/grievance/filtersearch"
                    labelExtractor={(row) =>
                      `${(row || {}).GrievanceCode} - ${(row || {}).Subject}`
                    }
                    valueExtractor={(row) =>
                      row
                        ? {
                            id: row.id,
                            _id: row._id,
                            GrievanceCode: row.GrievanceCode,
                            Subject: row.Subject,
                          }
                        : row
                    }
                    keyExtractor={(row) => (row || {}).id || row}
                    isMulti
                  />
                </FieldBlock>
                {(editingRow || {}).id && (
                  <FieldBlock
                    row
                    wrap
                    margin={[sizes.doubleBaseMargin * 1.5, 0]}
                  >
                    <Field
                      type="text"
                      label="Author Name"
                      required
                      name="Author.Name"
                      component={Input}
                      flex
                      readOnly
                    />
                    <Field
                      type="text"
                      label="Author's Organizations"
                      required
                      name="Author.Organization"
                      component={Input}
                      flex
                      readOnly
                    />
                  </FieldBlock>
                )}
                <Field
                  type="text"
                  label="Description"
                  name="Description"
                  component={CKEditor}
                  height={400}
                  width={500}
                />
                <Field
                  type="text"
                  label="Upload Document"
                  name="File"
                  url={`/api/media/upload/file`}
                  // axiosinstance={axiosinstance}
                  component={FilesUpload}
                />
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default GrievancereportForm;
