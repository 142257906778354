import React, { useEffect, useState } from 'react';
import { Menu as MenuIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { Block, IconButton, Button } from '@cloudhub-ux/core';
import {
  colors,
  // Images,
  sizes,
} from 'theme';
import Typography from '@mui/material/Typography';
import AccountButton from 'auth/AccountButton';

import DrawerMenu from './menu/DrawerMenu';
import Drawer from './menu/Drawer';
import { useMetrics } from '@cloudhub-ux/core/customhooks';
import { Link } from '@cloudhub-ux/core/reach';
const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  appFrame: {
    marginLeft: -drawerWidth,
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: 'white', // theme.palette.background.default,
  },
  appBar: {
    position: 'absolute',

    height: sizes.adminNavBarHeight,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: sizes.margin,
    marginRight: sizes.margin,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    heigh: '100%',
    overflow: 'hidden',
    width: drawerWidth,
    borderWidth: 0,
  },
  drawerInner: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
  },

  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const VerificationHome = ({ children, ...props }) => {
  const classes = useStyles();
  const { height } = useMetrics();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const renderMenuIcon = () => {
    return (
      <Block flex={false} style={{ width: drawerWidth }} row>
        <Block flex={false}>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={classNames(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon style={{ color: colors.dark }} />
          </IconButton>
        </Block>
        <Block style={{ height: '100%' }}>
          {/* <img
            src={Images.logo}
            alt="logo"
            style={{ width: '100%', objectFit: 'contain' }}
          /> */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 1 }}>
            KENYA REDD+ SIS
          </Typography>
        </Block>
      </Block>
    );
  };

  return (
    <Block row>
      <Block
        flex={false}
        style={{
          position: 'relative',

          borderColor: colors.gray,

          maxHeight: height - 1,
        }}
      >
        <Drawer open={open} onClose={() => setOpen(false)}>
          <DrawerMenu />
        </Drawer>
      </Block>
      <Block
        className={classNames(classes.appFrame, open && classes.contentShift)}
      >
        <Block
          row
          middle
          shadow
          color={colors.mistyWhite}
          flex={false}
          style={{ height: sizes.adminNavBarHeight, zIndex: 9 }}
        >
          {!open && renderMenuIcon()}
          <Link to="/">
            <Button outlined style={{ margin: `0 ${sizes.baseMargin}px` }}>
              Home
            </Button>
          </Link>
          <Block right row>
            <AccountButton
              userinterface="verification"
              color={colors.primary}
            />
          </Block>
        </Block>
        <Block
          style={{
            minHeight: height - sizes.adminNavBarHeight,
            maxHeight: height - sizes.adminNavBarHeight,
            position: 'relative',
          }}
          row
        >
          {!open && <Block flex={false} shadow style={{ width: 10 }}></Block>}
          <Block style={{ position: 'relative' }}>
            <Block
              absolute
              style={{
                overflowY: 'auto',
              }}
            >
              {children}
            </Block>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default VerificationHome;
