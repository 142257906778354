import React from 'react';
import { ListMenu, ListSubMenu, ListMenuItem } from '@cloudhub-ux/core';
import { Link } from '@cloudhub-ux/core/reach';

import { Home as HomeIcon, Verified } from '@mui/icons-material';
import { colors } from 'theme';
import VerificationContext from 'actionverification/context/VerificationContext';

const DrawerMenu = () => {
  const {
    VerificationState: {
      Validator,
      Verifier,
      Grievancehandler,
      Aggregator,
      Disseminator,
    },
  } = React.useContext(VerificationContext);
  return (
    <ListMenu>
      <ListSubMenu
        expandedStyles={{ backgroundColor: colors.gray2 }}
        header="Main Menu"
        expanded
      >
        <Link to="/actionverification">
          <ListMenuItem
            icon={<HomeIcon style={{ height: 14 }} />}
            primary="Dashboard"
          />
        </Link>
      </ListSubMenu>

      {(Verifier || {}).id && (
        <Link to="/actionverification/verifications">
          <ListMenuItem
            icon={<Verified style={{ height: 14, color: colors.success }} />}
            primary="Verifications"
          />
        </Link>
      )}
      {(Validator || {}).id && (
        <Link to="/actionverification/validations">
          <ListMenuItem
            icon={<Verified style={{ height: 14, color: colors.success }} />}
            primary="Validations"
          />
        </Link>
      )}
      {(Grievancehandler || {}).id && (
        <Link to="/actionverification/grievances">
          <ListMenuItem
            icon={<Verified style={{ height: 14, color: colors.success }} />}
            primary="Grievances"
          />
        </Link>
      )}
      {((Grievancehandler || {}).id || (Aggregator || {}).id) && (
        <Link to="/actionverification/grievancereports">
          <ListMenuItem
            icon={<Verified style={{ height: 14, color: colors.success }} />}
            primary="Grievance Reports"
          />
        </Link>
      )}
      {(Aggregator || {}).id && (
        <Link to="/actionverification/actions">
          <ListMenuItem
            icon={<Verified style={{ height: 14, color: colors.success }} />}
            primary="Actions"
          />
        </Link>
      )}
      {((Aggregator || {}).id || (Disseminator || {}).id) && (
        <Link to="/actionverification/infopages">
          <ListMenuItem
            icon={<Verified style={{ height: 14, color: colors.success }} />}
            primary="Articles"
          />
        </Link>
      )}
      {((Aggregator || {}).id || (Disseminator || {}).id) && (
        <Link to="/actionverification/documents">
          <ListMenuItem
            icon={<Verified style={{ height: 14, color: colors.success }} />}
            primary="Documents"
          />
        </Link>
      )}
    </ListMenu>
  );
};

export default DrawerMenu;
