import React from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink } from '@cloudhub-ux/core/reach';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import { MotionContainer, varBounceIn } from '@cloudhub-ux/min/theme/animate';
import { UploadIllustration } from 'assets/illustrations';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function GrievanceSubmitted({ Grievance }) {
  return (
    <RootStyle title="404 Page Not Found | Minimal-UI">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Your Grievance was Successfullt Submitted
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Your Grievance was successfully submitted. Use code{' '}
              <strong
                style={{
                  fontSize: 18,
                }}
              >
                {(Grievance || {}).GrievanceCode || ''}
              </strong>{' '}
              and the password you set to track your grievance. You may be
              requested to submit further information to aid the resoluttion of
              this grievance. Check regularly and respond promptly to aid in
              reaching an outcome
            </Typography>

            <motion.div variants={varBounceIn}>
              <UploadIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </motion.div>

            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Go to Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
