import React, { Component } from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import { Block } from '@cloudhub-ux/core';

import Signout from './Signout';

import Signin from './Signin';
import Signup from './Signup';
import ResetPassword from './ResetPassword';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import Profile from './Profile';

class AccountRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Block row padding={[20, 0]}>
        <Router
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          <Signin path="signin" />
          <Signout path="signout" />
          <Signup path="signup" />
          <ResetPassword path="resetpassword" />
          <Profile path="profile" />
          <Profile path="account" />
          <ResetPasswordSuccess path="resetpasswordsuccess" />
        </Router>
      </Block>
    );
  }
}

export default AccountRoutes;
