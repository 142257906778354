import React from 'react';
import { GridContainer, GridItem, Container } from '@cloudhub-ux/core';
import { makeStyles } from '@mui/styles';
import FeedbackForm from './FeedbackForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const FeedbackPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <GridContainer spacing={4}>
          <GridItem xs={12} md={12}>
            <FeedbackForm />
          </GridItem>
        </GridContainer>
      </Container>
    </div>
  );
};

export default FeedbackPage;
