import React from 'react';
import Home from './Home';
import RoutesContainer from './AppRoutes';
import AppProvider from './context/AppProvider';

const WebsiteApp = () => (
  <AppProvider>
    <Home default>
      <RoutesContainer />
    </Home>
  </AppProvider>
);

export default WebsiteApp;
