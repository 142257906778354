import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Dashboard from './dashboard/Dashboard';
import SettingsRoutes from './settings/SettingsRoutes';
import ActionRoutes from './actions/ActionRoutes';
import SafeguardRoutes from './safeguards/SafeguardRoutes';
import StakeholderRoutes from './stakeholders/StakeholderRoutes';
import FgrmRoutes from './fgrm/FgrmRoutes';
import WebsiteRoutes from './website/WebsiteRoutes';

const AdminRoutes = () => (
  <Router
    style={{
      display: 'flex',
      flex: 1,
    }}
  >
    <Dashboard default path="dashboard" />
    <SettingsRoutes path="settings/*" />
    <ActionRoutes path="actions/*" />
    <SafeguardRoutes path="safeguards/*" />
    <StakeholderRoutes path="stakeholders/*" />
    <FgrmRoutes path="/fgrm/*" />
    <WebsiteRoutes path="/website/*" />
  </Router>
);

export default AdminRoutes;
