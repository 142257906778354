/* eslint-disable capitalized-comments */
import React from 'react';
import { TableCell } from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import { Block, Alert, toastr } from '@cloudhub-ux/core';

import InfopageForm from './forms/InfopageForm';
import useVerificattionAxios from 'actionverification/context/useVerificationAxios';

// const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Infopage = () => {
  const { postData, verificationAxiosInstance } = useVerificattionAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const deleteRows = async (deletingRows) => {
    try {
      const ids = deletingRows.map((item) => item.id);
      const data = await postData({
        url: `/infopage/delete`,
        params: { ids },
      });
      if (data) {
        toastr.success('PLRs successfulyy Deleted');
        gridRef.current.reload();
      }
    } catch (error) {}
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [{ name: 'Title' }, { name: 'Section' }, { name: 'Rank' }];

  const columnWidths = [
    { columnName: 'Title', width: 120 },
    { columnName: 'Section', width: 150 },
    { columnName: 'Rank', width: 50 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>

      <RemoteDataGrid
        title="Information Pages and Posts"
        url="/safeguards/infopage/getall"
        axiosinstance={verificationAxiosInstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
      />
      <InfopageForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Infopage;
