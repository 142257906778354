import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import ContactsHero from './ContactsHero';
import FeedbackForm from 'app/fgrm/FeedbackForm';

const RootStyle = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(8),
  // [theme.breakpoints.up('md')]: {
  //   paddingTop: theme.spacing(11),
  // },
}));

export default function ContactUs() {
  return (
    <RootStyle title="Faqs | Kenya REDD+">
      <ContactsHero />

      <Container sx={{ mt: 15, mb: 10 }}>
        <Typography variant="h3" sx={{ mb: 5 }}>
          Feel free to send us your questions or feedback
        </Typography>

        <FeedbackForm />
      </Container>
    </RootStyle>
  );
}
