import React from 'react';
import { IconButton, Button, Input, Text, Block } from '@cloudhub-ux/core';
import Refresh from '@mui/icons-material/Refresh';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { sizes } from 'theme';
import { Field, Form, FormSpy } from '@cloudhub-ux/core/form';
import { useDebounce, useLocation } from '@cloudhub-ux/core/customhooks';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import CheckBox from '@cloudhub-ux/core/CheckBox';
const GridHeader = ({
  header,
  permissions,
  onSearch,
  queryString,
  Status,
  setStatus,
  headers,
  exporting,
  Accurate,
  Compliant,
  setAccurate,
  setCompliant,
  ...props
}) => {
  const [searchTerm, setsearchTerm] = React.useState('');
  const debouncedSearch = useDebounce(searchTerm, 1500);
  const { location } = useLocation();

  React.useEffect(() => {
    if (typeof debouncedSearch === 'string') {
      onSearch(debouncedSearch);
    }
  }, [onSearch, debouncedSearch]);

  if (header) {
    return (
      <Block flex={false}>
        {header({
          ...props,
          ...permissions,
          queryString,
          onSearch,
        })}
      </Block>
    );
  }

  return (
    <Block paper flex={false} row space="between" padding={sizes.padding}>
      <Text h4 bold>
        {props.title}
      </Text>

      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Stack spacing={1} direction="column">
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={Status}
              onChange={(event) => setStatus(event.target.value)}
              row
            >
              <FormControlLabel
                value="PENDING_STAKEHOLDER_RESPONSE"
                control={<Radio />}
                label="Pending Response"
              />
              <FormControlLabel
                value="NOT_SUBMITTED"
                control={<Radio />}
                label="Not Submitted"
              />
              <FormControlLabel
                value="SUBMITTED"
                control={<Radio />}
                label="Submitted"
              />

              <FormControlLabel
                value="OVERDUE"
                control={<Radio />}
                label="Overdue"
              />
            </RadioGroup>
          </FormControl>
          <CheckBox
            height={sizes.icons.medium}
            value={
              location.pathname.includes('verification') ? Accurate : Compliant
            }
            onChange={(e) => {
              if (location.pathname.includes('verification')) {
                setAccurate(e);
              } else {
                setCompliant(e);
              }
            }}
            tag={
              <Text>
                {location.pathname.includes('verification')
                  ? 'Accurate and Good Quality'
                  : 'Addresses and Respects Safeguards'}
              </Text>
            }
          />
        </Stack>
        <Form
          onSubmit={() => {}}
          render={() => (
            <React.Fragment>
              <Field name="search" value={searchTerm} component={Input} />
              <FormSpy
                subscriptions={{ values: true }}
                onChange={({ values }) => {
                  if (typeof (values || {}).search === 'string') {
                    setTimeout(() => {
                      setsearchTerm(values.search);
                    });
                  }
                }}
              />
            </React.Fragment>
          )}
        />

        <IconButton onClick={props.onRefresh}>
          <Refresh />
        </IconButton>
        <CSVLink
          data={exporting}
          headers={headers}
          filename={
            location.pathname.includes('verification')
              ? 'Verifications'
              : 'Validations'
          }
        >
          <Button>
            <ListAltIcon /> Export to Excel
          </Button>
        </CSVLink>
      </Stack>
    </Block>
  );
};

export default GridHeader;
