import React from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
} from '@mui/material';
import {
  Button,
  IconButton,
  Block,
  Text,
  Dialog,
  toastr,
  Alert,
} from '@cloudhub-ux/core';
import { DialogActions } from '@cloudhub-ux/core/dialog';
import { navigate } from '@cloudhub-ux/core/reach';
import { colors, sizes } from 'theme';
import { Delete, Edit } from '@mui/icons-material';
import useAppAxios from 'app/context/useAppAxios';

const MyActions = () => {
  const [deleting, setdeleting] = React.useState(null);
  const [myactions, setmyactions] = React.useState([]);

  const { postData, getData, error, reset } = useAppAxios({});

  const onEditAction = (row) => {
    navigate('/actions/new', { state: { action: row } });
  };
  const getmyActions = async () => {
    try {
      const data = await getData({
        url: '/app/actions/reddaction/myactions',
      });
      if (data && Array.isArray(data.items)) {
        setmyactions(data.items);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    getmyActions();
    // eslint-disable-next-line
  }, []);
  const onRefresh = () => getmyActions();

  const onDeleteEntry = async () => {
    try {
      const data = await postData({
        url: deleting && deleting.id ? '/app/entry/entry/delete' : '',
        params: { id: (deleting || {}).id },
      });
      if (data) {
        toastr.success('Entry Deleted');
        setdeleting(null);
        setTimeout(() => {
          onRefresh();
        });
      }
    } catch (error) {}
  };

  const newAction = () => {
    navigate('/entry/fillentry');
  };

  const actionsComponent = (row) => (
    <Block row>
      <IconButton
        onClick={() => {
          onEditAction(row);
        }}
      >
        <Edit color="primary" />
        <Text body semibold>
          {' '}
          Edit
        </Text>
      </IconButton>
      <IconButton
        onClick={() => {
          setdeleting(row);
        }}
      >
        <Delete style={{ color: colors.error }} />

        <Text body semibold>
          {' '}
          Delete
        </Text>
      </IconButton>
    </Block>
  );

  const DeleteDialog = () => (
    <Dialog
      open={deleting !== null}
      maxWidth="xs"
      minHeight="200"
      onClose={() => setdeleting(null)}
    >
      <Block padding={sizes.padding}>
        <Typography variant="h6" color="primary">
          Sure you want to delete this action?
        </Typography>
      </Block>
      <DialogActions>
        <Button
          variant="contained"
          color={colors.error}
          onClick={() => setdeleting(null)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onDeleteEntry()}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Block padding={[10, 20]}>
      <Block flex={false} row margin={[0, 0, 16, 0]}>
        <Block />
        <Block row space="between">
          <Button rounded color={colors.primary} onClick={onRefresh}>
            Reload
          </Button>
          <Button
            rounded
            color={colors.primary}
            onClick={newAction}
            style={{ paddingRight: 20, paddingLeft: 20 }}
          >
            New Action Report
          </Button>
        </Block>
      </Block>
      {error && <Alert error message={error} onClose={reset} />}
      <Block paper style={{ justifySelf: 'flex-start' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: colors.dark,
                  color: colors.milkyWhite,
                  fontSize: 14,
                }}
              >
                Action Name
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.dark,
                  color: colors.milkyWhite,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                Status
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.dark,
                  color: colors.milkyWhite,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                Programme
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.dark,
                  color: colors.milkyWhite,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                Project
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.dark,
                  color: colors.milkyWhite,
                  fontSize: 14,
                }}
              >
                Action
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: colors.dark,
                  color: colors.milkyWhite,
                  fontSize: 14,
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {myactions.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.ActionName || ''}
                </TableCell>
                <TableCell>{(row || {}).Status || ''}</TableCell>
                <TableCell>
                  {(row.Programme || {}).ProgrammeName || ''}
                </TableCell>
                <TableCell>{(row.Project || {}).ProjectName || ''}</TableCell>
                <TableCell>{actionsComponent(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Block>
      <Block margin="auto" flex />
      <DeleteDialog />
    </Block>
  );
};

export default MyActions;
