import React from 'react';
import { Form, Field } from '@cloudhub-ux/core/form';
import AppContext from 'app/context/AppContext';
import {
  GridContainer,
  GridItem,
  Text,
  TextArea,
  Button,
  Alert,
} from '@cloudhub-ux/core';
import useAppAxios from 'app/context/useAppAxios';
import { colors } from 'theme';

const Followup = () => {
  const {
    GrievanceState: { TrackingGrievance, grievanceActions },
  } = React.useContext(AppContext);
  const { updateTrackingGrievance } = grievanceActions;

  const { postData, error, reset } = useAppAxios();

  const sendFollowup = async (values, form) => {
    try {
      const data = await postData({
        url: '/app/fgrm/grievance/followup',
        params: {
          Request: values,
          GrievanceId: TrackingGrievance.id,
        },
      });
      if (data && data.id) {
        updateTrackingGrievance(data);
        reset();
        form.reset();
      }
    } catch (error) {}
  };

  return (
    <Form
      onSubmit={sendFollowup}
      initialValues={{ Response: 'Followup Question' }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <GridContainer spacing={2}>
          <GridItem xs={12} sm={12} md={12}>
            <Text h6 semibold>
              {(values || {}).Description || ''}
            </Text>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Field
              name="Description"
              component={TextArea}
              label="Followup Question"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              fullWidth
              color={colors.primary}
              onClick={handleSubmit}
              disabled={submitting || pristine}
            >
              {submitting && 'Submitting...'}
              {!submitting && 'Submit Followup Question'}
            </Button>
          </GridItem>
          {Boolean(error) && (
            <GridItem xs={12} sm={12} md={12}>
              <Alert error message={error} onClose={reset} />
            </GridItem>
          )}
        </GridContainer>
      )}
    />
  );
};
export default Followup;
