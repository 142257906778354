import React from 'react';
import axios from 'axios';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import AuthContext from 'auth/context/AuthContext';

import SessionExpired from 'auth/SessionExpired';
import { UNAUTH_USER } from 'auth/context/actions/authTypes';
import useVerification from './useVerification';
import VerificationContext from './VerificationContext';

const VerificationProvider = (props) => {
  const { navigate } = useLocation();

  const auth = React.useContext(AuthContext);

  const {
    token,
    currentUser,
    isAuthenticated,
    sessioncounter,
    CONFIG,
    axiosinstance,
  } = auth;
  const verificationAxiosInstance = React.useMemo(
    () => () =>
      axios.create({
        baseURL: `${CONFIG.API_ENDPOINT}/actionverification`,
        headers: {
          prxy: 'no-proxy',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
          userid: currentUser.id,
          username: currentUser.Name,
          email: currentUser.Email,
          phone: currentUser.Phone,
        },
      }),
    [
      CONFIG.API_ENDPOINT,
      token,
      currentUser.id,
      currentUser.Name,
      currentUser.Email,
      currentUser.Phone,
    ]
  );
  const VerificationState = useVerification({
    axiosinstance: verificationAxiosInstance,
  });
  React.useEffect(() => {
    if (
      !isAuthenticated ||
      (!currentUser.UserType.includes('Verifier') &&
        !currentUser.UserType.includes('Validator'))
    ) {
      // auth.actions.updateMerchant({});
      navigate('/signin');
    }
  }, [currentUser.UserType, isAuthenticated, navigate]);

  if (sessioncounter <= 0 && isAuthenticated) {
    try {
      localStorage.removeItem('token');
    } catch (error) {}
    auth.dispatch({
      type: UNAUTH_USER,
    });
    return <SessionExpired />;
  }

  return (
    <VerificationContext.Provider
      value={{
        auth: {
          token,
          currentUser,
          isAuthenticated,
          UserRoles: currentUser.UserRoles || [],
          UserType: currentUser.UserType,
          axiosinstance,
        },
        verificationAxiosInstance,
        VerificationState,
      }}
    >
      {props.children}
    </VerificationContext.Provider>
  );
};

export default VerificationProvider;
