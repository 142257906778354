import VerificationContext from 'actionverification/context/VerificationContext';
import AuthContext from 'auth/context/AuthContext';
import React from 'react';
import NotAuthorized from './NotAuthorized';

const VerificationRoute = ({ component: Component, Action }) => {
  const {
    currentUser: { UserType },
  } = React.useContext(AuthContext);
  const {
    VerificationState: {
      Validator,
      Verifier,
      Grievancehandler,
      Aggregator,
      Disseminator,
    },
  } = React.useContext(VerificationContext);

  const isAuthorized =
    UserType.includes('Verifier') ||
    UserType.includes('Validator') ||
    UserType.includes('Grievancehandler') ||
    UserType.includes('Aggregator') ||
    UserType.includes('Disseminator');
  if (!isAuthorized) {
    return <NotAuthorized />;
  }
  if (Action === 'Validation' && !(Validator || {}).id) {
    return <NotAuthorized />;
  }
  if (Action === 'Aggregation' && !(Aggregator || {}).id) {
    return <NotAuthorized />;
  }
  if (Action === 'Verification' && !(Verifier || {}).id) {
    return <NotAuthorized />;
  }
  if (Action === 'Grievance' && !(Grievancehandler || {}).id) {
    return <NotAuthorized />;
  }
  if (
    Action === 'Aggregation/Grievance' &&
    !(Grievancehandler || {}).id &&
    !(Aggregator || {}).id
  ) {
    return <NotAuthorized />;
  }
  if (
    Action === 'Aggregation/Dissemination' &&
    !(Disseminator || {}).id &&
    !(Aggregator || {}).id
  ) {
    return <NotAuthorized />;
  }

  return <Component />;
};

export default VerificationRoute;
