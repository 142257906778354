/* eslint-disable capitalized-comments */
import React from 'react';
import { TableCell } from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import { Block, Alert, IconButton } from '@cloudhub-ux/core';
import moment from 'moment';
import ValidationForm from './forms/ValidationForm';
import useAdminAxios from 'admin/context/useAdminAxios';
import { Edit } from '@mui/icons-material';
import GridHeader from 'admin/actions/GridHeader';

const Validation = () => {
  const [Status, setStatus] = React.useState('');
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);
  const [exporting, setexporting] = React.useState([]);
  const [Compliant, setCompliant] = React.useState(null);
  const { adminAxiosInstance } = useAdminAxios({});

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const headers = [
    { label: 'Assigned', key: 'Assigned' },
    { label: 'Submitted', key: 'Submitted' },
    { label: 'Action Name', key: 'ActionName' },
    { label: 'Action Code', key: 'ActionCode' },
    { label: 'Conclusion', key: 'Conclusion' },
    { label: 'Recommendation', key: 'Recommendation' },
    { label: 'Status', key: 'Status' },
    { label: 'SubmittedBy', key: 'SubmittedBy' },
    { label: 'Phone', key: 'Phone' },
    { label: 'Institution', key: 'Institution' },
    { label: 'Addresses and Respects Safeguards', key: 'Compliant' },
  ];

  const onChangeSelection = (selection) => {
    const actionswithdates = [...(selection || [])].map((action) => ({
      ...action,
      Assigned: moment(action.Timestamp).format('DD MMM, YYYY hh:mm a'),
      Submitted: (action.StatusDates || {}).SubmittedDate
        ? moment((action.StatusDates || {}).SubmittedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      ActionName: action.ActionName || '',
      SubmittedBy: (action.User || {}).Name || '',
      Phone: (action.User || {}).Phone || '',
      Institution: (action.User || {}).Institution || '',
      Compliant: action.Compliant ? 'Yes' : 'No',
    }));
    setexporting(actionswithdates);
  };

  React.useEffect(() => {
    if (Status) {
      try {
        gridRef.current.reload();
      } catch (error) {
        // error
      }
    }
  }, [Status]);

  const cols = [
    { name: 'ActionName', title: 'Action Name' },
    { name: 'ActionCode', title: 'Action Code' },
    { name: 'ValidatorName', title: 'Validator' },
    { name: 'Status' },
    { name: 'Compliant' },
  ];

  const columnWidths = [
    { columnName: 'ActionName', width: 200 },
    { columnName: 'ActionCode', width: 150 },
    { columnName: 'ValidatorName', width: 200 },
    { columnName: 'Status', width: 50 },
    { columnName: 'Compliant', width: 50 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>
      <RemoteDataGrid
        title="Validations"
        url="/actions/actionvalidation/getall"
        actionsComponent={(props) => {
          return (
            <IconButton onClick={() => setEditingRow((props || {}).row)}>
              <Edit color="primary" />
            </IconButton>
          );
        }}
        header={(props) => (
          <GridHeader
            {...props}
            Status={Status}
            setStatus={setStatus}
            headers={headers}
            exporting={exporting}
            Compliant={Compliant}
            setCompliant={setCompliant}
          />
        )}
        axiosinstance={adminAxiosInstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        params={{ Status, Compliant }}
        onAdd={() => {}}
        onEdit={(row) => setEditingRow(row)}
        onDeleteRows={() => {}}
        ref={gridRef}
        onChangeSelection={onChangeSelection}
      />
      <ValidationForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Validation;
