import React from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, Card } from '@mui/material';
import { MotionContainer, varBounceIn } from '@cloudhub-ux/min/theme/animate';
import { Input } from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import AppContext from 'app/context/AppContext';
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function RetrieveGrievances() {
  const {
    GrievanceState: { grievanceActions },
  } = React.useContext(AppContext);
  const { getGrievance } = grievanceActions;

  return (
    <RootStyle title="Retrieve Grievance">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Track Submitted Grievances
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Find the status of grievances you already submitted and respond to
              queries and requests. Enter the grievance code and the password
              you set to see updattes
            </Typography>

            <motion.div variants={varBounceIn}>
              <Form
                onSubmit={getGrievance}
                render={({ handleSubmit }) => (
                  <Card sx={{ p: 4 }}>
                    <Field
                      name="GrievanceCode"
                      label="Grievance Code"
                      component={Input}
                      required
                    />
                    <Field
                      name="Password"
                      label="Password"
                      component={Input}
                      required
                      type="password"
                    />
                    <Button
                      onClick={handleSubmit}
                      size="large"
                      variant="contained"
                      fullWidth
                    >
                      View Grievance
                    </Button>
                  </Card>
                )}
              />
            </motion.div>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
