import React from 'react';
import AdminErrorBoundary from './AdminErrorBoundary';
import AdminHome from './AdminHome';
import AdminRoutes from './AdminRoutes';
import AdminProvider from './context/AdminProvider';

const AdminApp = () => {
  return (
    <AdminErrorBoundary>
      <AdminProvider>
        <AdminHome>
          <AdminRoutes />
        </AdminHome>
      </AdminProvider>
    </AdminErrorBoundary>
  );
};

export default AdminApp;
