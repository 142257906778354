import React from 'react';
import { FieldBlock, Alert, Block } from '@cloudhub-ux/core';
import { colors } from 'theme';
import { Form, Field } from '@cloudhub-ux/core/form';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import { RemoteSelector } from '@cloudhub-ux/core/baseweb';
import FieldButton from '@cloudhub-ux/core/FieldButton';
const EscalationForm = ({ Grievanceresponse, onClose }) => {
  const {
    postData,
    error,
    reset,
    verificationAxiosInstance,
  } = useVerificationAxios();
  const escalateGrievance = async (values, form) => {
    try {
      const data = await postData({
        url: '/grievance/escalategrievance',
        params: {
          AssignmentId: Grievanceresponse.id,
          ...values,
        },
      });
      if (data) {
        onClose();
        reset();
        form.reset();
      }
    } catch (error) {}
  };
  return (
    <Form
      onSubmit={escalateGrievance}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <Block>
          <FieldBlock row wrap>
            <Field
              name="Grievancehandler"
              label="Escalate to"
              component={RemoteSelector}
              url="/grievancehandler/filtersearch"
              axiosinstance={verificationAxiosInstance}
              labelExtractor={(row) =>
                row && row.User && row.Stakeholder
                  ? `${(row.User || {}).Name} ${
                      (row.Stakeholder || {}).StakeholderName
                    }`
                  : ''
              }
              valueExtractor={(row) => row}
              keyExtractor={(row) => (row || {}).id}
              flex
            />
            <FieldButton
              color={colors.success}
              onClick={handleSubmit}
              disabled={
                submitting || pristine || !(values || {}).Grievancehandler
              }
            >
              Escalate
            </FieldButton>
          </FieldBlock>
          {Boolean(error) && <Alert error message={error} onClose={reset} />}
        </Block>
      )}
    />
  );
};
export default EscalationForm;
