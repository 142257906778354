import React from 'react';
import useAuthentication from './useAuthentication';
import AuthContext from './AuthContext';
import CONFIG from './Config';

const AuthProvider = (props) => {
  const {
    loading,
    isAuthenticated,
    currentUser,
    token,
    actions,
    ...rest
  } = useAuthentication();

  React.useEffect(() => {
    if (token && !isAuthenticated && !loading) {
      actions.checkAuthStatus();
    }
  }, [token, loading, actions, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        currentUser,
        UserRoles: currentUser.UserRoles || [],
        UserType: currentUser.UserType,
        token,
        actions,
        CONFIG,
        ...rest,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
