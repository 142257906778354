import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './service-worker';
import swStore from 'serviceworkers/store/swStore';
import { SW_INIT, SW_UPDATE } from 'serviceworkers/store/swActions';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@cloudhub-ux/core/slideshow/image-gallery.css';
import 'video.js/dist/video-js.css';
const dispatch = swStore.getState().dispatch;

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

serviceWorker.register({
  onSuccess: () => {
    dispatch({ type: SW_INIT });
  },
  onUpdate: (reg) => {
    dispatch({
      type: SW_UPDATE,
      payload: reg,
    });
  },
});

if (module.hot) {
  module.hot.accept();
}
