import React from 'react';
import {
  GridContainer,
  GridItem,
  Block,
  TextArea,
  Input,
  DatePicker,
  Alert,
  Text,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import {
  StaticListSelector,
  LayersManager,
  RemoteSelector,
} from '@cloudhub-ux/core/baseweb';
import ScopeForm from 'localcomponents/ScopeForm';
import useAdminAxios from 'admin/context/useAdminAxios';
import AdminContext from 'admin/context/AdminContext';

const ActionForm = ({ action = {} }) => {
  const { error, reset, appAxiosInstance } = useAdminAxios({});
  const {
    Tasks: { Safeguards },
  } = React.useContext(AdminContext);

  const safeguards = Object.keys((action || {}).Safeguards || {});
  return (
    <Form
      onSubmit={() => {}}
      initialValues={{ ...action }}
      validateOnChange={false}
      render={({ values }) => (
        <Block
          style={{
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          <LayersManager zIndex={1}>
            <GridContainer spacing={2}>
              <GridItem>
                {Boolean(error) && (
                  <Alert error message={error} onClose={() => reset('')} />
                )}
              </GridItem>
              <GridItem sm={12}>
                <Text h5>Submitted By: {((values || {}).User || {}).Name}</Text>
              </GridItem>
              <GridItem sm={12}>
                <Text h5>
                  Institution: {((values || {}).User || {}).InstitutionName}
                </Text>
              </GridItem>
              <GridItem sm={12}>
                <Field
                  readOnly
                  name="ActionName"
                  type="text"
                  label="Action Name"
                  component={Input}
                  required
                  maxLength={200}
                />
              </GridItem>
              <GridItem sm={12} md={4}>
                <Field
                  readOnly
                  name="Programme"
                  label="Programme"
                  component={RemoteSelector}
                  axiosinstance={appAxiosInstance}
                  url="/programme/filtersearch"
                  valueExtractor={(row) =>
                    row
                      ? {
                          id: row.id,
                          _id: row._id,
                          ProgrammeName: row.ProgrammeName,
                          ProgrammeCode: row.ProgrammeCode,
                        }
                      : row
                  }
                  labelExtractor={(row) => (row || {}).ProgrammeName || ''}
                  keyExtractor={(row) => (row || {}).id}
                  flex
                />
              </GridItem>
              <GridItem sm={12} md={4}>
                <Field
                  readOnly
                  name="Project"
                  label="Project"
                  component={RemoteSelector}
                  axiosinstance={appAxiosInstance}
                  url="/project/filtersearch"
                  valueExtractor={(row) =>
                    row
                      ? {
                          id: row.id,
                          _id: row._id,
                          ProjectName: row.ProjectName,
                          ProjectCode: row.ProjectCode,
                        }
                      : row
                  }
                  labelExtractor={(row) => (row || {}).ProjectName || ''}
                  keyExtractor={(row) => (row || {}).id}
                  params={
                    ((values || {}).Programme || {}).id
                      ? {
                          'Programme.id': ((values || {}).Programme || {}).id,
                        }
                      : {}
                  }
                  flex
                />
              </GridItem>
              <GridItem sm={12} md={4}>
                <Field
                  readOnly
                  name="Scope"
                  label="Scope"
                  component={StaticListSelector}
                  options={[
                    'COUNTRY_WIDE',
                    'COUNTY',
                    'CONSTITUENCY',
                    'WARD',
                    'VILLAGE',
                  ]}
                  required
                  flex
                />
              </GridItem>
              <GridItem sm={12}>
                <ScopeForm values={values} />
              </GridItem>
              <GridItem xs={12}>
                <Block middle center>
                  {(values || {}).Coordinates && values.Coordinates.Longitude && (
                    <Text h6 primary>
                      Longitude: {values.Coordinates.Longitude} Latitude:
                      {values.Coordinates.Latitude}
                    </Text>
                  )}
                </Block>
              </GridItem>
              <GridItem sm={12} md={4}>
                <Field
                  readOnly
                  name="StartDate"
                  label="Start Date of Action"
                  component={DatePicker}
                  required
                />
              </GridItem>
              <GridItem sm={12} md={4}>
                <Field
                  readOnly
                  name="EndDate"
                  label="End Date of Action"
                  component={DatePicker}
                  required
                  flex
                />
              </GridItem>
              <GridItem sm={12} md={4}>
                <Field
                  readOnly
                  name="ActionStatus"
                  label="ActionStatus"
                  component={StaticListSelector}
                  options={[
                    'ACTIVE',
                    'COMPLETTED',
                    'ON_HOLD',
                    'CANCELED',
                    'ABANDONED',
                  ]}
                  required
                  flex
                />
              </GridItem>

              <GridItem>
                <Field
                  readOnly
                  name="Description"
                  type="text"
                  label="Description of Action"
                  component={TextArea}
                  rows={10}
                  required
                  maxLength={5000}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {(((values || {}).Risks || {}).Files || []).map((file) =>
                  file && file.Location ? (
                    <a
                      style={{ width: '100%' }}
                      href={file.Location}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {file.filename || file.name}
                    </a>
                  ) : null
                )}
              </GridItem>
              {Safeguards.map((safeguard) => {
                const {
                  SafeguardName,
                  SafeguardCode,
                  Description,
                  Indicators,
                  id,
                } = safeguard;
                if (
                  !id ||
                  !SafeguardName ||
                  !SafeguardCode ||
                  (Indicators || []).length === 0 ||
                  !(safeguards || []).includes(SafeguardCode)
                ) {
                  return null;
                }
                return (
                  <React.Fragment key={safeguard.id}>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Text h4 bold primary>
                        {SafeguardName}
                      </Text>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: Description,
                        }}
                      />
                    </GridItem>
                    {Indicators.map((indicator) => {
                      const { IndicatorName, AssessmentQuestion } = indicator;
                      if (!IndicatorName || !AssessmentQuestion) {
                        return null;
                      }
                      return (
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <Field
                            readOnly
                            name={`Safeguards.${SafeguardCode}.${IndicatorName.replace(
                              /[\s.,]/g,
                              '_'
                            )}`}
                            label={
                              <Text>
                                <Text bold>{IndicatorName}:</Text>{' '}
                                {AssessmentQuestion}{' '}
                              </Text>
                            }
                            component={TextArea}
                            required
                          />
                        </GridItem>
                      );
                    })}
                  </React.Fragment>
                );
              })}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {(((values || {}).Safeguards || {}).Files || []).map((file) =>
                  file && file.Location ? (
                    <a
                      style={{ width: '100%' }}
                      href={file.Location}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {file.filename || file.name}
                    </a>
                  ) : null
                )}
              </GridItem>
            </GridContainer>
          </LayersManager>
        </Block>
      )}
    />
  );
};

export default ActionForm;
