import React from 'react';
import { styled } from '@mui/material/styles';
import SafeguardHero from './SafeguardHero';
import AppContext from 'app/context/AppContext';
import SafeguardContent from './SafeguardContent';

const RootStyle = styled('div')({
  height: '100%',
});

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100vw',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export default function AboutPage({ SafeguardName }) {
  const [safeguard, setsafeguard] = React.useState({
    Title: 'Welcome to Kenya REDD+ SIS',
    LongTitle: "Kenya's REDD+ safeguards Information System",
    Body:
      "Welcome to Kenya REDD+ SIS to learn more about Kenya's approach to addressing and respecting the Cancun safeguards",
  });

  const {
    Safeguards: { Safeguards },
  } = React.useContext(AppContext);
  React.useEffect(() => {
    if (SafeguardName) {
      const ind = Safeguards.findIndex(
        (page) => page.SafeguardName === SafeguardName
      );
      if (ind > -1) {
        setsafeguard(Safeguards[ind]);
      }
    } else {
      const ind = Safeguards.findIndex((page) => page.SafeguardName);
      if (ind > -1) {
        setsafeguard(Safeguards[ind]);
      }
    }
    // eslint-disable-next-line
  }, [JSON.stringify(Safeguards), SafeguardName]);
  return (
    <RootStyle title="REDD+ Kenya SIS" id="about_sis">
      <SafeguardHero page={safeguard} />
      <ContentStyle>
        <SafeguardContent page={safeguard} />
      </ContentStyle>
    </RootStyle>
  );
}
