import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';
import useSettings from '@cloudhub-ux/min/hooks/useSettings';
import AllGrievances from './AllGrievances';
import AllVerifications from './AllVerifications';
import AllValidations from './AllValidations';
import VerificationsByStatus from './VerificationsByStatus';
import ValidationsByStatus from './ValidationsByStatus';
import GrievancesByStatus from './GrievancesByStatus';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  width: '100%',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const theme = useTheme();
  const { themeStretch } = useSettings();

  return (
    <RootStyle>
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
        }}
      >
        <Container maxWidth={themeStretch ? false : 'xl'} style={{}}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <AllVerifications />
            </Grid>
            <Grid item xs={12} md={4}>
              <AllValidations />
            </Grid>
            <Grid item xs={12} md={4}>
              <AllGrievances />
            </Grid>

            <Grid item xs={12} md={4}>
              <VerificationsByStatus />
            </Grid>

            <Grid item xs={12} md={4}>
              <ValidationsByStatus />
            </Grid>
            <Grid item xs={12} md={4}>
              <GrievancesByStatus />
            </Grid>
          </Grid>
        </Container>
      </MainStyle>
    </RootStyle>
  );
}
