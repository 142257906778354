/* eslint-disable capitalized-comments */
import React from 'react';
import { TableCell } from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import { Block, Alert, toastr } from '@cloudhub-ux/core';

import DocumentsForm from './forms/DocumentsForm';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import moment from 'moment';
const dateFormat = 'DD-MMM-YYYY';

const Documents = () => {
  const { postData, verificationAxiosInstance } = useVerificationAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);

  const deleteRows = async (deletingRows) => {
    try {
      const ids = deletingRows.map((item) => item.id);
      const data = await postData({
        url: `/safeguards/documents/delete`,
        params: { ids },
      });
      if (data) {
        toastr.success('PLRs successfulyy Deleted');
        gridRef.current.reload();
      }
    } catch (error) {}
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    if (column.name === 'PublishedDate') {
      return (
        <TableCell>{moment(row.PublishedDate).format(dateFormat)}</TableCell>
      );
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'Title' },
    { name: 'DocumentType', title: 'Type' },
    { name: 'PublishedDate', title: 'Published' },
  ];

  const columnWidths = [
    { columnName: 'Title', width: 200 },
    { columnName: 'DocumentType', width: 100 },
    { columnName: 'PublishedDate', width: 150 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>

      <RemoteDataGrid
        title="Reports and Publications"
        url="/documents/getall"
        axiosinstance={verificationAxiosInstance}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
      />
      <DocumentsForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Documents;
