import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Reports from './Reports';
import Galleries from './Galleries';
import AboutPage from 'app/about/AboutPage';

const ResourceRoutes = () => {
  return (
    <Router style={{ flex: 1 }}>
      <Reports path="reports" />
      <Galleries path="media" />
      <AboutPage path="/:Title" />
    </Router>
  );
};

export default ResourceRoutes;
