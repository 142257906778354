import React from 'react';
import {
  ListMenu,
  ListMenuItem,
  Block,
  Button,
  IconButton,
  Input,
  Alert,
  toastr,
  Avatar,
  Text,
} from '@cloudhub-ux/core';
import { StaticListSelector, RemoteSelector } from '@cloudhub-ux/core/baseweb';
import { Form, Field } from '@cloudhub-ux/core/form';
import useAdminAxios from 'admin/context/useAdminAxios';
import { colors, sizes } from 'theme';
import { Delete, Edit } from '@mui/icons-material';

const StakeholderValidator = ({ StakeholderName, StakeholderCode, id }) => {
  const [validators, setvalidators] = React.useState([]);
  const [deletingIndex, setdeletingIndex] = React.useState(-1);
  const [editingIndex, seteditingIndex] = React.useState(-1);

  const { getData, postData, error, reset, adminAxiosInstance } = useAdminAxios(
    {}
  );

  const getValidators = async () => {
    try {
      const data = await getData({
        url: '/stakeholders/validator/stakeholdervalidators',
        params: { StakeholderId: id },
      });
      if (data && Array.isArray(data.items)) {
        setvalidators(data.items);
      }
    } catch (error) {
      // error
    }
  };

  React.useEffect(() => {
    if (id) {
      getValidators();
    }
    // eslint-disable-next-line
  }, [id]);

  const saveValidator = async (validator, form) => {
    try {
      const data = await postData({
        url: '/stakeholders/validator/savevalidator',
        params: validator,
      });
      if (data && data.id) {
        toastr.success('Success, Validator saved successfully');
        getValidators();
        setTimeout(() => {
          form.reset();
          reset();
          seteditingIndex(-1);
        });
      }
    } catch (error) {}
  };

  const deleteValidator = async (validator, form) => {
    try {
      if (deletingIndex === -1) {
        return;
      }
      const validatorId = ((validators || [])[deletingIndex] || {}).id;
      if (!validatorId) {
        return;
      }
      const data = await postData({
        url: '/stakeholders/validator/delete',
        params: { ids: [validatorId] },
      });
      if (data) {
        toastr.success('Success, Validator deleted successfully');
        getValidators();
      }
    } catch (error) {}
  };
  return (
    <Block>
      <div>
        {Boolean(error) && <Alert error message={error} onClose={reset} />}
      </div>
      <Block row wrap>
        {(validators || []).length > 0 && (
          <Block>
            <ListMenu>
              {validators.map((validator, index) =>
                !validator ? null : (
                  <ListMenuItem
                    key={index}
                    avatar={
                      <Avatar>
                        {(validator.User || {}).Name.substring(0, 1)}
                      </Avatar>
                    }
                    primary={
                      <Text h6 bold style={{ marginRight: 100 }}>
                        {((validator || {}).User || {}).Name}
                      </Text>
                    }
                    secondary={validator.Designation}
                    action={
                      <Block>
                        {deletingIndex === index ? (
                          <Block row paper padding={sizes.padding}>
                            <Text>Sure you want to remove validator?</Text>
                            <Button
                              contained
                              small
                              color={colors.error}
                              style={{ margin: '0 5px' }}
                              onClick={() => setdeletingIndex(-1)}
                            >
                              Cancel
                            </Button>
                            <Button
                              contained
                              small
                              color={colors.primary}
                              style={{ margin: '0 5px' }}
                              onClick={deleteValidator}
                            >
                              Remove
                            </Button>
                          </Block>
                        ) : (
                          <Block row>
                            <IconButton onClick={() => seteditingIndex(index)}>
                              <Edit color="primary" />{' '}
                            </IconButton>
                            <IconButton onClick={() => setdeletingIndex(index)}>
                              <Delete style={{ color: colors.error }} />{' '}
                            </IconButton>
                          </Block>
                        )}
                      </Block>
                    }
                  />
                )
              )}
            </ListMenu>
          </Block>
        )}
        <Form
          onSubmit={saveValidator}
          initialValues={
            editingIndex > -1
              ? { ...((validators || [])[editingIndex] || {}) }
              : {
                  Stakeholder: {
                    id: id,
                    _id: `stakeholder/${id}`,
                    StakeholderName,
                    StakeholderCode,
                  },
                }
          }
          render={({ handleSubmit, submitting, pristine, values, form }) => (
            <Block>
              {editingIndex > -1 && (
                <Button
                  style={{ alignSelf: 'flex-end', color: colors.red }}
                  onClick={() => seteditingIndex(-1)}
                >
                  Cancel
                </Button>
              )}
              <Field
                name="User"
                component={RemoteSelector}
                url="/system/user/filtersearch"
                label="User"
                axiosinstance={adminAxiosInstance}
                labelExtractor={(row) => (row || {}).Name || ''}
                valueExtractor={(row) =>
                  row && row.id
                    ? {
                        id: row.id,
                        _id: row._id,
                        Name: row.Name,
                        Email: row.Email,
                        Phone: row.Phone,
                      }
                    : row
                }
                keyExtractor={(row) => (row || {}).id || row}
                required
              />
              <Field
                name="Designation"
                label="Designation"
                component={Input}
                required
              />
              <Field
                name="Status"
                label="Status"
                component={StaticListSelector}
                options={['ACTIVE', 'DORMANT']}
                required
              />
              <Field
                name="Indicators"
                label="Indicators"
                component={RemoteSelector}
                axiosinstance={adminAxiosInstance}
                url="/safeguards/safeguard/indicators"
                required
                isMulti
              />

              <Button
                color={colors.primary}
                disabled={pristine || submitting}
                onClick={handleSubmit}
              >
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </Block>
          )}
        />
      </Block>
    </Block>
  );
};

export default StakeholderValidator;
