import React from 'react';
import {
  GridContainer,
  GridItem,
  Block,
  TextArea,
  Alert,
  Input,
  toastr,
  Button,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { makeStyles } from '@mui/styles';
import { LayersManager, StaticListSelector } from '@cloudhub-ux/core/baseweb';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import { sizes } from 'theme';
import EscalationForm from './EscalationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    paddingBottom: sizes.padding * 2,
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
}));

const GrievanceResponseForm = ({ Grievanceresponse, onClose }) => {
  const [uploading, setuploading] = React.useState(false);
  const [saving, setsaving] = React.useState(false);
  const classes = useStyles();
  const { error, reset, postData } = useVerificationAxios({});

  const changeFile = (event, form) => {
    if (event && event.target && event.target.value) {
      const v = event.target.value || [];
      const ind = v.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', v);
    }
    if (event && Array.isArray(event)) {
      const ind = event.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', event);
    }
  };

  const onRemoveFiles = async (file) => {
    try {
      const data = await postData({
        url: '/media/upload/deletefiles',
        params: { files: [file] },
      });
      if (data) {
        toastr.success('Files deleted successfully');
        return data;
      }
    } catch (error) {}
  };

  const submitResponse = async (values) => {
    try {
      const data = await postData({
        url: '/grievanceresponse/submitresponse',
        params: values,
      });
      if (data && data.id) {
        toastr.success('Response Submittted Successfully');
        reset();
        onClose();
      }
    } catch (error) {}
  };

  const saveResponse = async (values) => {
    try {
      setsaving(true);
      const data = await postData({
        url: '/grievanceresponse/saveresponse',
        params: values,
      });
      if (data && data.id) {
        toastr.success('Response updatted successfully');
        reset();
      }
      setsaving(false);
    } catch (error) {
      setsaving(false);
    }
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={submitResponse}
        initialValues={{ ...(Grievanceresponse || {}) }}
        validateOnChange={false}
        render={({ handleSubmit, pristine, submitting, form, values }) => (
          <Block
            style={{
              maxHeight: '100%',
              overflow: 'auto',
            }}
          >
            <LayersManager zIndex={1}>
              <GridContainer spacing={2}>
                <GridItem>
                  {Boolean(error) && (
                    <Alert error message={error} onClose={() => reset('')} />
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="Methodology"
                    label="Description of Grievance Redress Methodology"
                    component={TextArea}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="Findings"
                    label="Description of Findings"
                    component={TextArea}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="Conclusion"
                    label="Conclusion"
                    component={TextArea}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="Recommendation"
                    label="Recommendation"
                    component={TextArea}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="Status"
                    label="Status"
                    component={StaticListSelector}
                    options={['OPEN', 'RESOLVED', 'DISMISSED', 'REFERRED']}
                  />
                </GridItem>
                {(values || {}).Status === 'REFERRED' && (
                  <React.Fragment>
                    <GridItem xs={12}>
                      <Field
                        name="ReferredTo.Institution"
                        label="Institution Referred to"
                        component={Input}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name="ReferredTo.Officer"
                        label="Officer to Whom Referred"
                        component={Input}
                      />
                    </GridItem>
                  </React.Fragment>
                )}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    label="Grievance Redress Files"
                    name="Files"
                    url={`/api/media/upload/file`}
                    // axiosinstance={axiosinstance}
                    component={FilesUpload}
                    limit={10}
                    onChange={(event) => changeFile(event, form)}
                    onRemove={onRemoveFiles}
                  />
                </GridItem>
                {(Grievanceresponse || {}).Status === 'OPEN' && (
                  <EscalationForm
                    Grievanceresponse={Grievanceresponse}
                    onClose={onClose}
                  />
                )}
                {(Grievanceresponse || {}).Status === 'OPEN' && (
                  <React.Fragment>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        fullWidth
                        onClick={saveResponse}
                        color="secondary"
                        disabled={pristine || submitting || saving || uploading}
                      >
                        {`${submitting ? 'Submitting...' : ''}${
                          saving ? 'Saving...' : ''
                        }${uploading ? 'Uploading...' : ''}${
                          !submitting && !saving && !uploading ? 'Save' : ''
                        }`}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        fullWidth
                        onClick={handleSubmit}
                        color="primary"
                        disabled={submitting || saving || uploading}
                      >
                        {`${submitting ? 'Submitting...' : ''}${
                          saving ? 'Saving...' : ''
                        }${uploading ? 'Uploading...' : ''}${
                          !submitting && !saving && !uploading ? 'Submit' : ''
                        }`}
                      </Button>
                    </GridItem>
                  </React.Fragment>
                )}
              </GridContainer>
            </LayersManager>
          </Block>
        )}
      />
    </div>
  );
};

export default GrievanceResponseForm;
