import React from 'react';
import {
  Dialog,
  Text,
  GridContainer,
  GridItem,
  Block,
  Alert,
  TextArea,
  Button,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import DialogLayer from 'localcomponents/DialogLayer';
import { Form, Field } from '@cloudhub-ux/core/form';
import AdminContext from 'admin/context/AdminContext';
import { sizes, colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import VerificationReassignForm from './VerificationReassignForm';

const VerificationForm = ({ editingRow, onCancelEdit, onSave }) => {
  const [deassign, setdeassign] = React.useState(false);
  const {
    Tasks: { Safeguards },
  } = React.useContext(AdminContext);

  const { error, postData, reset } = useAdminAxios({});

  const deassignTask = async () => {
    try {
      const data = await postData({
        url: `/actions/actionverification/deassign`,
        params: { VerificationId: (editingRow || {}).id },
      });
      if (data && data.id) {
        reset();
        onSave();
        setdeassign(false);
      }
    } catch (error) {}
  };

  const safeguards = Object.keys((editingRow || {}).Safeguards || {});
  return (
    <Dialog open={editingRow !== null} fullScreen onClose={onCancelEdit}>
      <DialogHeader onClose={onCancelEdit}>
        <Text h4 bold>
          {(editingRow || {}).ActionName || (editingRow || {}).ActionCode || ''}
        </Text>
      </DialogHeader>
      <DialogLayer>
        <Form
          onSubmit={() => {}}
          initialValues={editingRow || {}}
          validateOnChange={false}
          render={({ values }) => (
            <Block padding={sizes.padding} paper>
              <GridContainer spacing={2}>
                <GridItem>
                  {Boolean(error) && (
                    <Alert error message={error} onClose={() => reset('')} />
                  )}
                </GridItem>
                {Safeguards.map((safeguard) => {
                  const {
                    SafeguardName,
                    SafeguardCode,
                    Description,
                    Indicators,
                    id,
                  } = safeguard;
                  const indicators = Object.keys(
                    ((editingRow || {}).Safeguards || {})[SafeguardCode] || {}
                  );
                  if (
                    !id ||
                    !SafeguardName ||
                    !SafeguardCode ||
                    (Indicators || []).length === 0 ||
                    !(safeguards || []).includes(SafeguardCode)
                  ) {
                    return null;
                  }
                  return (
                    <React.Fragment key={safeguard.id}>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Text h4 bold primary>
                          {SafeguardName}
                        </Text>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: Description,
                          }}
                        />
                      </GridItem>
                      {Indicators.map((indicator) => {
                        const {
                          IndicatorName,
                          VerificationQuestion,
                          VerificationNote,
                          AssessedInformation,
                          Definition,
                          VerificationManner,
                        } = indicator;
                        if (
                          !IndicatorName ||
                          !VerificationQuestion ||
                          !(indicators || []).includes(IndicatorName)
                        ) {
                          return null;
                        }
                        return (
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Field
                              readOnly
                              name={`Safeguards.${SafeguardCode}.${IndicatorName.replace(
                                /[\s.,]/g,
                                '_'
                              )}`}
                              label={
                                <Text>
                                  <Text bold>{IndicatorName}:</Text>{' '}
                                  {VerificationQuestion}{' '}
                                  {VerificationNote ? (
                                    <Tooltip
                                      title={
                                        <Block>
                                          <Text>{VerificationNote || ''}</Text>
                                          <Text>{Definition || ''}</Text>
                                          <Text>
                                            {AssessedInformation || ''}
                                          </Text>
                                          <Text>
                                            {VerificationManner || ''}
                                          </Text>
                                        </Block>
                                      }
                                    >
                                      <InfoOutlined
                                        style={{
                                          color: colors.info,
                                          fontSize: 14,
                                          marginLeft: sizes.doubleBaseMargin,
                                          marginBottom: -2,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                </Text>
                              }
                              component={TextArea}
                              required
                            />
                          </GridItem>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    label="Conclusion"
                    name="Conclusion"
                    component={TextArea}
                    readOnly
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    type="text"
                    label="Recommendation"
                    name="Recommendation"
                    component={TextArea}
                    readOnly
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {Array.isArray((values || {}).Files) &&
                    values.Files.map((file) => (
                      <a
                        href={file.Location}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {file.filename}
                      </a>
                    ))}
                </GridItem>
              </GridContainer>
              <VerificationReassignForm
                AssignmentId={(editingRow || {}).id}
                onReassign={onSave}
              />
            </Block>
          )}
        />
      </DialogLayer>
      <DialogActions>
        <Button contained onClick={onCancelEdit} color={colors.error}>
          Cancel
        </Button>
        <React.Fragment>
          {(editingRow || {}).Status === 'NOT_SUBMITTED' && !deassign && (
            <React.Fragment>
              <Button
                contained
                onClick={() => setdeassign(true)}
                color={colors.primary}
              >
                Deassgin
              </Button>
            </React.Fragment>
          )}
          {(editingRow || {}).Status === 'NOT_SUBMITTED' && deassign && (
            <React.Fragment>
              <Text h6 bold>
                Sure you want to deassign task?
              </Text>
              <Button
                contained
                onClick={() => setdeassign(false)}
                color={colors.error}
              >
                Cancel
              </Button>
              <Button contained onClick={deassignTask} color={colors.primary}>
                Deassgin
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationForm;
