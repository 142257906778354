import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import Safeguard from './Safeguard';
import Plr from './Plr';
import Infopage from '../website/Infopage';
import Documents from '../website/Documents';

const SafeguardRoutes = (props) => {
  return (
    <Router style={{ flex: 1 }}>
      <Safeguard path="/" />
      <Plr path="plr" />
      <Infopage path="infopages" />
      <Documents path="documents" />
    </Router>
  );
};

export default SafeguardRoutes;
