import { toastr } from '@cloudhub-ux/core';
import { navigate } from '@cloudhub-ux/core/reach';
import {
  SET_LOADING,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  SET_TOKEN,
  OPEN_SIGN_IN,
  CLOSE_SIGN_IN,
  OPEN_CHANGE_PASSWORD,
  CLOSE_CHANGE_PASSWORD,
  OPEN_UPDATE_PROFILE,
  CLOSE_UPDATE_PROFILE,
  SEND_CONFIRMATION_CODE,
  CONFIRM_ACCOUNT,
  OPEN_GET_STARTED,
  CLOSE_GET_STARTED,
  SET_IMPLEMENTOR,
  SET_LAST_INTERACTION,
} from './authTypes';

const authActions = ({ dispatch, axiosinstance }) => {
  const actions = {
    authUser: (data) => {
      dispatch({
        type: AUTH_USER,
        payload: data,
      });
    },

    signinUser: async (values) => {
      try {
        actions.setLoading();
        const { data } = await axiosinstance().post(`/auth/user/emaillogin`, {
          ...values,
        });

        actions.authUser(data);
        dispatch({
          type: CLOSE_SIGN_IN,
        });

        if (
          Array.isArray(data.user.UserType) &&
          data.user.UserType.includes('Admin')
        ) {
          navigate('/admin');
        } else if (
          Array.isArray(data.user.UserType) &&
          (data.user.UserType.includes('Verifier') ||
            data.user.UserType.includes('Validator') ||
            data.user.UserType.includes('GrievanceHandler') ||
            data.user.UserType.includes('Informationaggregator') ||
            data.user.UserType.includes('Disseminator'))
        ) {
          navigate('/actionveriification');
        } else {
          navigate('/');
        }

        actions.setLoading(false);
        // return { token: data.token, user: data.user };
      } catch (error) {
        toastr.error('Error signing in');
        actions.authError('Bad login info');
        return {};
      }
    },

    checkPhoneNumber: ({ Phone }) =>
      axiosinstance()
        .get(`/auth/user/checkphone`, {
          params: { Phone },
        })
        .then(({ data }) => Promise.resolve(data))
        .catch(() => Promise.reject()),

    signoutUser: () => {
      if (localStorage) {
        localStorage.removeItem('token');
      }
      navigate('/signin');
      return dispatch({
        type: UNAUTH_USER,
      });
    },

    signupUser: ({ user }) => {
      axiosinstance()
        .post(`/auth/user/signup`, user)
        .then(({ data }) => {
          actions.authUser(data);
          actions.closeSignIn();
          navigate('/');
        })
        .catch((error) => {
          const { response } = error;
          const message = response
            ? response.data.message || 'Error in your request'
            : error;
          toastr.error(message);
          actions.authError(message);
        });
    },

    loginWithFacebook: ({ params }) => {
      axiosinstance()
        .post(`/auth/user/facebooklogin`, { ...params })
        .then(({ data }) => {
          actions.authUser(data);
          dispatch({
            type: CLOSE_SIGN_IN,
          });
        })
        .catch(async () => {});
    },

    loginWithGoogle: ({ params }) => {
      axiosinstance()
        .post(`/auth/user/googlelogin`, { ...params })
        .then(({ data }) => {
          actions.authUser(data);
          dispatch({
            type: CLOSE_SIGN_IN,
          });
        })
        .catch(async (error) => {
          if (localStorage) {
            localStorage.removeItem('google_access_code');
          }

          actions.authError(
            error.response.data.message ? error.response.data.message : error
          );
        });
    },

    checkAuthStatus: async () => {
      let token;
      try {
        dispatch({
          type: AUTH_ERROR,
          payload: '',
        });
        token = localStorage.getItem('token');
      } catch (error) {
        //
      }

      dispatch({
        type: SET_TOKEN,
        payload: token,
      });

      try {
        actions.setLoading();
        const { data } = await axiosinstance().post(`/auth/user/getuser`, {
          token,
        });

        actions.authUser(data);
        const user = data.user || {};

        if (Array.isArray(user.UserType) && user.UserType.includes('Admin')) {
          navigate('/admin');
        } else {
          navigate('/');
        }

        actions.setLoading(false);
      } catch (error) {
        const response = error.response || {};
        const data = response.data || {};

        if (`${data.message}`.includes('EXPIRED')) {
          navigate('/');
        }

        dispatch({
          type: AUTH_ERROR,
          payload: `${data.message || 'Server error'}`.replace(/_/g, ' '),
        });
      }
    },

    sendConfirmationCode: () => {
      axiosinstance()
        .get(`/auth/user/sendconfirmcode`)
        .then(() => {
          dispatch({
            type: SEND_CONFIRMATION_CODE,
          });
        });
    },

    confirmAccount: (code) => {
      axiosinstance()
        .post(`/auth/user/confirmaccount`, { code })
        .then(() => {
          dispatch({
            type: CONFIRM_ACCOUNT,
          });
        })
        .catch(() => {
          toastr.error('Problem with your code');
        });
    },

    resetPassword: ({ Email }) =>
      axiosinstance()
        .post(`/auth/user/resetpassword`, { Email })
        .then(() => {
          navigate('/auth/resetpasswordsuccess');
          return Promise.resolve();
        })
        .catch(() => {
          toastr.error('Problem with your code');
        }),

    changePassword: ({ CurrentPassword, Password }) => {
      axiosinstance()
        .post(`/auth/user/changepassword`, {
          CurrentPassword,
          Password,
        })
        .then(() => {
          actions.closeChangePassword();
          toastr.success('Your password was reset successfully');
        })
        .catch(() => {
          toastr.error('Problem with your code');
        });
    },

    updateProfile: (profile) => {
      axiosinstance()
        .post(`/auth/user/updateprofile`, profile)
        .then(({ data }) => {
          actions.authUser(data);
          toastr.success('Your profile is now updated');
        })
        .catch((error) => {
          const response = error.response || {};
          const data = response.data || {};
          toastr.error(data.message || 'Problem with your profile');
        });
    },

    openSignIn: () =>
      dispatch({
        type: OPEN_SIGN_IN,
      }),

    openGetStarted: () =>
      dispatch({
        type: OPEN_GET_STARTED,
      }),

    closeGetStarted: () =>
      dispatch({
        type: CLOSE_GET_STARTED,
      }),

    closeSignIn: () =>
      dispatch({
        type: CLOSE_SIGN_IN,
      }),

    openChangePassword: () =>
      dispatch({
        type: OPEN_CHANGE_PASSWORD,
      }),

    closeChangePassword: () =>
      dispatch({
        type: CLOSE_CHANGE_PASSWORD,
      }),

    openUpdateProfile: () =>
      dispatch({
        type: OPEN_UPDATE_PROFILE,
      }),

    closeUpdateProfile: () =>
      dispatch({
        type: CLOSE_UPDATE_PROFILE,
      }),

    setToken: (token) =>
      dispatch({
        type: SET_TOKEN,
        payload: token,
      }),

    authError: (error) =>
      dispatch({
        type: AUTH_ERROR,
        payload: error,
      }),

    setLoading: (status = true) => {
      dispatch({
        type: SET_LOADING,
        payload: status,
      });
    },
    getImplementor: async (id) => {
      try {
        const { data } = await axiosinstance().get(`/auth/user/getimplementor`);
        if (data && data.id) {
          dispatch({
            type: SET_IMPLEMENTOR,
            payload: data,
          });
        }
      } catch (error) {}
    },
    setLastInteraction: (lastInteraction) => {
      dispatch({
        type: SET_LAST_INTERACTION,
        payload: lastInteraction,
      });
    },
  };

  return actions;
};
export default authActions;
