import React, { Component } from 'react';
import Dialog, { DialogHeader } from '@cloudhub-ux/core/dialog';

import { Text } from '@cloudhub-ux/core';
import SlideComponent from '@cloudhub-ux/core/SlideComponent';
import ProfileForm from './ProfileForm';

class UpdateProfileDialog extends Component {
  static defaultProps = {
    open: false,
    onClose: () => {},
    onOk: () => {},
    currentUser: {},
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { open, currentUser } = this.props;
    const { name, email, phone } = currentUser;
    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={SlideComponent}
        title="Update Profile"
      >
        <DialogHeader onClose={this.props.onClose}>
          <Text h4>Update Profile</Text>
        </DialogHeader>

        <ProfileForm initialValues={{ name, email, phone }} />
      </Dialog>
    );
  }
}

export default UpdateProfileDialog;
