import React from 'react';
import { Dialog, Text, Block, Tab, Tabs } from '@cloudhub-ux/core';
import { DialogHeader, DialogTabs } from '@cloudhub-ux/core/dialog';
import DialogLayer from 'localcomponents/DialogLayer';
import ActionForm from './ActionForm';
import Interventions from './Interventions';
import Verifications from './Verifications';
import Validations from './Validations';

const VerificationForm = (props) => {
  const [tabvalue, settabvalue] = React.useState(0);
  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} fullScreen onClose={props.onCancelEdit}>
      <DialogHeader onClose={props.onCancelEdit}>
        <Text h4 bold>
          {(editingRow || {}).ActionName || (editingRow || {}).ActionCode || ''}
        </Text>
      </DialogHeader>
      <DialogTabs>
        <Block>
          <Tabs
            value={tabvalue}
            onChange={(event, val) => settabvalue(val)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Action Details" />
            <Tab label="Follow-up" />
            <Tab label="Verifications" />
            <Tab label="Validations" />
          </Tabs>
        </Block>
      </DialogTabs>
      <DialogLayer>
        {tabvalue === 0 && (
          <Block>
            <ActionForm action={editingRow} />
          </Block>
        )}
        {tabvalue === 1 && (
          <Block>
            <Interventions ActionId={(editingRow || {}).id} />
          </Block>
        )}
        {tabvalue === 2 && (
          <Block>
            <Verifications Action={editingRow} />
          </Block>
        )}
        {tabvalue === 3 && (
          <Block>
            <Validations Action={editingRow} />
          </Block>
        )}
      </DialogLayer>
    </Dialog>
  );
};

export default VerificationForm;
