const isNode = require('detect-node');
const port = '2141';
// const ipAddress = '192.168.8.101';
// const ipAddress = '192.168.100.54';

const ipAddress = '127.0.0.1';

module.exports = {
  ipAddress,
  port,
  API_ENDPOINT: isNode ? `http:/${ipAddress}:${port}/api` : '/api',
  FACEBOOK_ID: '279750363012126',
  GOOGLE_ID:
    '36532691394-jig7pr9fq45hjb7hgl9uc36o5sqk9j60.apps.googleusercontent.com',
  GOOGLE_APIKEY: 'AIzaSyA1alkRfDOOXteAhNEPbIqo9CDmCmikSWs',
};
