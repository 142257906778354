/* eslint-disable capitalized-comments */
import React from 'react';
import {
  TableCell,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from '@mui/material';
import { RemoteDataGrid } from '@cloudhub-ux/core/datagrid';
import {
  Block,
  Alert,
  IconButton,
  Input,
  Button,
  Text,
} from '@cloudhub-ux/core';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import GrievanceresponseForm from './forms/GrievanceresponseForm';
import AdminContext from 'admin/context/AdminContext';
import Edit from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import ListAltIcon from '@mui/icons-material/ListAlt';
// import useAdminAxios from 'admin/context/useAdminAxios';

const dateFormat = 'DD-MMM-YYYY hh:mm a';

const Infopage = () => {
  const { adminAxiosInstance } = React.useContext(AdminContext);
  // const { postData } = useAdminAxios();
  const gridRef = React.useRef();
  const [error, setError] = React.useState('');
  const [editingRow, setEditingRow] = React.useState(null);
  const [exporting, setexporting] = React.useState([]);
  const [Status, setStatus] = React.useState('');

  React.useEffect(() => {
    try {
      gridRef.current.reload();
    } catch (error) {}
  }, [Status]);

  const headers = [
    { label: 'Assigned', key: 'Assigned' },
    { label: 'Acknowledged', key: 'Acknowledged' },
    { label: 'Dismissed', key: 'Dismissed' },
    { label: 'Escalated', key: 'Escalated' },
    { label: 'Referred', key: 'Referred' },
    { label: 'Resolved', key: 'Resolved' },
    { label: 'Subject', key: 'Subject' },
    { label: 'Grievance Code', key: 'GrievanceCode' },
    { label: 'Conclusion', key: 'Conclusion' },
    { label: 'Recommendation', key: 'Recommendation' },
    { label: 'Status', key: 'Status' },
    { label: 'SubmittedBy', key: 'SubmittedBy' },
    { label: 'Phone', key: 'Phone' },
    { label: 'Institution', key: 'Institution' },
    { label: 'Addresses and Respects Safeguards', key: 'Compliant' },
  ];

  const onChangeSelection = (selection) => {
    const actionswithdates = [...(selection || [])].map((action) => ({
      ...action,
      Assigned: moment(action.Timestamp).format('DD MMM, YYYY hh:mm a'),
      Acknowledged: (action.StatusDates || {}).AcknowledgedDate
        ? moment((action.StatusDates || {}).AcknowledgedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      Dismissed: (action.StatusDates || {}).DismissedDate
        ? moment((action.StatusDates || {}).DismissedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      Escalated: (action.StatusDates || {}).EscalatedDate
        ? moment((action.StatusDates || {}).EscalatedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      Referred: (action.StatusDates || {}).ReferredDate
        ? moment((action.StatusDates || {}).ReferredDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      Resolved: (action.StatusDates || {}).ResolvedDate
        ? moment((action.StatusDates || {}).ResolvedDate).format(
            'DD MMM, YYYY hh:mm a'
          )
        : '',
      ActionName: action.ActionName || '',
      SubmittedBy: action.HandlerName || '',
      Phone: action.HandlerPhone || '',
      Institution: action.Institution || '',
    }));
    setexporting(actionswithdates);
  };
  const renderHeader = ({ onSearch, onRefresh, title }) => (
    <Block paper>
      <Block row space="between" middle padding={20}>
        <Text header>{title}</Text>
        <Block row flex={false}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={Status}
              onChange={(event) => {
                setStatus(event.target.value);
              }}
              row
            >
              <FormControlLabel value="OPEN" control={<Radio />} label="Open" />
              <FormControlLabel
                value="ACKNOWLEDGED"
                control={<Radio />}
                label="Acknowledged"
              />
              <FormControlLabel
                value="RESOLVED"
                control={<Radio />}
                label="Resolved"
              />
              <FormControlLabel
                value="ESCALATED"
                control={<Radio />}
                label="Escalated"
              />
              <FormControlLabel
                value="REFERRED"
                control={<Radio />}
                label="Referred"
              />
              <FormControlLabel
                value="DISMISSED"
                control={<Radio />}
                label="Dismissed"
              />
              <FormControlLabel
                value="OVERDUE"
                control={<Radio />}
                label="Overdue"
              />
            </RadioGroup>
          </FormControl>
          <Input
            style={{ flex: 1, minWidth: 200 }}
            icon="search"
            placeholder="Search..."
            onChange={(e) => onSearch(e.target.value)}
          />
          <Button onClick={() => onRefresh()}>
            <RefreshIcon /> Refresh
          </Button>
          <CSVLink
            data={exporting}
            headers={headers}
            filename="Grievance Assignments"
          >
            <Button>
              <ListAltIcon /> Export Selected to Excel
            </Button>
          </CSVLink>
        </Block>
      </Block>
      <Block />
    </Block>
  );

  const deleteRows = async (deletingRows) => {
    // try {
    //   const ids = deletingRows.map((item) => item.id);
    //   const data = await postData({
    //     url: `/fgrm/grievance/delete`,
    //     params: { ids },
    //   });
    //   if (data) {
    //     toastr.success('PLRs successfulyy Deleted');
    //     gridRef.current.reload();
    //   }
    // } catch (error) {}
  };

  const cellComponent = ({ row, column }) => {
    // Row, column, style
    if (column.name === 'discount') {
      return <TableCell />;
    }
    if (column.name === 'Timestamp') {
      return <TableCell>{moment(row.Timestamp).format(dateFormat)}</TableCell>;
    }
    return (
      <TableCell>
        {`${typeof row[column.name] === 'undefined' ? '' : row[column.name]}`}
      </TableCell>
    );
  };

  const cols = [
    { name: 'Subject' },
    { name: 'HandlerName', title: 'Handler' },
    { name: 'GrievanceCode', title: 'Code' },
    { name: 'Timestamp', title: 'Submitted' },
    { name: 'Status' },
  ];

  const columnWidths = [
    { columnName: 'Subject', width: 120 },
    { columnName: 'GrievanceCode', width: 120 },
    { columnName: 'HandlerName', width: 150 },
    { columnName: 'Timestamp', width: 150 },
    { columnName: 'Status', width: 50 },
  ];

  return (
    <Block>
      <Block flex={false}>
        {Boolean(error) && (
          <Alert error message={error} onClose={() => setError('')} />
        )}
      </Block>

      <RemoteDataGrid
        title="Grievance Assignments"
        url="/fgrm/grievanceresponse/getall"
        axiosinstance={adminAxiosInstance}
        actionsComponent={(props) => {
          return (
            <IconButton onClick={() => setEditingRow((props || {}).row)}>
              <Edit color="primary" />
            </IconButton>
          );
        }}
        columns={cols}
        columnWidths={columnWidths}
        allowColumnResizing={false}
        cellComponent={cellComponent}
        onAdd={() => setEditingRow({})}
        onDeleteRows={deleteRows}
        onEdit={(row) => setEditingRow(row)}
        ref={gridRef}
        onChangeSelection={onChangeSelection}
        header={renderHeader}
        params={{ Status }}
      />
      <GrievanceresponseForm
        editingRow={editingRow}
        onCancelEdit={() => setEditingRow(null)}
        onSave={(row) => {
          gridRef.current.onSave(row);
          gridRef.current.reload();
          setEditingRow(null);
        }}
      />
    </Block>
  );
};

export default Infopage;
