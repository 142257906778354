import React from 'react';
import axios from 'axios';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import AuthContext from 'auth/context/AuthContext';

import SessionExpired from 'auth/SessionExpired';
import { UNAUTH_USER } from 'auth/context/actions/authTypes';

import AdminContext from './AdminContext';
import useTasks from './useTasks';

const AdminProvider = (props) => {
  const { navigate } = useLocation();

  const auth = React.useContext(AuthContext);

  const {
    token,
    currentUser,
    isAuthenticated,
    sessioncounter,
    CONFIG,
    axiosinstance,
  } = auth;

  const adminAxiosInstance = React.useMemo(
    () => () =>
      axios.create({
        baseURL: `${CONFIG.API_ENDPOINT}/admin`,
        headers: {
          prxy: 'no-proxy',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
          userid: currentUser.id,
          username: currentUser.Name,
          email: currentUser.Email,
          phone: currentUser.Phone,
          enterpriseaccountno: currentUser.EnterpriseAccountNo,
          enterpriseid: currentUser.EnterpriseId,
        },
      }),
    [
      CONFIG.API_ENDPOINT,
      token,
      currentUser.id,
      currentUser.Name,
      currentUser.Email,
      currentUser.Phone,
      currentUser.EnterpriseAccountNo,
      currentUser.EnterpriseId,
    ]
  );

  const Tasks = useTasks({ axiosinstance: adminAxiosInstance });

  React.useEffect(() => {
    if (!isAuthenticated || !currentUser.UserType.includes('Admin')) {
      // auth.actions.updateMerchant({});
      navigate('/auth/signin');
    }
  }, [currentUser.UserType, isAuthenticated, navigate]);

  if (sessioncounter <= 0 && isAuthenticated) {
    try {
      localStorage.removeItem('token');
    } catch (error) {}
    auth.dispatch({
      type: UNAUTH_USER,
    });
    return <SessionExpired />;
  }

  return (
    <AdminContext.Provider
      value={{
        auth: {
          token,
          currentUser,
          isAuthenticated,
          UserRoles: currentUser.UserRoles || [],
          UserType: currentUser.UserType,
          axiosinstance,
        },
        adminAxiosInstance,
        adminaxiosinstance: adminAxiosInstance,
        Tasks,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
