import React from 'react';
import { motion } from 'framer-motion';
import { styled, alpha } from '@mui/material/styles';
import { Box, Container, Typography, Grid } from '@mui/material';
import {
  varFadeIn,
  varWrapEnter,
  varFadeInRight,
  TextAnimate,
} from '@cloudhub-ux/min/theme/animate';
import { colors, Images } from 'theme';
import AppContext from 'app/context/AppContext';

const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `linear-gradient(to right, ${alpha(colors.dark, 1)}, ${alpha(
    colors.dark,
    0.7
  )}), url(${Images.landing}), url(${Images.landing2})`,
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10),
  },
}));

export default function ContactHero() {
  const {
    AppSettings: { Organization },
  } = React.useContext(AppContext);
  const { Address, Phone, Email } = Organization || {};
  const { Line1, Line2, Location } = Address || {};

  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      <Container maxWidth="lg" sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle>
          <TextAnimate
            text="Where"
            sx={{ color: 'primary.main' }}
            variants={varFadeInRight}
          />
          <br />
          <Box sx={{ display: 'inline-flex', color: 'common.white' }}>
            <TextAnimate text="to" sx={{ mr: 2 }} />
            <TextAnimate text="find" sx={{ mr: 2 }} />
            <TextAnimate text="us?" />
          </Box>

          <Grid container spacing={5} sx={{ mt: 5, color: 'common.white' }}>
            <Grid item xs={12} md={6} sx={{ pr: { md: 5 } }}>
              <motion.div variants={varFadeIn}>
                <Typography variant="h6" paragraph>
                  {Line1}
                  <br />
                  {Line2}
                  <br />
                  {Location}
                </Typography>
              </motion.div>
              <motion.div variants={varFadeInRight}>
                <Typography variant="h6">
                  {Email}
                  <br /> {Phone}
                </Typography>
              </motion.div>
            </Grid>
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
