import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
import FaqsHero from './FaqsHero';
import FaqsList from './FaqsList';
import FeedbackForm from 'app/fgrm/FeedbackForm';
import { varFadeInUp, MotionInView } from '@cloudhub-ux/min/theme/animate';

const RootStyle = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(8),
  // [theme.breakpoints.up('md')]: {
  //   paddingTop: theme.spacing(11),
  // },
}));

export default function Faqs() {
  return (
    <RootStyle title="Faqs | Kenya REDD+">
      <FaqsHero />

      <Container sx={{ mt: 15, mb: 10 }}>
        <Typography variant="h3" sx={{ mb: 5 }}>
          Frequently asked questions
        </Typography>

        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <FaqsList />
          </Grid>
          <Grid item xs={12} md={6}>
            <MotionInView variants={varFadeInUp}>
              <Typography variant="h4">
                Haven't found the right help?
              </Typography>
            </MotionInView>
            <FeedbackForm />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
