import React from 'react';
import {
  Button,
  Dialog,
  Input,
  FieldBlock,
  Block,
  Text,
  Alert,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import ImagesUpload from '@cloudhub-ux/core/uploaders/ImagesUpload';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import DialogLayer from 'localcomponents/DialogLayer';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import TextArea from '@cloudhub-ux/core/TextArea';

const GalleryForm = (props) => {
  const { postData, error, reset } = useAdminAxios({});

  const addGallery = async (values) => {
    try {
      const data = await postData({
        url: `/website/gallery/savegallery`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} minHeight="75%">
      <Form
        onSubmit={addGallery}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).GalleryName || 'Add Information Page'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <FieldBlock row wrap margin={[sizes.doubleBaseMargin, 0]}>
                  <Field
                    type="text"
                    label="Title"
                    required
                    name="Title"
                    component={Input}
                    flex
                  />
                  <Field
                    type="text"
                    label="Location"
                    required
                    name="Location"
                    component={Input}
                    flex
                  />
                </FieldBlock>
                <Field
                  type="text"
                  label="Description"
                  name="Description"
                  component={TextArea}
                />
                <Field
                  type="text"
                  label="Pictures"
                  name="Pictures"
                  url={`/api/media/upload/image`}
                  component={ImagesUpload}
                  limit={30}
                />
                <Field
                  type="text"
                  label="Upload Video"
                  name="Video"
                  url="/api/media/upload/file"
                  accept="video/*"
                  component={FilesUpload}
                />
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default GalleryForm;
