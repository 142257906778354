import React from 'react';
import {
  Block,
  ListMenu,
  ListSubMenu,
  FieldBlock,
  TextArea,
  Button,
} from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import Text from '@cloudhub-ux/core/Text';
import { Fab } from '@mui/material';
import { Close, FiberNew } from '@mui/icons-material';

const InterventionEvent = ({ Events, onUpdate }) => {
  const [addEvent, setaddEvent] = React.useState(false);

  return (
    <Block shadow padding={[0, sizes.padding]} margin={sizes.baseMargin}>
      <Text h5 bold style={{ alignSelf: 'center' }}>
        Followup Updates &amp; Events
      </Text>
      <div style={{ alignSelf: 'flex-end' }}>
        <Fab
          sx={{ backgroundColor: colors.error, mr: 1 }}
          size="small"
          variant="extended"
          onClick={() => setaddEvent(false)}
        >
          <Close /> Cancel
        </Fab>
        <Fab size="small" variant="extended" onClick={() => setaddEvent(true)}>
          <FiberNew /> New Update
        </Fab>
      </div>
      {addEvent && (
        <Form
          onSubmit={() => {}}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <Block
              padding={sizes.padding}
              shadow
              margin={sizes.doubleBaseMargin}
            >
              <Field
                readOnly
                name="Description"
                label="Description of Update"
                component={TextArea}
                flex
              />
              <FieldBlock row wrap>
                <Field
                  type="text"
                  label="Response Files"
                  name="Files"
                  url={`/api/media/upload/file`}
                  // axiosinstance={axiosinstance}
                  component={FilesUpload}
                  limit={5}
                  flex
                />
              </FieldBlock>
              <Button
                contained
                color={colors.primary}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </Block>
          )}
        />
      )}

      <ListMenu>
        {(Events || []).map((item) => (
          <ListSubMenu
            header={item.EventCode}
            key={item.EventCode}
            expanded={!(item || {}).EventCode}
          >
            {(item || {}).EventCode && (
              <Block padding={sizes.padding} paper>
                <Text h6 style={{ marginBottom: sizes.baseMargin }}>
                  {item.Description}
                </Text>
                {Array.isArray(item.Files) &&
                  item.Files.map((file) => (
                    <a
                      key={file.url}
                      href={file.Location}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {file.Location}
                    </a>
                  ))}
              </Block>
            )}
          </ListSubMenu>
        ))}
      </ListMenu>
    </Block>
  );
};

export default InterventionEvent;
