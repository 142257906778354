import React from 'react';
import { Text, Block, Button } from '@cloudhub-ux/core';
import { sizes, colors } from 'theme';
import { withStyles } from '@mui/styles';
import AccountButton from 'auth/AccountButton';
import { Link } from '@cloudhub-ux/core/reach';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import TopMenu from './menu/TopMenu';
import MenuMobile from './menu/MenuMobile';
import Container from '@cloudhub-ux/core/Container';
import AuthContext from 'auth/context/AuthContext';
import { MHidden } from '@cloudhub-ux/min/theme/@material-extend';
import useOffSetTop from '@cloudhub-ux/min/hooks/useOffSetTop';
let startlink = '/signin';

// if (global.navigator) {
//   isMobile = navigator.userAgent.match(
//     /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
//   );
// }

const App = ({ children }) => {
  const { isAuthenticated } = React.useContext(AuthContext);

  const isOffset = useOffSetTop(100);
  const { location } = useLocation();

  return (
    <Block>
      <Block
        flex={false}
        middle
        style={{
          height: sizes.navBarHeight,
        }}
      >
        <Block
          row
          shadow
          color={colors.milkyWhite}
          style={{
            position: 'fixed',
            right: 0,
            top: 0,
            left: 0,
            zIndex: 9,
          }}
        >
          <Container
            style={{
              position: 'relative',
              height: '100%',
            }}
          >
            <Block
              row
              middle
              style={{
                height: sizes.navBarHeight,
                position: 'relative',
              }}
            >
              <Block>
                <TopMenu />
              </Block>

              <Block flex={false} row middle right margin={[0, sizes.margin]}>
                {!isAuthenticated ? (
                  <Link to={startlink}>
                    <Button
                      contained
                      color={colors.milkyWhite}
                      style={{
                        borderWidth: 1,
                        borderColor: colors.primary,
                      }}
                    >
                      <Text h5 primary>
                        Signin
                      </Text>
                    </Button>
                  </Link>
                ) : (
                  <AccountButton />
                )}
              </Block>
              <MHidden width="mdUp">
                <MenuMobile
                  isOffset={isOffset}
                  isHome={location.pathname === '/'}
                />
              </MHidden>
            </Block>
          </Container>
        </Block>
      </Block>

      <Block
        style={{
          height: '100%',
          // paddingTop: sizes.navBarHeight + sizes.padding,
        }}
      >
        {children}
      </Block>

      <Block
        flex={false}
        style={{
          marginTop: 20,
          marginBottom: 20,
          position: 'relative',
          backgroundColor: colors.dark,
        }}
      ></Block>
    </Block>
  );
};

const styles = () => ({
  container: {},
});

export default withStyles(styles)(App);
