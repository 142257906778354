import React from 'react';
import { FieldBlock, Block, Alert } from '@cloudhub-ux/core';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors, sizes } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import { RemoteSelector } from '@cloudhub-ux/core/baseweb';
import FieldButton from '@cloudhub-ux/core/FieldButton';

const GrievanceReassignForm = ({ AssignmentId, onReassign }) => {
  const { postData, error, reset, adminAxiosInstance } = useAdminAxios({});

  const reassignTask = async (values) => {
    try {
      const data = await postData({
        url: `/fgrm/grievanceresponse/reassigngrievance`,
        params: { ...values, AssignmentId },
      });
      if (data && data.id) {
        onReassign(data);
        reset();
      }
    } catch (error) {}
  };

  return (
    <Form
      onSubmit={reassignTask}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <Block paper margin={sizes.baseMargin}>
          <FieldBlock row wrap>
            <Field
              name="Grievancehandler"
              label="Reassign To"
              component={RemoteSelector}
              url="/stakeholders/grievancehandler/filtersearch"
              axiosinstance={adminAxiosInstance}
              required
              readOnly
              labelExtractor={(row) =>
                row && row.User && row.User.Name
                  ? `${row.User.Name.split(' ')[0]} - ${
                      (row.Stakeholder || {}).StakeholderName
                    }`
                  : ''
              }
              valueExtractor={(row) => row}
              params={{ Status: 'ACTIVE' }}
              keyExtractor={(row) => (row || {}).id}
              flex
            />
            <FieldButton
              onClick={handleSubmit}
              color={colors.success}
              disabled={pristine || submitting}
            >
              Reassign Grievance
            </FieldButton>
          </FieldBlock>
          {Boolean(error) && <Alert error message={error} onClose={reset} />}
        </Block>
      )}
    />
  );
};

export default GrievanceReassignForm;
