import React from 'react';
import {
  Block,
  Text,
  Input,
  FieldBlock,
  ListMenuItem,
  IconButton,
  ListMenu,
  Alert,
  toastr,
  Button,
  TextArea,
} from '@cloudhub-ux/core';
import Pagination from '@mui/material/Pagination';
import { sizes, colors } from 'theme';
import useVerificationAxios from 'actionverification/context/useVerificationAxios';
import { VisibilityOutlined } from '@mui/icons-material';
import { Form, Field } from '@cloudhub-ux/core/form';
import { useLocation } from '@cloudhub-ux/core/customhooks';
import VerificationContext from 'actionverification/context/VerificationContext';
import { StaticListSelector, LayersManager } from '@cloudhub-ux/core/baseweb';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import InterventionEvents from './InterventionEvents';

const Interventions = () => {
  const [uploading, setuploading] = React.useState(false);
  const [interventions, setinterventions] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [totalCount, settotalCount] = React.useState(0);
  const [editing, setediting] = React.useState({});
  const { location } = useLocation();

  const {
    VerificationState: { Validator, Verifier, CurrentAction },
    auth: { currentUser },
  } = React.useContext(VerificationContext);

  const isOwner =
    ((editing || {}).id && (editing._from || '').includes(currentUser.id)) ||
    !(editing || {}).id;

  const { getData, postData, error, reset } = useVerificationAxios({});

  const getInterventions = React.useCallback(async () => {
    try {
      if (CurrentAction.id) {
        const data = await getData({
          url: `/intervention/actioninterventions`,
          params: { ActionId: CurrentAction.id, skip: page * 20, limit: 20 },
        });
        if (data && Array.isArray(data.items)) {
          setinterventions(data.items);
          settotalCount(data.totalCount);
        }
      }
    } catch (error) {}
    // eslint-disable-next-line
  }, [CurrentAction.id, page]);

  React.useEffect(() => {
    getInterventions();
  }, [getInterventions]);

  const saveIntervention = async (values, form) => {
    try {
      if (!values.id) {
        values._from = location.pathname.includes('verifications')
          ? Verifier._id
          : Validator._id;
        values._to = CurrentAction._id;
      }
      const data = await postData({
        url: `/intervention/saveintervention`,
        params: { ...values },
      });
      if (data && data.id) {
        setinterventions((current) => {
          if (!values.id) {
            return [...(current || {}), data];
          } else {
            return (current || []).map((item) => {
              if (item.id === data.id) {
                return data;
              }
              return item;
            });
          }
        });
        form.reset();
        setediting({});
      }
    } catch (error) {}
  };
  const changeFile = (event, form) => {
    if (event && event.target && event.target.value) {
      const v = event.target.value || [];
      const ind = v.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', v);
    }
    if (event && Array.isArray(event)) {
      const ind = event.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', event);
    }
  };
  const onRemoveFiles = async (file) => {
    try {
      const data = await postData({
        url: '/media/upload/deletefiles',
        params: { files: [file] },
      });
      if (data) {
        toastr.success('Files deleted successfully');
        return data;
      }
    } catch (error) {}
  };

  return (
    <Block row wrap>
      {interventions.length > 0 && (
        <Block flex={false}>
          <ListMenu>
            {interventions.map((intervention) => (
              <ListMenuItem
                divider
                button={false}
                key={intervention.id}
                primary={
                  <Block padding={[0, 75, 0, 0]}>
                    <Text h6 bold>
                      {intervention.Name}
                    </Text>
                  </Block>
                }
                secondary={`${intervention.InterventionType} - ${intervention.InterventionCode}`}
                action={
                  <Block>
                    <IconButton
                      onClick={() => {
                        setediting(intervention);
                      }}
                    >
                      <VisibilityOutlined color="primary" />
                    </IconButton>
                  </Block>
                }
              />
            ))}
          </ListMenu>
          {totalCount > 20 && (
            <Block center flex={false} margin={[sizes.doubleBaseMargin, 0]}>
              <Pagination
                classes={{}}
                count={totalCount}
                rowsPerPage={20}
                onChange={(event, current) => setPage(current)}
              />
            </Block>
          )}
        </Block>
      )}
      <Form
        onSubmit={saveIntervention}
        initialValues={editing || {}}
        render={({ handleSubmit, form, pristine, submittting }) => (
          <Block>
            <Button
              style={{ alignSelf: 'flex-end' }}
              onClick={() => setediting({})}
            >
              <Text error> Clear</Text>
            </Button>
            <LayersManager zIndex={1}>
              <FieldBlock row wrap>
                <Field
                  name="InterventionType"
                  label="Type of Followup"
                  component={StaticListSelector}
                  options={['Request for Information', 'Request for Action']}
                  flex
                />
                {(editing || {}).InterventionCode && (
                  <Field
                    name="InterventionCode"
                    label="Intervention Code"
                    component={Input}
                    flex
                  />
                )}
              </FieldBlock>
              <Field
                name="Description"
                label="Describe the Followup"
                component={TextArea}
              />
              <FieldBlock row wrap paper>
                <Field
                  type="text"
                  label="Response Files"
                  name="Files"
                  url={`/api/media/upload/file`}
                  // axiosinstance={axiosinstance}
                  component={FilesUpload}
                  limit={5}
                  onChange={(event) => changeFile(event, form)}
                  onRemove={onRemoveFiles}
                  flex
                  disabled={!isOwner}
                />
              </FieldBlock>
              {Array.isArray((editing || {}).Events) && (editing || {}).id && (
                <InterventionEvents
                  InterventionId={editing.id}
                  Events={editing.Events}
                  onUpdate={(val) => {
                    setinterventions((current) =>
                      (current || []).map((item) => {
                        if (item.id === editing.id) {
                          return { ...item, Events: val };
                        }
                        return item;
                      })
                    );
                    setediting(val);
                  }}
                />
              )}
            </LayersManager>
            {Boolean(error) && <Alert error message={error} onClose={reset} />}
            <Button
              contained
              color={colors.primary}
              onClick={handleSubmit}
              disabled={pristine || submittting || uploading || !isOwner}
            >
              {uploading && 'Uploading...'}
              {submittting && 'Saving...'}
              {!uploading && !submittting && 'Save Followup'}
            </Button>
          </Block>
        )}
      />
    </Block>
  );
};
export default Interventions;
