/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Block, Text } from '@cloudhub-ux/core';

const NotAuthorized = (props) => (
  <Block middle center>
    <Block flex={false} center>
      <Text h1 role="img">
        😢
      </Text>
      <Text h3>You are not authorized for this resource</Text>
    </Block>
  </Block>
);

export default NotAuthorized;
