import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Grid, Avatar, Typography, CardContent } from '@mui/material';
import SvgIconStyle from 'app/static/SvgIconStyle';
import { Images, colors } from 'theme';
import moment from 'moment';

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const TitleStyle = styled('div')(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 44,
  color: colors.dark,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

InstitutionCard.propTypes = {
  sttakeholder: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function InstitutionCard({ member, index }) {
  const { Name, Designation, Picture, Bio, Organization, Position, Tenure } =
    member || {};
  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle>
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
            }}
          />
          <AvatarStyle
            alt="logo"
            src={(Picture || {}).Location || Images.landing}
          />

          <CoverImgStyle
            alt={Name}
            src={(Picture || {}).Location || Images.landingbg}
          />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
          }}
        >
          <TitleStyle>
            {Name}
            <br />
            {Designation} - {Organization}
          </TitleStyle>

          <TitleStyle>
            {Position}
            <br />
            {Tenure &&
              Tenure.Start &&
              Tenure.End &&
              `${moment(Tenure.Start).format('MMM YYYY')} - ${moment(
                Tenure.End
              ).format('MMM YYYY')}`}
          </TitleStyle>
          <Typography
            variant="h6"
            color="common.white"
            dangerouslySetInnerHTML={{ __html: Bio }}
            sx={{ height: '150px', overflow: 'auto' }}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
