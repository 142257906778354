import React from 'react';
import {
  Button,
  Dialog,
  Input,
  TextArea,
  Block,
  Text,
  Alert,
  FieldBlock,
} from '@cloudhub-ux/core';
import { DialogHeader, DialogActions } from '@cloudhub-ux/core/dialog';
import { Form, Field } from '@cloudhub-ux/core/form';
import { colors } from 'theme';
import useAdminAxios from 'admin/context/useAdminAxios';
import DialogLayer from 'localcomponents/DialogLayer';

const FaqForm = (props) => {
  const { postData, error, reset } = useAdminAxios({});

  const addFaq = async (values) => {
    try {
      const data = await postData({
        url: `/website/faq/savefaq`,
        params: values,
      });
      if (data && data.id) {
        props.onSave(data);
        reset();
      }
    } catch (error) {}
  };

  const { editingRow } = props;

  return (
    <Dialog open={editingRow !== null} maxWidth="md" minHeight={400}>
      <Form
        onSubmit={addFaq}
        initialValues={editingRow || {}}
        render={({ handleSubmit, submitting, form, pristine, values }) => (
          <React.Fragment>
            <DialogHeader onClose={props.onCancelEdit}>
              <Text h4 bold>
                {(editingRow || {}).Question || 'Add FAQe'}
              </Text>
            </DialogHeader>
            <DialogLayer>
              <Block>
                <FieldBlock row wrap>
                  <Field
                    type="text"
                    label="Question"
                    required
                    name="Question"
                    component={Input}
                    flex
                  />
                  <Field
                    type="number"
                    name="Rank"
                    required
                    label="Rank"
                    component={Input}
                    parse={(val) =>
                      isNaN(parseInt(val, 10)) ? 1 : parseInt(val, 10)
                    }
                    min={1}
                  />
                </FieldBlock>

                <Field
                  type="text"
                  label="Answer"
                  name="Answer"
                  component={TextArea}
                />
              </Block>
            </DialogLayer>
            <DialogActions>
              <React.Fragment>
                {Boolean(error) && (
                  <Alert error message={error} onClose={reset} />
                )}
              </React.Fragment>
              <Button
                contained
                onClick={props.onCancelEdit}
                color={colors.primary}
              >
                Cancel
              </Button>
              <Button
                contained
                onClick={handleSubmit}
                disabled={submitting || pristine || form.invalid}
                color={colors.secondary}
              >
                Save
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

export default FaqForm;
