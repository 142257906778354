import React from 'react';
import { Form, Field } from '@cloudhub-ux/core/form';
import AppContext from 'app/context/AppContext';
import {
  GridContainer,
  GridItem,
  Text,
  TextArea,
  toastr,
  Button,
  Alert,
} from '@cloudhub-ux/core';
import FilesUpload from '@cloudhub-ux/core/uploaders/FilesUpload';
import useAppAxios from 'app/context/useAppAxios';
import { colors } from 'theme';

const InfoResponse = ({ infoRequest }) => {
  const [uploading, setuploading] = React.useState(false);
  const {
    GrievanceState: { TrackingGrievance, grievanceActions },
  } = React.useContext(AppContext);
  const { updateTrackingGrievance } = grievanceActions;

  const { postData, error, reset } = useAppAxios();

  const sendResponse = async (values, form) => {
    try {
      const data = await postData({
        url: '/app/fgrm/grievance/submitresponse',
        params: {
          Response: values,
          GrievanceCode: TrackingGrievance.GrievanceCode,
        },
      });
      if (data && data.id) {
        updateTrackingGrievance(data);
        reset();
        form.reset();
      }
    } catch (error) {}
  };
  const changeFile = (event, form) => {
    if (event && event.target && event.target.value) {
      const v = event.target.value || [];
      const ind = v.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', v);
    }
    if (event && Array.isArray(event)) {
      const ind = event.indexOf(
        (fileobj) => (fileobj && fileobj.percent) || fileobj.percent === 0
      );
      setuploading(ind > -1);
      form.change('Files', event);
    }
  };
  const onRemoveFiles = async (file) => {
    try {
      const data = await postData({
        url: '/media/upload/deletefiles',
        params: { files: [file] },
      });
      if (data) {
        toastr.success('Files deleted successfully');
        return data;
      }
    } catch (error) {}
  };

  return (
    <Form
      onSubmit={sendResponse}
      initialValues={infoRequest || {}}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <GridContainer spacing={2}>
          <GridItem xs={12} sm={12} md={12}>
            <Text h6 semibold>
              {(values || {}).Description || ''}
            </Text>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Field name="Response" component={TextArea} label="Your Response" />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Field
              type="text"
              label="Response Files"
              name="Files"
              url={`/api/media/upload/file`}
              // axiosinstance={axiosinstance}
              component={FilesUpload}
              limit={5}
              onChange={(event) => changeFile(event, form)}
              onRemove={onRemoveFiles}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              fullWidth
              color={colors.primary}
              onClick={handleSubmit}
              disabled={submitting || pristine || uploading}
            >
              {uploading && 'Uploading...'}
              {submitting && 'Submitting...'}
              {!uploading && !submitting && 'Submit'}
            </Button>
          </GridItem>
          {Boolean(error) && (
            <GridItem xs={12} sm={12} md={12}>
              <Alert error message={error} onClose={reset} />
            </GridItem>
          )}
        </GridContainer>
      )}
    />
  );
};
export default InfoResponse;
