import React from 'react';
import { Router } from '@cloudhub-ux/core/reach';
import User from './users/User';
import SystemSettings from './settings/SystemSettings';
import AdminRoute from '../authroutes/AdminRoute';

const SettingsRoutes = () => (
  <Router style={{ flex: 1, display: 'flex' }}>
    <AdminRoute component={SystemSettings} default path="systemsettings" />
    <AdminRoute component={User} path="users" />
  </Router>
);

export default SettingsRoutes;
