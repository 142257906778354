// leave off @2x/@3x
import componentImages from '@cloudhub-ux/core/theme/Images';
import svgData from 'assets/svg/data.svg';
import feelingProud from 'assets/svg/feeling-proud.svg';
import observe from 'assets/svg/observe.svg';
import performance from 'assets/svg/performance.svg';
import payments from 'assets/svg/payments.svg';
import rocket from 'assets/svg/rocket.svg';
import store from 'assets/svg/store.svg';
import search from 'assets/svg/search.svg';
import landing from 'assets/images/landing.png';
import landingbg from 'assets/images/landingbg.jpeg';
import overlay from 'assets/svg/overlay.svg';
import logo from 'assets/images/logo.png';
import NoImage from 'assets/images/NoImage.jpg';
import nomad from 'assets/images/nomad.png';
import defaultUser from 'assets/images/default-user.png';

const images = {
  ...componentImages,
  NoImage,
  defaultUser,
  logo,
  whitelogo: logo,
  logoicon: logo,
  nomad,
  landing,
  landingbg,
  overlay,
  svgData,
  feelingProud,
  observe,
  performance,
  payments,
  rocket,
  store,
  search,
};

export default images;
