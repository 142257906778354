import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';

const colors = {
  primary: '#332B98', // '#0976b4',
  secondary: '#D0F19F',
  tertiary: '#909CEF',
  accent: '#FF4957',
  blueGreen: '#00D3CA',
  jungleGreen: '#37b38b',
  yellowlime: 'EFFC90',
  black: '#2E2E2E',
  white: '#FFFFFF',
  darkGray: '#6C6C6C',
  gray: '#B4B4B4',
  gray2: '#D8D8D8',
  gray3: '#F0F0F0',
  gray4: '#F7F8FA',
  dark: '#001529',
  mistyWhite: '#FFF6FF',
  milkyWhite: '#FFFCFF',

  red: red[500],
  pink: pink[500],
  purple: purple[500],
  deepPurple: deepPurple[500],
  indigo: indigo[500],
  blue: blue[500],
  lightBlue: lightBlue[500],
  cyan: cyan[500],
  teal: teal[500],
  green: green[500],
  lightGreen: lightGreen[500],
  lime: lime[500],
  yellow: yellow[500],
  amber: amber[500],
  orange: orange[500],
  deepOrange: deepOrange[500],
  brown: brown[500],
  grey: grey[500],
  blueGrey: blueGrey[500],

  background: '#FFFCFF',
  clear: 'rgba(0,0,0,0)',
  facebook: '#3b5998',
  google: '#dd4b39',
  transparent: 'rgba(0,0,0,0)',
  silver: '#F7F7F7',
  steel: '#CCCCCC',
  error: 'rgba(200, 0, 0, 0.8)',
  ricePaper: 'rgba(255,255,255, 0.75)',
  frost: '#D8D8D8',
  cloud: 'rgba(200,200,200, 0.35)',
  windowTint: 'rgba(0, 0, 0, 0.4)',
  panther: '#161616',
  charcoal: '#595959',
  coal: '#2d2d2d',
  bloodOrange: '#fb5f26',
  snow: 'white',
  ember: 'rgba(164, 0, 48, 0.5)',
  fire: '#e73536',
  drawer: 'rgba(30, 30, 29, 0.95)',
  eggplant: '#251a34',
  border: '#483F53',
  banner: '#5F3E63',
  text: '#E0D7E5',

  primaryColors: {},
  secondaryColors: {},
  tertiaryColors: {},
  warningColors: {},
  dangerColors: {},
  successColors: {},
  infoColors: {},

  whiteColor: '#FFF',
  blackColor: '#000',
  twitterColor: '#55acee',
  facebookColor: '#3b5998',
  googleColor: '#dd4b39',
  linkedinColor: '#0976b4',
  pinterestColor: '#cc2127',
  youtubeColor: '#e52d27',
  tumblrColor: '#35465c',
  behanceColor: '#1769ff',
  dribbbleColor: '#ea4c89',
  redditColor: '#ff4500',
  instagramColor: '#125688',

  twitter: '#55acee',
  linkedin: '#0976b4',
  pinterest: '#cc2127',
  youtube: '#e52d27',
  tumblr: '#35465c',
  behance: '#1769ff',
  dribbble: '#ea4c89',
  reddit: '#ff4500',
  instagram: '#125688',

  success: '#4caf50',
  info: '#00acc1',
  rose: '#e91e63',
  warning: '#ff9800',
  danger: '#f44336',
};

export default colors;
