import AuthContext from 'auth/context/AuthContext';
import React from 'react';

const useAppAxios = (props) => {
  const { url, params, initialState } = props || {};
  const { axiosinstance: appAxiosInstance } = React.useContext(AuthContext);

  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(initialState);

  const postData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await appAxiosInstance().post(`${url}`, {
          ...params,
        });
        setLoading(false);
        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};

        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    // eslint-disable-next-line
    []
  );

  const getData = React.useMemo(
    () => async ({ url, params }) => {
      try {
        setLoading(true);
        const { data } = await appAxiosInstance().get(`${url}`, {
          params,
        });
        setLoading(false);

        return data;
      } catch (error) {
        const response = error.response || {};
        const data = response.data || response.body || {};
        setError(`${data.message || 'Server error'}`.replace(/_/g, ' '));
      }
      setLoading(false);
    },
    // eslint-disable-next-line
    [url]
  );

  const hydrateState = async () => {
    const data = await getData({ url, params });
    setData(data);
  };

  const reset = () => {
    setError('');
    setLoading(false);
    setData(initialState);
  };

  React.useEffect(() => {
    if (url) {
      hydrateState();
    }
    // eslint-disable-next-line
  }, [url, JSON.stringify(params)]);

  return { data, error, loading, getData, postData, reset, appAxiosInstance };
};

export default useAppAxios;
